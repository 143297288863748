import React from 'react'

const PlusMinus = props => {
  
  return (
    <div className="col-md-12">
      <div className="col-md-9">
        <label><img src={'images/' + props.icon } alt="" /> {props.label}</label>
      </div>
      <div className="col-md-3">
        <button className="unstyled-btn" onClick={() => props.decrementCount(props.field, props.stateValue, props.fieldsToAdd)}>
          <img src="../images/minus.png" alt="" />
        </button>
        
        <label className="pr-1 count-value">{props.countValue}</label>
        
        <button className="unstyled-btn" onClick={() => props.incrementCount(props.field, props.stateValue, props.fieldsToAdd)}>
          <img src="../images/plus.png" alt="" />
        </button>
      </div>
    </div>
  )
}

export default PlusMinus