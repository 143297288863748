import _ from 'lodash';
import React from 'react';
import axios from 'axios';
import moment from 'moment'
import config from '../config';
import Radio from "./lib/Radio";
import InputMask from 'react-input-mask';
import ReactTooltip from 'react-tooltip'
import PlusMinus from './lib/PlusMinus';
import Select from 'react-select';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import DatePicker from "react-datepicker";
import Tooltip from './lib/Tooltip';
import MaskedInput from 'react-text-mask'
import "react-datepicker/dist/react-datepicker.css";

class Driver extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      endDateError: ''
    }

    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.setInsuranceDates = this.setInsuranceDates.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  setInsuranceDates() {
    const { values } = this.props;
    const driverData = {};

    let yearsInsured, company;

    switch(values.yearsContinuouslyInsured) {
      case 'Less than 1':
        yearsInsured = 0.5;
        break;
      case '1':
        yearsInsured = 1;
        break;
      case '2':
        yearsInsured = 2;
        break;
      case '3':
        yearsInsured = 3;
        break;
      case '4':
        yearsInsured = 4;
        break;
      case '5':
        yearsInsured = 5;
        break;
      case '6':
        yearsInsured = 6;
        break;
      case '7':
        yearsInsured = 7;
        break;
      case '8':
        yearsInsured = 8;
        break;
      case '9':
        yearsInsured = 9;
        break;
      case '10 or more':
        yearsInsured = 10;
        break;
      default:
        yearsInsured = 0;
        break;
    }

    switch (values.yearsCurrentCompany) {
      case 'Less than 1':
        company = 0.5;
        break;
      case '1':
        company = 1;
        break;
      case '2':
        company = 2;
        break;
      case '3':
        company = 3;
        break;
      case '4':
        company = 3;
        break;
      case '5':
        company = 5;
        break;
      case '6':
        company = 6;
        break;
      case '7':
        company = 7;
        break;
      case '8':
        company = 8;
        break;
      case '9':
        company = 9;
        break;
      case '10 or more':
        company = 10;
        break;
      default:
        company = 0;
        break;
    }

     /* use the date from "When did you last have auto insurance?"(ie 09/01/ 1992) minus 
      * "How long did you have insurance leading up to the date above?" (3) = (09/01/1989) 
      * = Continuous insurance date.and then add a Lapse with reason "No vehicle, hence no insurance"
      * from date 09/01/1992 to today 's date. If they include cancellations in the last 3 years, add those as well.
      */
    let continuousInsuranceDate, dateWithCompany;

    if (values.currentlyInsured === 'No' && values.hasHadInsurance === 'Yes' && values.dateLastInsured !== '') {
      continuousInsuranceDate = moment(values.dateLastInsured, 'MM-DD-YYYY').subtract(yearsInsured, 'years').format('YYYY-MM-DD');

      // if we're in this condition, we need to add a lapse and use insurance was not requred.
      const insuranceNotRequiredLapse = {
        start_date: moment(values.dateLastInsured, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        lapse_type: 'NotReqrd'
      }

      let lapseData = {
        has_cancellations: values.hasCancellations,
        lapses: this.buildLapses(values.insuranceGaps, 'insuranceGapsType'),
      }

      if (!_.isEmpty(lapseData.lapses)) {
        // append the not required insurance lapse
        lapseData.lapses.push(insuranceNotRequiredLapse)
        driverData.lapses = lapseData;
      } else {
        driverData.lapses = insuranceNotRequiredLapse;
      }
    } else if (yearsInsured > 0 || company > 0) {
      continuousInsuranceDate = moment(values.effectiveDate, 'MM-DD-YYYY').subtract(yearsInsured, 'years').format('YYYY-MM-DD');
      dateWithCompany = moment(values.effectiveDate, 'MM-DD-YYYY').subtract(company, 'years').format('YYYY-MM-DD');
    } else {
      dateWithCompany = null;
      continuousInsuranceDate = null;
    }
    driverData.continuous_insurance_date = continuousInsuranceDate;
    driverData.date_with_company = dateWithCompany;

    return driverData;
  }

  saveAndContinue(e) {
    e.preventDefault()

    const { values } = this.props;
    let driverData = {};

    if (values.driverId && (values.editDriver || values.usedBackLink)) {
      driverData.driverId = values.driverId;
      driverData.firstname = values.driverFirstname;
      driverData.lastname = values.driverLastname;
      driverData.preferred_pronouns = values.driverPreferredpronouns;
      driverData.gender = values.gender;
      driverData.dob = moment(values.birthdate).format('YYYY-MM-DD');
      driverData.marital_status = values.maritalStatus;
      driverData.employment_status = values.employmentStatus;
      driverData.license_class = values.licenseClass;
      driverData.license_class_date = moment(values.licenseClassDate).format('YYYY-MM-DD');
      driverData.currently_insured = values.currentlyInsured === "Yes" ? true : false;
      driverData.years_continuously_insured = values.yearsContinuouslyInsured;
      driverData.years_current_company = values.yearsCurrentCompany;
      driverData.date_last_insured = moment(values.dateLastInsured).format('YYYY-MM-DD');
      driverData.has_had_insurance = values.hasHadInsurance === "Yes" ? true : false;
      driverData.previously_insured_duration = values.previouslyInsuredDuration;
      driverData.completed_drivers_training = values.completedDriversTraining === "Yes" ? true : false;
      driverData.drivers_training_date = moment(values.driversTrainingDate).format('YYYY-MM-DD');
      driverData.has_g2 = values.hasG2 === "Yes" ? true : false;
      driverData.out_of_country_driver = values.OutOfCountryDriver === "Yes" ? true : false;
      driverData.g2_date = moment(values.g2Date).format('YYYY-MM-DD') || null;
      driverData.has_g1 = values.hasG1 === "Yes" ? true : false;
      driverData.g1_date = moment(values.g1Date).format('YYYY-MM-DD') || null;

      if (values.occupation)
        driverData.occupation = values.occupation;

      if (values.goodGrades)
        driverData.good_grades = values.goodGrades === "Yes" ? true : false;

      if (values.otherClaim)
        driverData.other_claim = values.otherClaim

      if (values.otherCancel)
        driverData.other_cancel = values.otherCancel

      const today = new Date(moment());
      const birthDate = new Date(moment(values.birthdate, 'MM-DD-YYYY').toDate());
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
  
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
  
      if (age < 25 && values.hasHadInsurance === "No") {
        driverData.is_young_driver = true;
      }

      if (!(age < 25 && values.hasHadInsurance === "No")) {
        driverData.is_young_driver = false;
      }

      driverData = {...driverData, ...this.setInsuranceDates()}
      const lapseData = {
        has_cancellations: values.hasCancellations,
        lapses: this.buildLapses(values.insuranceGaps, 'insuranceGapsType'),
      }

      if (!_.isEmpty(lapseData.lapses)) {
        driverData.lapses = lapseData;
      }

      axios.put(`${config.apiUrl}/drivers/${values.driverId}`, driverData)
        .then((response) => {
          this.props.nextStep();
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      
      driverData = {
        vehicle_id: _.isArray(values.vehicleId) ? values.vehicleId[0] : values.vehicleId,
        quote_id: values.quoteId,
        firstname: values.driverFirstname,
        lastname: values.driverLastname,
        preferred_pronouns: values.driverPreferredpronouns,
        gender: values.gender,
        dob: moment(values.birthdate).format('YYYY-MM-DD'),
        marital_status: values.maritalStatus,
        employment_status: values.employmentStatus,
        license_class: values.licenseClass,
        license_class_date: moment(values.licenseClassDate).format('YYYY-MM-DD'),
        currently_insured: values.currentlyInsured === "Yes" ? true : false,
        years_continuously_insured: values.yearsContinuouslyInsured,
        years_current_company: values.yearsCurrentCompany,
        date_last_insured: moment(values.dateLastInsured).format('YYYY-MM-DD'),
        has_had_insurance: values.hasHadInsurance === "Yes" ? true : false,
        previously_insured_duration: values.previouslyInsuredDuration,
        completed_drivers_training: values.completedDriversTraining === "Yes" ? true : false,
        drivers_training_date: moment(values.driversTrainingDate).format('YYYY-MM-DD'),
        has_g2: values.hasG2 === "Yes" ? true : false,
        out_of_country_driver: values.OutOfCountryDriver === "Yes" ? true : false,
        g2_date: moment(values.g2Date).format('YYYY-MM-DD') || null,
        has_g1: values.hasG1 === "Yes" ? true : false,
        g1_date: moment(values.g1Date).format('YYYY-MM-DD') || null,
      }

      if (values.occupation)
        driverData.occupation = values.occupation;

      if (values.goodGrades)
        driverData.good_grades = values.goodGrades === "Yes" ? true : false;

      if (values.otherClaim)
        driverData.other_claim = values.otherClaim

      if (values.otherCancel)
        driverData.other_cancel = values.otherCancel

      const lapseData = {
        has_cancellations: values.hasCancellations,
        lapses: this.buildLapses(values.insuranceGaps, 'insuranceGapsType'),
      }

      if (!_.isEmpty(lapseData.lapses)) {
        driverData.lapses = lapseData;
      }
      driverData = {...driverData, ...this.setInsuranceDates()}

      this.props.save(driverData, 'drivers');
      this.props.nextStep();
    }
  }

  handleDateChange(name, date) {
    let change = {};
    change[name] = date;
    this.setState(change);
  }

  yearsContinuouslyInsured() {
    let numYears = [
      "Less than 1",
    ];
    for (let i = 1; i < 10; i += 1) {
      numYears.push((i).toString());
      if (i === 9) {
        numYears.push(("10 or more").toString());
      }
    }
    return numYears;
  }

  yearsInsuredPrior() {
    return [
      'Less than a year',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10 or more'
    ];
  }

  checkForOther() {
    const {
      values
    } = this.props;

    if (!_.isUndefined(values.claims)) {
      const found = values.claims.some((el) => {
        return el.isChecked === true && el.value === 'Other';
      });
      return found;
    }
  }


  checkLicenseDetailsForDriverTraining() {
    const {
      values
    } = this.props;
    const data  = values.OutOfCountryDriver && (
      (values.licenseClass === 'G' && values.licenseClassDate && values.gDateFiveOrLess === true && values.hasG2 === 'No') ||
      (values.licenseClass === 'G' && values.licenseClassDate && values.gDateFiveOrLess === true && values.hasG2 === 'Yes' && values.g2Date && values.hasG1 === 'No' ) ||
      (values.licenseClass === 'G' && values.licenseClassDate && values.gDateFiveOrLess === true && values.hasG2 === 'Yes' && values.g2Date && values.hasG1 === 'Yes' && values.g1Date ) ||
      (values.licenseClass === 'G2' && values.licenseClassDate && values.gDateFiveOrLess === true && values.hasG1 === 'No') ||
      (values.licenseClass === 'G2' && values.licenseClassDate && values.gDateFiveOrLess === true && values.hasG1 === 'Yes' && values.g1Date) ||
      (values.licenseClass === 'G1' && values.licenseClassDate && values.gDateFiveOrLess === true))
      return data
  }

  checkLicenseDetailsForCurrenltyInsured() {
    const {
      values
    } = this.props;
    const data  = values.OutOfCountryDriver &&(
      (values.licenseClass === 'G' && values.isAfterOrOn === false && values.licenseClassDate) ||
      (values.licenseClass === 'G2' && values.licenseClassDate && values.hasG1 === 'No') ||
      (values.licenseClass === 'G2' && values.licenseClassDate && values.hasG1 === 'Yes' && values.g1Date) ||
      (values.licenseClass === 'G1' && values.licenseClassDate) ||
      (values.hasG2 === 'Yes' && values.g2Date && values.hasG1 === 'No') ||
      (values.hasG2 === 'Yes' && values.g2Date && values.hasG1 === 'Yes' && values.g1Date) ||
      (values.hasG2 === 'No' && values.licenseClassDate))
      return data
  }

  buildLapses(lapses, type) {
    return _.map(lapses, lapse => {
      return {
        start_date: moment(lapse.startDate).format('YYYY-MM-DD'),
        end_date: moment(lapse.endDate).format('YYYY-MM-DD'),
        lapse_type: lapse[type].value,
      }
    });
  }

  genders() {
    return [
      'Male',
      'Female',
      'Gender X'
    ]
  }

  maritalStatuses() {
    return [
      'Married',
      'Single',
      'Common-Law',
      'Separated/Divorced',
      'Widowed'
    ]
  }

  employmentStatuses() {
    return [
      'Self-Employed',
      'Employed',
      'Retired',
      'Full-time Student',
      'Part-time Student',
      'Not currently working'
    ]
  }

  licenseClasses() {
    return [
      'G',
      'G2',
      'G1',
    ]
  }

  render() {
    let hrefLink = '#';
    const {
      handleChange,
      values,
      incrementCount,
      decrementCount,
      handleConviction,
      handleConvictionDate,
    } = this.props;

    const insuranceGapsOptions = [
      { value: 'NonPay', label: 'Cancellation due to non-payment' },
      { value: 'LicSusp', label: 'Cancellation due to driving record or license suspension' },
      { value: 'MatMisrep', label: 'Cancellation due to Material Misrepresentation' },
      { value: 'NonDiscl', label: 'Cancellation due to Non-Disclosure' },
      { value: 'OtherLapse', label: 'Other' },
    ]

    let isOtherGapType;
    if (values.insuranceGaps[0]) {
      if (values.insuranceGaps[0].insuranceGapsType.label === 'Other') {
        isOtherGapType = true;
      }
    }

    let isDisabled;
    if (values.currentlyInsured === 'Yes' && values.hasCancellations) {
      isDisabled = false;
    } else if (values.currentlyInsured === 'No' && values.hasHadInsurance === 'No') {
      isDisabled = false;
    } else if (values.currentlyInsured === 'No' && values.dateLastInsured && values.yearsContinuouslyInsured && values.hasCancellations) {
      isDisabled = false;
    } else if (values.birthDateAgeError) {
      isDisabled = true;
    } else {
      isDisabled = true;
    }

    if (values.hasCancellations === 'Yes' && _.isEmpty(values.insuranceGaps)) {
      isDisabled = true;
    }

    values.insuranceGaps.map((ins, idx) => {
      if (values.insuranceGaps[idx].insuranceGapsType === "") {
        isDisabled = true
      } else if (values.insuranceGaps[idx].startDate === "") {
        isDisabled = true
      } else if (values.insuranceGaps[idx].endDate === "") {
        isDisabled = true
      } else {
        isDisabled = false;
      }
      if (values.insuranceGaps[idx].endDateError1) {
        isDisabled = true;
      }
    })

    let DriverBirthDate, DriverLicenseClassDate, DriverG2Date, DriverG1Date, DriverLastInsuredDate;
    if (values.driverId && (values.editDriver || values.usedBackLink)) {
      DriverBirthDate = values.birthdate ? moment(values.birthdate, "MM-DD-YYYY").toDate() : values.birthdate;
      DriverLicenseClassDate = values.licenseClassDate ? moment(values.licenseClassDate, "MM-DD-YYYY").toDate() : values.licenseClassDate;
      DriverG2Date =  values.g2Date ? moment(values.g2Date, "MM-DD-YYYY").toDate() : values.g2Date;
      DriverG1Date = values.g1Date ? moment(values.g1Date, "MM-DD-YYYY").toDate() : values.g1Date;
      DriverLastInsuredDate = values.dateLastInsured ? moment(values.dateLastInsured, "MM-DD-YYYY").toDate() : values.dateLastInsured;
    } else {
      DriverBirthDate = values.birthdate;
      DriverLicenseClassDate = values.licenseClassDate;
      DriverG2Date =  values.g2Date;
      DriverG1Date = values.g1Date;
      DriverLastInsuredDate = values.dateLastInsured;
    }

    return (
      <section id="welcome" className="tm-section">
        <ReactTooltip />
        <div className="row mt-3">
          <div className="col-md-8">
            <div className="form-block" id="FirstNameSelected">
              <label>
                <img src="images/name.png" alt="" /> First name
              </label>
              <input
                name="driverFirstname"
                placeholder='First name'
                className="form-control"
                id="12"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={values.driverFirstname}
              />
              <div className='invalid-feedback'>{values.driverFirstnameError}</div>
            </div>
          </div>
        </div>

      {
        values.driverFirstname ? 
          <div className="row mt-3">
            <div className="col-md-8">
              <div className="form-block">
                <label>
                  <img src="images/name.png" alt="" /> Last name
                </label>
                <input
                  id="13"
                  name="driverLastname"
                  className="form-control"
                  placeholder='Last name'
                  onChange={handleChange}
                  onBlur={handleChange}
                  defaultValue={values.driverLastname}
                />
                <div className='invalid-feedback'>{values.driverLastnameError}</div>
              </div>
            </div>
          </div>
        : null
      }
      
      {
        values.driverLastname ?
          <div className="row mt-3">
            <div className="col-md-8">
              <div className="form-block">
                <label>
                  <img src="images/name.png" alt="" /> Preferred pronouns (Optional)
                </label>
                <input
                  id="13"
                  name="driverPreferredpronouns"
                  className="form-control"
                  placeholder='Preferred pronouns'
                  onChange={handleChange}
                  onBlur={handleChange}
                  defaultValue={values.driverPreferredpronouns}
                />
              </div>
            </div>
          </div>
        : null
      }

      {
       values.driverLastname &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-actions btn-list mt-3">
                  <label>
                    <img src="images/birthdate.png" alt="" /> Birth date
                  </label><br/>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    id="14"
                    autoComplete="off"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={moment().subtract(16, 'years').toDate()}
                    minDate={moment().subtract(100, 'years').toDate()}
                    scrollableMonthDropdown
                    showMonthDropdown
                    name="birthdate"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={DriverBirthDate}
                    onChange={(e) => this.props.handleDateChange(e, 'birthdate')}
                  />
                  <div className='invalid-feedback'>{values.birthDateTooOldError}</div>
                  <div className='invalid-feedback'>{values.birthDateAgeError}</div>
                </div>
              </div>
              {
                (values.birthdate && (!values.birthDateTooOldError || values.birthDateAgeError)) &&
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/gender.png" alt="" /> Gender
                        <Tooltip placement="right" trigger="click" tooltip="Must match gender on driver's license.">
                          <img src="images/help.png" alt="/"/>
                        </Tooltip>
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          id="15"
                          name="gender"
                          handleChange={handleChange}
                          value={values.gender}
                          radioValues={this.genders()}
                        />
                      </div>
                    </div>
                  </div>
              }
            </div>
          </ScrollIntoViewIfNeeded>
        }

      {
        values.birthdate && values.gender &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <ReactTooltip />
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/marital_status.png" alt="" /> Marital status
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      id="16"
                      name="maritalStatus"
                      handleChange={handleChange}
                      value={values.maritalStatus}
                      radioValues={this.maritalStatuses()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }
      {
        values.maritalStatus &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
            <ReactTooltip />
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/employment_status.png" alt="" /> Employment status
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      id="17"
                      name="employmentStatus"
                      handleChange={handleChange}
                      value={values.employmentStatus}
                      radioValues={this.employmentStatuses()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }
      {
        values.employmentStatus === 'Employed' &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/occupation.png" alt="" /> Occupation? (discounts could apply)
                  </label>
                  <input
                    name="occupation"
                    className="form-control"
                    placeholder='Occupation'
                    onChange={handleChange}
                    defaultValue={values.occupation}
                  />
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }
      {
        values.employmentStatus === 'Self-Employed' &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/occupation.png" alt="" /> What's your occupation? (discounts could apply)
                  </label>
                  <input
                    name="occupation"
                    className="form-control"
                    placeholder='Occupation'
                    onChange={handleChange}
                    defaultValue={values.occupation}
                  />
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }
      {
        values.employmentStatus === 'Full-time Student' &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/student.png" alt="" /> Are your grades 80% or higher (discounts could apply)
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      name="goodGrades"
                      handleChange={handleChange}
                      value={values.goodGrades}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }
      {
        values.employmentStatus &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>   
            <div className="row">
              <ReactTooltip />
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/license.png" alt="" /> Current license class
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      id="18"
                      name="licenseClass"
                      handleChange={handleChange}
                      value={values.licenseClass}
                      radioValues={this.licenseClasses()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }

      {
        values.licenseClass &&
        <ScrollIntoViewIfNeeded options={{
          scrollMode: 'always',
        }}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-block mt-3">
                <label>
                  <img src="images/calendar.png" alt="" /> When did you obtain the {values.licenseClass} license?
                </label> <br/>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    id="19"
                    autoComplete="off"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={moment().toDate()}
                    scrollableMonthDropdown
                    showMonthDropdown
                    name="licenseClassDate"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={DriverLicenseClassDate}
                    onChange={(e) => this.props.handleDateChange(e, 'licenseClassDate')}
                  />
                <div className='invalid-feedback'>{values.licenseClassDateError1}</div>
                <div className='invalid-feedback'>{values.licenseClassDateError2}</div>
              </div>
            </div>
          </div>
        </ScrollIntoViewIfNeeded>
      }

      {
        values.licenseClassDate &&
        <ScrollIntoViewIfNeeded options={{
          scrollMode: 'always',
        }}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-block mt-3">
                <label>
                  <img src="images/insurance_card.png" alt="" /> Did you originally obtain your driver's license outside of Ontario, or did you Fast-Track through the driver licensing?
                </label>
                <div className="btn-group-toggle" data-toggle="buttons">
                  <Radio
                    name="OutOfCountryDriver"
                    handleChange={handleChange}
                    value={values.OutOfCountryDriver}
                    radioValues={['Yes', 'No']}
                  />
                </div>
              </div>
              <div className='invalid-feedback input-error'>
                {
                  _.isEmpty(values.OutOfCountryDriver) ? 'This field is required' : null
                }
              </div>
            </div>
          </div>
        </ScrollIntoViewIfNeeded>
      }

      {
        (values.OutOfCountryDriver && values.licenseClass === 'G' && values.licenseClassDate && values.isAfterOrOn === true) ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/insurance_card.png" alt="" /> Did you ever have a G2?
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      name="hasG2"
                      handleChange={handleChange}
                      value={values.hasG2}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
      }
      {
        values.isAfterOrOn === true && values.hasG2 === "Yes" ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/calendar.png" alt="" /> When did you receive your G2?
                  </label><br/>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    autoComplete="off"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={moment().toDate()}
                    scrollableMonthDropdown
                    showMonthDropdown
                    name="g2Date"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={DriverG2Date}
                    onChange={(e) => this.props.handleDateChange(e, 'g2Date')}
                  />
                  <div className='invalid-feedback'>{values.g2DateError2}</div>
                  <div className='invalid-feedback'>{values.g2DateError3}</div>
                  <div className='invalid-feedback'>{values.g2DateError4}</div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
      }

      {
        (values.OutOfCountryDriver &&
          ((values.isAfterOrOn === true && values.g2Date) ||
          (values.licenseClass === 'G2' && values.licenseClassDate) ||
          (values.hasG2 && values.g2Date))
        )
          ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/insurance_card.png" alt="" /> Did you ever have a G1?
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      name="hasG1"
                      handleChange={handleChange}
                      value={values.hasG1}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
      }

      {
        /*values.isAfterOrOn === true &&*/ values.hasG1 === "Yes" ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-8">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/calendar.png" alt="" /> When did you receive your G1?
                  </label><br />
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    autoComplete="off"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={moment().toDate()}
                    scrollableMonthDropdown
                    showMonthDropdown
                    name="g1Date"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={DriverG1Date}
                    onChange={(e) => this.props.handleDateChange(e, 'g1Date')}
                  />
                  <div className='invalid-feedback'>{values.g1DateError2}</div>
                  <div className='invalid-feedback'>{values.g1DateError3}</div>
                  <div className='invalid-feedback'>{values.g1DateError4}</div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
      }

      {
        this.checkLicenseDetailsForDriverTraining() &&
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/insurance_card.png" alt="" /> Have you completed a driver's training course?
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      name="completedDriversTraining"
                      handleChange={handleChange}
                      value={values.completedDriversTraining}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      }

      {
        values.completedDriversTraining && values.completedDriversTraining === "Yes" ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-8">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/calendar.png" alt="" /> What was the date you completed drivers training?
                  </label>
                  <InputMask
                    mask="99/99/9999"
                    value={values.driversTrainingDate}
                    onChange={handleChange}
                    name="driversTrainingDate"
                    className="form-control"
                  />
                  <div className='help-text'>
                    <img src="images/info.png" alt="Info" />&nbsp; Tip: MM/DD/YYYY
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
      }

      {
        ((this.checkLicenseDetailsForCurrenltyInsured() && this.checkLicenseDetailsForDriverTraining() === false) ||
          (this.checkLicenseDetailsForCurrenltyInsured() && this.checkLicenseDetailsForDriverTraining() &&
          (values.completedDriversTraining === 'Yes' && values.driversTrainingDate)) ||
          (this.checkLicenseDetailsForCurrenltyInsured() && this.checkLicenseDetailsForDriverTraining() &&
          (values.completedDriversTraining === 'No'))
        ) ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <ReactTooltip />
              <div className="col-md-8">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/insurance_card.png" alt="" /> Are you currently listed on an insurance policy in Canada or the U.S? (Even if you are insured as a secondary or occasional operator, this experience will count.)
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      id="20"
                      name="currentlyInsured"
                      handleChange={handleChange}
                      value={values.currentlyInsured}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
        : null
        }
        
        {
          values.currentlyInsured === 'Yes' ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
                }}>
                <div className="row">
                <ReactTooltip />
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/year.png" alt="" /> How many years have you been continuously insured in Canada or the U.S?
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          id="21"
                          name="yearsContinuouslyInsured"
                          handleChange={handleChange}
                          value={values.yearsContinuouslyInsured}
                          radioValues={this.yearsContinuouslyInsured()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
            : null
          }
          
          {
            (values.yearsContinuouslyInsured && /*!values.dateLastInsured &&*/ values.currentlyInsured === 'Yes') ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <ReactTooltip />
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/year.png" alt="" /> How many years with your current insurance company?
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          name="yearsCurrentCompany"
                          handleChange={handleChange}
                          value={values.yearsCurrentCompany}
                          radioValues={this.yearsContinuouslyInsured()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
              : null
          }

          {
            values.currentlyInsured === 'No' &&
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/calendar.png" alt="" /> Have you ever been listed on an insurance policy in Canada or the U.S? (Even if you have been insured as a secondary or occasional operator, this experience will count.)
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          id="21"
                          name="hasHadInsurance"
                          handleChange={handleChange}
                          value={values.hasHadInsurance}
                          radioValues={['Yes', 'No']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
          }
          {
            values.hasHadInsurance === 'Yes' && values.currentlyInsured === 'No' &&
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-actions btn-list mt-3">
                      <label>
                        <img src="images/calendar.png" alt="" /> When did you last have auto insurance?
                      </label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false }
                        }}
                        autoComplete="off"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        maxDate={moment().toDate()}
                        scrollableMonthDropdown
                        showMonthDropdown
                        name="dateLastInsured"
                        className="form-control"
                        placeholderText="MM/DD/YYYY"
                        customInput={<MaskedInput
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        />}
                        selected={DriverLastInsuredDate}
                        onChange={(e) => this.props.handleDateChange(e, 'dateLastInsured')}
                      />
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
          }
          { 
            values.dateLastInsured && values.currentlyInsured === 'No' ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/year.png" alt="" /> How long did you continuously have insurance leading up to the date above?
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          name="yearsContinuouslyInsured"
                          handleChange={handleChange}
                          value={values.yearsContinuouslyInsured}
                          radioValues={this.yearsInsuredPrior()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
            : null
          }

          {
            (values.currentlyInsured === 'Yes' && values.yearsCurrentCompany) ||
            (values.dateLastInsured && values.yearsContinuouslyInsured) ?
              <React.Fragment>
                <ScrollIntoViewIfNeeded options={{
                  scrollMode: 'always',
                }}>
                  <div className="row">
                    <ReactTooltip html={true} />
                    <div className="col-md-12">
                      <div className="form-block mt-3">
                        <label>
                          <img src="images/calendar.png" alt="" /> Have you been cancelled by an insurance company in the last 3 years?
                        </label>
                        <div className="btn-group-toggle" data-toggle="buttons">
                          <Radio
                            id="21"
                            name="hasCancellations"
                            handleChange={handleChange}
                            value={values.hasCancellations}
                            radioValues={['Yes', 'No']}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollIntoViewIfNeeded>
              </React.Fragment>
             : null
          }

          {
            values.hasCancellations === 'Yes' ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row cancellations">
                  <PlusMinus
                    label="Please indicate how many times."
                    icon="payment.png"
                    incrementCount={incrementCount}
                    decrementCount={decrementCount}
                    countValue={values.cancellationCount}
                    stateValue="insuranceGaps"
                    fieldsToAdd={[{insuranceGapsType: '', startDate: '', endDate: ''}]}
                    field="cancellationCount" />
                    {
                      values.insuranceGaps.length > 0 ? 
                        values.insuranceGaps.map((insuranceGapsType, idx) => (
                          <div key={idx}>
                            <ReactTooltip />
                            <div className="col-md-6 mt-1 mb-1">
                              <div className="form-block">
                                <label>
                                  #{idx+1} Reason
                                </label>
                                <Select
                                  value={[{
                                    label: values.insuranceGaps[idx].insuranceGapsType.label,
                                    value: values.insuranceGaps[idx].insuranceGapsType.value,
                                  }]}
                                  options={insuranceGapsOptions}
                                  onChange={handleConviction(idx, 'insuranceGaps', 'insuranceGapsType')}
                                  placeholder={`Conviction type #${idx + 1}`}
                                  isSearchable
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-1 mb-1">
                              <div className="form-block">
                                <label>
                                  #{idx+1} When did this happen?
                                </label>
                                <DatePicker
                                  popperModifiers={{
                                    computeStyle: { gpuAcceleration: false }
                                  }}
                                  autoComplete="off"
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  maxDate={moment().toDate()}
                                  scrollableMonthDropdown
                                  showMonthDropdown
                                  className="form-control"
                                  placeholderText="MM/DD/YYYY"
                                  customInput={<MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  />}
                                  selected={insuranceGapsType.startDate}
                                  onChange={handleConvictionDate(idx, 'insuranceGaps', 'startDate')}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-1 mb-1">
                              <div className="form-block">
                                <label>
                                  #{idx+1} When did you re-obtain insurance? &nbsp;
                                  <Tooltip placement="right" trigger="click" tooltip="If you never re-obtained insurance, simply put today's date.">
                                    <img src="images/help.png" alt="/"/>
                                  </Tooltip>
                                </label>
                                <DatePicker
                                  popperModifiers={{
                                    computeStyle: { gpuAcceleration: false }
                                  }}
                                  autoComplete="off"
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  maxDate={moment().toDate()}
                                  scrollableMonthDropdown
                                  showMonthDropdown
                                  className="form-control"
                                  placeholderText="MM/DD/YYYY"
                                  customInput={<MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  />}
                                  selected={insuranceGapsType.endDate}
                                  onChange={handleConvictionDate(idx, 'insuranceGaps', 'endDate')}
                                />
                                <div className='invalid-feedback'>{values.insuranceGaps[idx].endDateError1}</div>
                                <div className='invalid-feedback'>{this.state.endDateError}</div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null
                    }
                </div>
              </ScrollIntoViewIfNeeded>
              : null
          }

          {
            isOtherGapType ? 
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/other.png" alt="" /> Please tell us the reason that you've been cancelled by an insurance company in the last 3 years.
                      </label>
                      <input
                        name="otherCancel"
                        className="form-control"
                        placeholder='Reason'
                        onChange={handleChange}
                        defaultValue={values.otherCancel}
                      />
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
            : null
          }

          {this.checkForOther() &&
            <div className="row">
              <div className="col-md-6">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/other.png" alt="" /> Reason?
                  </label>
                  <input
                    name="otherClaim"
                    className="form-control"
                    placeholder='Reason'
                    onChange={handleChange}
                    defaultValue={values.otherClaim}
                  />
                </div>
              </div>
            </div>
          }
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-actions btn-list mt-3">
                  <button onClick={this.saveAndContinue}
                    disabled={
                      isDisabled || 
                      values.birthDateAgeError || 
                      values.birthDateTooOldError ||
                      values.licenseClassDateError1 || 
                      values.licenseClassDateError2 ||
                      values.g2DateError2 ||
                      values.g2DateError3 ||
                      values.g2DateError4 ||
                      values.g1DateError2 ||
                      values.g1DateError3 ||
                      values.g1DateError4 ||
                      values.driverFirstnameHasError ||
                      values.driverLastnameHasError ||
                      (values.OutOfCountryDriver === '')
                    }
                    className="btn btn-green"
                    id="Driver-3"
                    type="submit">
                    Next Step &rarr;
                  </button>
                  <div className='invalid-feedback'>
                    {
                      (values.birthDateAgeError || values.birthDateTooOldError) ? '"Birth date" has errors.' : null
                    }
                  </div>
                  <div className='invalid-feedback'>
                    { 
                      values.licenseClassDateError1 || values.licenseClassDateError2 ? '"When did you obtain the license?" has errors.' : null
                    }
                  </div>

                  <div className='invalid-feedback'>
                    {
                     values.g2DateError2
                      || values.g2DateError3
                      || values.g2DateError4 ? "G2 Date has errors." : null
                    }
                  </div>

                  <div className='invalid-feedback'>
                    {
                      values.g1DateError2
                      || values.g1DateError3
                      || values.g1DateError4 ? "G1 Date has errors." : null
                    }
                  </div>

                  <div className='invalid-feedback'>
                    {
                      values.driverFirstnameHasError ? "First name is required" : null
                    }
                  </div>

                  <div className='invalid-feedback'>
                    {
                      values.driverLastnameHasError ? "Last name is required" : null
                    }
                  </div>
                  <br/>
                  <p><a href={hrefLink} onClick={() => this.props.setStep('vehicleList')}><img src="images/back.png" alt="Back"/> Back</a></p>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
      </section>
    )
  }
}

export default Driver;