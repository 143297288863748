import _ from 'lodash';
import axios from 'axios';
import config from '../config';
import React from 'react';

class Assignment extends React.Component {

  constructor(props) {
    super(props);
    
    this.saveAndContinue = this.saveAndContinue.bind(this);
  }

  componentDidMount() {
    const { values } = this.props;
    // 261 has 4 drivers, 2 vehicles
    // 262 has 3 drivers, 2 vehicles
    axios.get(`${config.apiUrl}/vehicle_assignments/${values.quoteId}`).then(response => {
      const { vehicle_assignment } = response.data;
      // if we're editing, we don't want to redo the vehicle/driver assignment
      // so if the assignments already exist for this quote id, then just 
      // skip over to the quotes page. Otherwise, show the dropdown to allow for
      // the vehicle/driver assignment.
      if (!_.isUndefined(vehicle_assignment) && vehicle_assignment.length > 0) {
        this.props.goToStep(5);
      } else {
        this.props.setUpDriversVehicles();
      }
    }).catch(error => console.log(error));
  }

  saveAndContinue(e) {
    e.preventDefault()

    const { values } = this.props; 
    
    _.map(values.vehiclesAvailable, vehicle => {
      // assign the selected driver as the primary.
      const primaryDriver = {
        quote_id: values.quoteId,
        driver_assignment: vehicle.driverId,
        vehicle_assignment: vehicle.id,
        operator_type: 'Primary',
      }
      this.props.save(primaryDriver, 'vehicle_assignments')

      // update the drivers object and remove the driver we made primary
      _.remove(values.driversAvailable, item => item.id === vehicle.driverId);
    })

    // Usually the youngest driver is entered into the quote if multiple drivers
    // are added so reverse the object array and use that as the secondary for best accuracy.
    const secondaryReversed = values.driversAvailable.reverse()[0];

    // if values.driversAvailable length is 1, then we have one driver left so assign it to the first vehicle
    if (values.driversAvailable.length === 1) {
      _.map(values.driversAvailable, (driver, i) => {
        const secondaryDriver = {
          quote_id: values.quoteId,
          driver_assignment: secondaryReversed.id, // reversed driver id from above
          vehicle_assignment: _.first(values.vehiclesAvailable).id,
          operator_type: 'Secondary'
        }
        this.props.save(secondaryDriver, 'vehicle_assignments');
      })
    } else {
      // Otherwise, we have more than 1 driver left so assign the remaining drivers to the vehicles by index
      _.map(values.driversAvailable, (driver, i) => {
        let vehicleId;
        if (_.isUndefined(values.vehiclesAvailable[i])) {
          vehicleId = _.first(values.vehiclesAvailable).id;
          const secondaryDriver = {
            quote_id: values.quoteId,
            driver_assignment: driver.id, // reversed driver id from above
            vehicle_assignment: vehicleId,
            operator_type: 'Secondary'
          }
          this.props.save(secondaryDriver, 'vehicle_assignments');
        } else {
          const secondaryDriver = {
            quote_id: values.quoteId,
            driver_assignment: driver.id, // reversed driver id from above
            vehicle_assignment: values.vehiclesAvailable[i].id,
            operator_type: 'Secondary'
          }
          this.props.save(secondaryDriver, 'vehicle_assignments');
        }
        
      })
    }
    this.props.goToStep(5);
  }

  render() {
    const { 
      handleChange,
      values,
    } = this.props;
    
    //'saveAndContinue' button will be disabled untill primary driver has not selected
    let isDisabled = false;
    _.map(values.vehiclesAvailable, vehicle => {
      if (isNaN(vehicle.driverId) || vehicle.driverId == null) {
        isDisabled = true;
      }
    });
    return (
      <section id="welcome" className="tm-section">
        <h5>Please confirm who drives which vehicle?</h5>
        <hr />

        { 
          values.vehiclesAvailable && _.map(values.vehiclesAvailable, (vehicle, idx) => (
            <div key={vehicle.id} data-vehicle={vehicle.id}>
              <h6>{vehicle.name}</h6>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Primary driver</label>
                    <select 
                      className="form-control" 
                      name="primaryDriver" 
                      data-vehicle={vehicle.id}
                      onChange={handleChange} 
                      value={vehicle.driverId || ''}>
                      <option value="">Please select</option>
                      {values.driversAvailable.map(val => (
                        <option value={val.id} key={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='invalid-feedback'>
                    {
                      (isNaN(vehicle.driverId) || vehicle.driverId == null) ? 'This field is required' : null
                    }
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-3">
              <button onClick={this.saveAndContinue} 
                disabled={isDisabled}
                id="Assignment-meta"
                className="btn btn-primary" 
                type="submit"> 
                Next Step
              </button> &rarr;
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Assignment;