import _ from 'lodash';
import React from 'react';
import axios from 'axios';
import config from '../config';
import 'react-phone-number-input/style.css'
import Select from 'react-select';
import Input, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  preferredTime
} from "./lib/constants";

class Success extends React.Component {

  constructor(props) {
    super(props);

    this.saveAndContinue = this.saveAndContinue.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  saveAndContinue(e) {
    e.preventDefault()

    const { values } = this.props;
    
    console.log("on the finish page ", values.fb);

    const userData = {
      firstname: values.drivers[0].firstname,
      lastname: values.drivers[0].lastname,
      email: values.emailAddress,
      phone: values.phoneNumber,
      preferred_time: values.preferredTime.value,
      additional_information: values.additionalInformation
    }

    axios.put(`${config.apiUrl}/quotes/${values.quoteId}`, userData)
      .then((response) => {
        if (values.fb) {
          const fbObj = {
            fb: values.fb,
            year: values.selectedYear.value,
            make: values.selectedMake.value,
            model: values.selectedModel.value,
            firstname: values.drivers[0].firstname,
            psid: this.props.psid,
            ref: values.quoteReferenceNumber,
          };
          axios.post(`https://bender-fbbot.herokuapp.com/complete`, fbObj)
            .then((response) => {
              window.MessengerExtensions.requestCloseBrowser((success) => {
                console.log("Webview closing");
              }, function error(err) {
                console.log(err);
              });
            })
            .catch((err) => {
              console.log("err ", err);
            })
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!values.fb) {
      // make sure to use next step for non fb webview
      this.props.nextStep()
    }

  }

  render() {
    let hrefLink = '#';

    const {
      handleChange,
      handleCustomSelect,
      values
    } = this.props;

    let disabled;
    if (values.emailHasErrors || values.userFirstNameHasError || values.userLastNameHasError) {
      disabled = true;
    } else if (_.isEmpty(values.emailAddress)) {
      disabled = true;
    } else if (_.isEmpty(values.phoneNumber)) {
      disabled = true;
    } else {
      disabled = false;
    }

    return (
      <section className="tm-section">
        <h5 className="mb-2">Great Choice! Please confirm we have the right contact information.</h5>
        <hr />

        <form action="/complete" method="post">
          <input type="hidden" name="psid" id="psid" value /> 
          <div className="row">
            <div className="col-md-6">
              <div className="form-block" id="ReConfirm">
                <label>
                  <img src="images/driver.png" alt="" /> First name
                </label>
                <input
                  name="userFirstName"
                  className="form-control"
                  defaultValue={values.drivers[0].firstname}
                  placeholder='First name'
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <div className='invalid-feedback input-error'>{values.useFirstnameError}</div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-block">
                <label>
                  <img src="images/driver.png" alt="" /> Last name
                </label>
                <input
                  name="userLastName"
                  className="form-control"
                  placeholder='Last name'
                  defaultValue={values.drivers[0].lastname}
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                <div className='invalid-feedback input-error'>{values.userLastnameError}</div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="form-block">
                <label>
                  <img src="images/lg-phone.png" alt="" /> Phone number
                </label>
                <Input
                  country='CA'
                  name="phoneNumber"
                  inputClassName="form-control bacon"
                  value={values.phoneNumber}
                  error={values.phoneNumber ? (isValidPhoneNumber(values.phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number is required'}
                  onChange={this.props.handlePhoneChange} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-block">
                <label>
                  <img src="images/email2.png" alt="" /> Email address
                </label>
                <input
                  name="emailAddress"
                  className={`form-control ${values.emailAddressError ? 'is-invalid' : ''}`}
                  placeholder='Email address'
                  defaultValue={values.emailAddress}
                  onChange={handleChange}
                />
              </div>
              <div className='invalid-feedback input-error'>
              {
                _.isEmpty(values.emailAddress) ? 'Email address is required.' : values.emailAddressError
              }
            </div>
            </div>
          </div>
          {
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-block">
                  <label>
                    <img src="images/lg-phone.png" alt="" /> Preferred Time to connect (optional)
                  </label>
                  <Select
                    value={values.preferredTime}
                    onChange = { (value) => handleCustomSelect('preferredTime', value)}
                    options={preferredTime}
                    placeholder="Please Select"
                    name="preferredTime"
                    isSearchable
                  />
                </div>
              </div>
            </div>
          }
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="form-block">
                <label>
                  <img src="images/lg-phone.png" alt="" /> Additional Comments (optional)
                </label>
                <textarea
                  rows="7"
                  placeholder="Additional Comments"
                  value={values.additionalInformation}
                  onChange={handleChange}
                  name="additionalInformation"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-actions btn-list mt-3">
                <button onClick={this.saveAndContinue}
                  id="Success-8"
                  disabled={disabled || !isValidPhoneNumber(values.phoneNumber)}
                  className="btn btn-primary"
                  type="submit">
                  Finish &rarr;
                </button>
                <div className='invalid-feedback'>
                  {
                    values.emailAddressError ? 'Email address has errors.' : null
                  }
                </div>
                <div className='invalid-feedback'>
                {
                  values.useFirstnameError ? 'First name has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.userLastNameHasError ? 'Last name has errors.' : null
                }
              </div>
                <br/>
                <p><a href={hrefLink} onClick={this.props.prevStep}><img src="images/back.png" alt="Back"/> Back</a></p>
              </div>
            </div>
          </div>
        </form>
      </section>
    )
  }
}

export default Success;