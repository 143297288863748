import React from 'react';
import Filler from './Filler';

const ProgressBar = (props) => {
  return (
    <div className={props.isMobile ? 'mobile-progress-bar' : 'line-progress-bar'}>
      <Filler percentage={props.percentage} isMobile={props.isMobile} />
    </div>
  )
}

export default ProgressBar;