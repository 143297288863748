import _ from 'lodash';
import React from 'react';
import axios from 'axios';
import config from '../config';
import airbrake from '../config/airbrake';

class Drivers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      drivers: [],
      vehicles: []
    }

    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.addAnotherDriver = this.addAnotherDriver.bind(this);
  }

  componentDidMount() {
    const { values } = this.props;
    window.scrollTo(0, 0)
    
    axios.get(`${config.apiUrl}/quotes/${values.quoteId}`)
      .then(response => {
        this.setState({
          vehicles: response.data.vehicles,
          drivers: response.data.drivers
        })
      }).catch(error => {
        console.log(error);
        // Notify Airbrake about the error
        airbrake.notify({
          error: error,
          params: {
            endpoint: `DriverList Page - ${error} - API End point: ${config.apiUrl}/quotes/${values.quoteId}`,
          }
        });
      });
  }

  saveAndContinue(e) {
    e.preventDefault()

    const { values } = this.props;

    if (values.drivers.length > 1) {
      this.props.nextStep(true);
    } else {
      this.props.nextStep();
    }
  }

  addAnotherDriver(e) {
    e.preventDefault();

    this.props.goToStep(2);
  }

  render() {
    const { drivers } = this.state;
    const { values } = this.props;
    
    const driverLimitExceeded = this.state.drivers.length === 4;
    let hrefLink = '#';

    return (
      <section id="Welcome" className="tm-section">
        <h5>Drivers</h5>
        {
          _.map(drivers, driver => (
            <div className="card" key={driver.id}>
              <div className="card-body p-2">
                <ul className="driver-list">
                  <li>
                    <h4>
                      <img src="images/driver.png" alt="Driver" />
                      { driver.firstname } { driver.lastname }
                    </h4>
                    <small className="pull-right">
                      <hr />
                      <a href={hrefLink} onClick={(e) => this.props.editDriver(e, driver.id)}>
                        <img src="images/edit.png" alt="Edit" /> Edit {driver.firstname}
                      </a>
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          ))
        }
        <button onClick={this.addAnotherDriver}
          disabled={driverLimitExceeded}
          className="btn btn-primary"
          type="submit">
          Add another driver
        </button>
        <div className='invalid-feedback'>
          {
            driverLimitExceeded &&
            <p>Please note, our online quote can only support up to 4 drivers. You may continue with the 4 drivers only, or call us with your quote reference number {values.quoteReferenceNumber} and one of our brokers will be happy to assist.</p>
          }
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-3">
              <button onClick={this.saveAndContinue}
                className="btn btn-green"
                id="DriverList-5"
                type="submit">
                Next Step &rarr;
              </button>
              <br/>
              <p><a href={hrefLink} onClick={this.props.prevStep}><img src="images/back.png" alt="Back"/> Back</a></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Drivers;