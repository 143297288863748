import moment from 'moment';
import React from 'react';
import ReactTooltip from 'react-tooltip'
import Radio from "./lib/Radio";
import DatePicker from "react-datepicker";
import MaskedInput from 'react-text-mask'
import "react-datepicker/dist/react-datepicker.css";

class EffectiveDate extends React.Component {

  constructor(props) {
    super(props);

    this.saveAndContinue = this.saveAndContinue.bind(this);
  }

  saveAndContinue(e) {
    e.preventDefault()
    
    this.props.save(null, 'effectiveDate');
    this.props.nextStep()
  }

  intent() {
    return [
      'I bought a car and I need insurance',
      'I have insurance and I\'m shopping around',
      'My insurance was cancelled or is being cancelled and I need help',
      'I\'m just looking around',
    ]
  }

  render() {
    const {
      handleChange
    } = this.props;

    const { values } = this.props;

    return (
      <section id="welcome" className="tm-section">
        <h3 className="mb-2 mt-3">Hi, let's get started!</h3>
        <hr />
         <div className="row text-center justify-content-center">
         <ReactTooltip />
          <div className="row">
            <ReactTooltip />
            <div className="col-md-8">
              <div className="form-block mt-3">
                <label>
                  <img src="images/hero.png" alt="" /> How can we be your HERO?
                </label>
                <div className="btn-group-toggle" data-toggle="buttons">
                  <Radio
                    id="0"
                    name="intent"
                    handleChange={handleChange}
                    value={values.intent}
                    radioValues={this.intent()}
                  />
                </div>
              </div>
            </div>
          </div>

          {
            values.intent &&
              <div className="col-md-12">
                <div className="form-actions btn-list mt-3">
                  <label>
                    <img src="images/calendar.png" alt="" /> When do you need the insurance?
                  </label><br/>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    autoComplete="off"
                    showMonthYearDropdown
                    name="effectiveDate"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={values.effectiveDate}
                    minDate={moment().toDate()}
                    maxDate={moment().add(60, 'days').toDate()}
                    onChange={(e) => this.props.handleDateChange(e, 'effectiveDate')}
                  />
                </div>
              </div>
          }
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-3">
              <button onClick={this.saveAndContinue}
                id="EffectiveDate-1"
                disabled = { values.formHasErrors || !values.effectiveDate }
                className="btn btn-primary"
                type="submit">
                Next Step &rarr;
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default EffectiveDate;