import _ from 'lodash';
import moment from 'moment'
import React from 'react';
import axios from 'axios';
import config from '../config';
import Checkbox from "./lib/Checkbox";
import Radio from "./lib/Radio";
import Select from 'react-select';
import ReactTooltip from 'react-tooltip'
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import DatePicker from "react-datepicker";
import MaskedInput from 'react-text-mask'
import Tooltip from './lib/Tooltip';
import airbrake from '../config/airbrake';
import "react-datepicker/dist/react-datepicker.css";

const validations = {
  postalCodeError: '',
  formHasErrors: false,
}

class Vehicle extends React.Component {

  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.state = {
      years: [],
      makes: [],
      models: [],
      city: '',
      ...validations
    }

    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.checkForTrue = this.checkForTrue.bind(this);
    this.showDistanceDrivenYearly = this.showDistanceDrivenYearly.bind(this);
    this.addAnother = this.addAnother.bind(this);
    this._save = this._save.bind(this);
  }


  componentDidMount() {
    if (window.location.search) {
      this.setState({
        utm: window.location.search
      });
    }

    if (window.location.hash) {
      this.setState({
        utm: window.location.hash
      });
    }

    axios.get(`${config.fortusDbUrl}/api/year`)
      .then(response => {
        let years = [];
        _.map(response.data, (data) => {
          years.push({
            value: data,
            label: data
          })
        })
        this.setState({
          years: years.reverse()
        })
      }).catch(error => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.props;
    if (values.selectedYear && values.selectedYear !== prevProps.values.selectedYear) {
      // this is a bit hacky as we're returning data filtered but if they select "1980"
      // and then the models update, and then they selected "2018" the models need to update
      // again to reflect the year change... only on state change does the make update.
      // We can refactor this into one database/api call and use client side filtering instead
      axios.get(`${config.fortusDbUrl}/api/make/${values.selectedYear.value}`)
        .then(response => {
          let makes = [];
          _.map(response.data, (data) => {
            makes.push({
              value: data,
              label: data
            })
          })
          this.setState({
            makes
          })
        });
    }

    if (values.selectedMake && values.selectedMake !== prevProps.values.selectedMake) {
      axios.get(`${config.fortusDbUrl}/api/model/${values.selectedYear.value}/${values.selectedMake.value}`)
        .then(response => {
          let models = [];
          _.map(response.data, (data) => {
            models.push({
              value: data,
              label: data
            })
          })
          this.setState({
            models
          })
        });
    }

    if (values.selectedModel && values.selectedModel !== prevProps.values.selectedModel) {
      axios.get(`${config.fortusDbUrl}/api/get_vicc/${values.selectedYear.value}/${values.selectedMake.value}/${values.selectedModel.value}`)
        .then(vicc =>
          this.setState({
            vicc: vicc.data[0]["vicc_code"],
            ext_vicc: vicc.data[1]["ext_vicc"],
            price: vicc.data[2]["price"]
          })
        );
    }
  }

  makeId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text.toUpperCase();
  }

  addAnother(e) {
    e.preventDefault();
    this._save(true)
  }

  saveAndContinue = async (e) => {
    e.preventDefault()

    const { values } = this.props;
    if ((!_.isEmpty(values.vehicleId) || values.vehicleId) && (values.editVehicle || values.usedBackLink)) {
      // editing
      const vehUsage = [];
      let vehicleData = {};

      Object.keys(values.vehicleUsage).map((usage, i) => {
        if (values.vehicleUsage[usage].isChecked) {
          vehUsage.push(values.vehicleUsage[usage].value)
        }
        return vehUsage;
      });

      const comBusUsage = [];

      Object.keys(values.commercialBusinessUsage).map((usage, i) => {
        if (values.commercialBusinessUsage[usage].isChecked) {
          comBusUsage.push(values.commercialBusinessUsage[usage].value)
        }
        return comBusUsage;
      });

      if (!values.address) {
        vehicleData.location_index = values.locationIndex;
        vehicleData.location_city = values.city;
        vehicleData.postal_code = values.postalCode;
        vehicleData.parking_address = values.address;
      } else {
        vehicleData.location_index = values.locationIndex;
        vehicleData.location_city = values.locationCity;
        vehicleData.postal_code = values.postalCode;
        vehicleData.parking_address = values.address;
      }

      try {
        let postalCode = values.postalCode.replace(/ /g,'')
        // add middle space, required for API location endpoint
        // as the location index data is formatted in 2 and if we don't have
        // a match then we split and use the first as seen below.
        postalCode = postalCode.slice(0, 3) + " " + postalCode.slice(3);

        const response = await fetch(`${config.apiUrl}/location/${postalCode}`);
        const index = await response.json();

        let loc_indx, loc_city;
        if (_.isEmpty(index)) {
          const response = await fetch(`${config.apiUrl}/location/${values.postalCode.slice(0, 3)}`);
          const index = await response.json();
          loc_indx = _.get(_.first(index), 'gcpcid');
          loc_city = _.get(_.first(index), 'city');
        } else {
          loc_indx = _.get(index[0], 'gcpcid');
          loc_city = _.get(_.first(index), 'city');
        }
        vehicleData.location_index = loc_indx;
        vehicleData.location_city = loc_city;
      } catch (error) {
        console.error("Error fetching location data", error);
      }
      if (!this.state.vicc || !this.state.ext_vicc) {
        // Comment the below code to pass the changed value vehicle vicc_code, ext_vicc and price on vehicle edit.

        // if (values.userSelectedVehicles[0].ext_vicc && values.userSelectedVehicles[0].vicc_code) {
        //   vehicleData.vicc_code = values.userSelectedVehicles[0].vicc_code;
        //   vehicleData.ext_vicc = values.userSelectedVehicles[0].ext_vicc;
        //   vehicleData.price = values.userSelectedVehicles[0].price;
        // } else {
          vehicleData.vicc_code = values.viccCode;
          vehicleData.ext_vicc = values.extViccCode;
          vehicleData.price = values.price
        // }
      } else {
        vehicleData.vicc_code = this.state.vicc;
        vehicleData.ext_vicc = this.state.ext_vicc;
        vehicleData.price = this.state.price;
      }

      vehicleData.vehicleId = values.vehicleId;
      vehicleData.year = values.selectedYear.value;
      vehicleData.make = values.selectedMake.value;
      vehicleData.model = values.selectedModel.value;
      vehicleData.purchase_condition = values.purchaseCondition;
      vehicleData.purchase_date = moment(values.purchaseDate).format('YYYY-MM-DD');
      vehicleData.distance_oneway = values.distanceDrivenOneWay;
      vehicleData.distance_annually = values.distanceDrivenAnnually;
      vehicleData.ownership_status = values.ownershipStatus;
      vehicleData.commercial_business_percent = values.commercialBusinessPercent;
      vehicleData.winter_tires = values.winterTires === 'Yes' ? true :  false;
      vehicleData.has_condo_or_apartment = values.ownRent === 'Yes' ? true : false;
      vehicleData.rent_own = values.ownRent || '';
      vehicleData.rent_own_other = values.ownRentOther || '';
      vehicleData.vehicleUsage = vehUsage;
      vehicleData.commercialBusinessUsage = comBusUsage;
      vehicleData.commercial_business_use_other = values.commercialBusinessOther
      vehicleData.apply_discount = values.applyDiscount === 'Yes' ? true : false;

      axios.put(`${config.apiUrl}/vehicles/${values.vehicleId}`, vehicleData)
        .then((response) => {
          const { vehicle } = response.data;

          axios.get(`${config.apiUrl}/quotes/${vehicle.quote_id}`)
            .then(response => {
              const { drivers } = response.data;
              if (!drivers.length) {
                this.props.go(2);
              } else {
                this.props.go('vehicleList');
              }
          }).catch(error => {
            console.log(error);
            airbrake.notify({
              error: error,
              params: {
                endpoint: `Vehicle Page - ${error} - API End point: ${config.apiUrl}/quotes/${vehicle.quote_id}`,
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      this._save();
    }
  }

  fetchLocationIndexAndSave(vehicleData, quoteData) {
    const {
      values,
      save
    } = this.props;

    // remove ALL spaces.
    let postalCode = values.postalCode.replace(/ /g,'')
    // add middle space, required for API location endpoint
    // as the location index data is formatted in 2 and if we don't have
    // a match then we split and use the first as seen below.
    postalCode = postalCode.slice(0, 3) + " " + postalCode.slice(3);

    fetch(`${config.apiUrl}/location/${postalCode}`)
      .then(response => response.json())
      .then(index => {
        if (_.isEmpty(index)) {
          // No match found for full postal code so use the first
          // three from postal code and use the '...' for location index
          // Use first three for postal code by slice(0, 3), split(' ')[0] not returns a first three character if user has not added a space in postal code
          // fetch(`${config.apiUrl}/location/${values.postalCode.split(' ')[0]}`)
          fetch(`${config.apiUrl}/location/${values.postalCode.slice(0, 3)}`)
            .then(response => response.json())
            .then(index => {
              const locationIndex = _.get(_.first(index), 'gcpcid');
              const city = _.get(_.first(index), 'city');
              vehicleData.location_index = locationIndex;
              vehicleData.location_city = city;

              axios.post(`${config.apiUrl}/quotes`, quoteData)
                .then((response) => {
                  vehicleData.quote_id = response.data.id
                  this.setState({
                    quoteId: response.data.id
                  })
                  save(vehicleData, 'vehicles');
                })
                .catch((error) => {
                  console.log(error);
                })
            })
        } else {
          // match found for postal code, we can 0 index this one because
          // there will only ever be one returning if found for a postal code
          const locationIndex = _.get(index[0], 'gcpcid');
          const city = _.get(_.first(index), 'city')
          vehicleData.location_index = locationIndex;
          vehicleData.location_city = city;
          axios.post(`${config.apiUrl}/quotes`, quoteData)
            .then((response) => {
              vehicleData.quote_id = response.data.id
              this.setState({
                quoteId: response.data.id
              })
              save(vehicleData, 'vehicles');
            })
            .catch((error) => {
              console.log(error);
            })
        }
      });
  }

  fetchLocationIndex(vehicleData) {
    const {
      values,
      save
    } = this.props;

    // remove ALL spaces.
    let postalCode = values.postalCode.replace(/ /g,'')
    // add middle space, required for API location endpoint
    // as the location index data is formatted in 2 and if we don't have
    // a match then we split and use the first as seen below.
    postalCode = postalCode.slice(0, 3) + " " + postalCode.slice(3);

    fetch(`${config.apiUrl}/location/${postalCode}`)
      .then(response => response.json())
      .then(index => {
        if (_.isEmpty(index)) {
          // No match found for full postal code so use the first
          // three from postal code and use the '...' for location index
          // Use first three for postal code by slice(0, 3), split(' ')[0] not returns a first three character if user has not added a space in postal code
          // fetch(`${config.apiUrl}/location/${values.postalCode.split(' ')[0]}`)
          fetch(`${config.apiUrl}/location/${values.postalCode.slice(0, 3)}`)
            .then(response => response.json())
            .then(index => {
              const locationIndex = _.get(_.first(index), 'gcpcid');
              const city = _.get(_.first(index), 'city');
              vehicleData.location_index = locationIndex;
              vehicleData.location_city = city;
              save(vehicleData, 'vehicles');
            })
        } else {
          // match found for postal code, we can 0 index this one because
          // there will only ever be one returning if found for a postal code
          const locationIndex = _.get(index[0], 'gcpcid');
          const city = _.get(_.first(index), 'city')
          vehicleData.location_index = locationIndex;
          vehicleData.location_city = city;
          save(vehicleData, 'vehicles');
        }
      });
  }

  _save(addAnother) {
    const {
      values,
      save
    } = this.props;

    const vehUsage = [];

    Object.keys(values.vehicleUsage).map((usage, i) => {
      if (values.vehicleUsage[usage].isChecked) {
        vehUsage.push(values.vehicleUsage[usage].value)
      }
      return vehUsage;
    });

    const comBusUsage = [];

    Object.keys(values.commercialBusinessUsage).map((usage, i) => {
      if (values.commercialBusinessUsage[usage].isChecked) {
        comBusUsage.push(values.commercialBusinessUsage[usage].value)
      }
      return comBusUsage;
    });

    let vehicleData = {};

    vehicleData.year = values.selectedYear.value;
    vehicleData.make = values.selectedMake.value;
    vehicleData.model = values.selectedModel.value;
    vehicleData.purchase_condition = values.purchaseCondition;
    vehicleData.purchase_date = moment(values.purchaseDate).format('YYYY-MM-DD');
    vehicleData.distance_oneway = values.distanceDrivenOneWay;
    vehicleData.distance_annually = values.distanceDrivenAnnually;
    vehicleData.ownership_status = values.ownershipStatus;
    vehicleData.commercial_business_percent = values.commercialBusinessPercent;
    vehicleData.winter_tires = values.winterTires === 'Yes' ? true : false;
    vehicleData.vicc_code = this.state.vicc;
    vehicleData.ext_vicc = this.state.ext_vicc;
    vehicleData.price = this.state.price;
    vehicleData.postal_code = values.postalCode;
    vehicleData.parking_address = values.address;
    vehicleData.vehicle_use = vehUsage;
    vehicleData.commercial_business_use = comBusUsage;
    vehicleData.rent_own = values.ownRent;
    vehicleData.rent_own_other = values.ownRentOther;
    vehicleData.commercial_business_use_other = values.commercialBusinessOther
    vehicleData.apply_discount = values.applyDiscount === 'Yes' ?  true : false;

    const quoteData = {
      year: values.selectedYear.value,
      make: values.selectedMake.value,
      model: values.selectedModel.value,
      quote_number: this.makeId(),
      effective_date: moment(values.effectiveDate).format('YYYY-MM-DD'),
      intent: values.intent
    }

    if (values.quoteId) {
      // edit
      vehicleData.quote_id = values.quoteId
      this.fetchLocationIndex(vehicleData);
      // save(vehicleData, 'vehicles');
    } else if (this.state.quoteId) {
      vehicleData.quote_id = this.state.quoteId
      this.fetchLocationIndex(vehicleData);
      // save(vehicleData, 'vehicles');
    } else {
      if (this.state.utm) {
        quoteData.utm = this.state.utm;
      }
      if (values.fb) {
        quoteData.is_facebook = values.fb;
      }
      this.fetchLocationIndexAndSave(vehicleData, quoteData);
    }

    if (addAnother) {
      this.props.addAnotherVehicle();
      this.setState({
        vicc: '',
        ext_vicc: '',
        price: '',
        locationCity: '',
        locationIndex: '',
        postalCode: '',
        parking: '',
        city: '',
      })
    } else {
      this.props.nextStep()
    }
  }

  distanceDrivenOneWay() {
    let kmsOneWay = [];
    for (let i = 0; i < 11; i += 1) {
      kmsOneWay.push((i * 5).toString());
      if (i * 5 === 25) {
        i = i * 5 + 25;
        kmsOneWay.push((i).toString());
        kmsOneWay.push(i + 25 + "+");
      }
    }
    return kmsOneWay;
  }

  distanceDrivenAnnually() {
    let kmsAnnually = [];
    for (let i = 1; i < 10; i += 1) {
      kmsAnnually.push((i * 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      if (i * 5000 === 45000) {
        kmsAnnually.push((50000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+");
      }
    }
    return kmsAnnually;
  }

  ownershipStatus() {
    return [
      'Financed',
      'Not Financed',
      'Leased'
    ]
  }

  // Check vehicle use - if use of vehicle includes business/commercial then commercialBusinessUsage and commercialBusinessPercent should be present
  isIncorerctBusinessVehicleUseData() {
    const { values } = this.props;
    let found = false
    if (this.isBusinessCommercial()) {
      found = values.commercialBusinessUsage.some((el) => {
        return el.isChecked === true;
      })
      return !(found && values.commercialBusinessPercent)
    } else {
      return false
    }
  }

  checkForTrue() {
    const { values } = this.props;
    if (!_.isUndefined(values.vehicleUsage)) {
      // eslint-disable-next-line
      const found = values.vehicleUsage.some((el) => {
        if (el.id === 'Work/School') {
          return el.isChecked === true;
        }
      });
      return found;
    }
  }

  checkIfPleasure() {
    const {
      values
    } = this.props;

    if (!_.isUndefined(values.vehicleUsage)) {
      // eslint-disable-next-line
      const found = values.vehicleUsage.some((el) => {
        if (el.id === 'Pleasure') {
          return el.isChecked === true;
        }
      });
      return found;
    }
  }

  isBusinessCommercial() {
    const { values } = this.props;
    if (!_.isUndefined(values.vehicleUsage)) {
      // eslint-disable-next-line
      const found = values.vehicleUsage.some((el) => {
        if (el.id === 'Business/Commercial') {
          return el.isChecked === true;
        }
      });
      return found;
    }
  }

  showDistanceDrivenYearly() {
    const { values } = this.props;
    if (!_.isUndefined(values.vehicleUsage)) {
      const found = values.vehicleUsage.some((el) => {
        return el.isChecked === true;
      });
      return found;
    }
  }

  isOtherChecked() {
    const { values } = this.props;
    if (!_.isUndefined(values.commercialBusinessUsage)) {
      // eslint-disable-next-line
      const found = values.commercialBusinessUsage.some((el) => {
        if (el.id === 'Other') {
          return el.isChecked === true;
        }
      });
      return found;
    }
  }

  render() {
    const {
      handleChange,
      handleCustomSelect,
      handleCheckChildElement,
      values
    } = this.props;

    const {
      years,
      makes,
      models,
    } = this.state;

    const vehicleLimitExceeded = values.userSelectedVehicles.length === 2;

    const useSameAddress = values.userSelectedVehicles.length > 0;

    let showHideParking;
    if (values.winterTires && !useSameAddress) {
      showHideParking = true;
    } else if (values.isParkingSame === 'No') {
      showHideParking = true;
    } else {
      showHideParking = false;
    }

    let disableAddAnother;
    if (values.postalCode && !values.isParkingSame && values.applyDiscount) {
      disableAddAnother = false;
    } else if (vehicleLimitExceeded) {
      disableAddAnother = true;
    } else if (values.isParkingSame === 'No' && values.postalCode && !values.applyDiscount) {
      disableAddAnother = false;
    } else if (values.isParkingSame && !values.postalCode) {
      disableAddAnother = false;
    } else if (values.ownRent === 'Live with Parents') {
      disableAddAnother = false;
    } else if (values.ownRent === 'Other' && values.ownRentOther) {
      disableAddAnother = false;
    } else if (!values.applyDiscount) {
      disableAddAnother = true;
    } else {
      disableAddAnother = true;
    }

    let isDisabled;
    if (values.formHasErrors) {
      isDisabled = true;
    } else if (this.state.formHasErrors) {
      isDisabled = true;
    } else if (!values.postalCode && !values.isParkingSame) {
      isDisabled = true;
    } else if (this.isIncorerctBusinessVehicleUseData()) {
      isDisabled = true;
    } else if (_.isUndefined(values.postalCode)) {
      isDisabled = true;
    } else if (values.ownRent === 'Live with Parents') {
      isDisabled = false;
    } else if (values.ownRent === 'Other' && values.ownRentOther) {
      isDisabled = false;
    } else if (!values.applyDiscount) {
      isDisabled = true;
    } else if (!values.selectedYear || !values.selectedMake || !values.selectedModel){
      isDisabled = true
    } else {
      isDisabled = false;
    }

    let poDate;
    if ((!_.isEmpty(values.vehicleId) || values.vehicleId) && (values.editVehicle || values.usedBackLink) && (!_.isEmpty(values.purchaseDate))) {
      poDate = moment(values.purchaseDate, "MM-DD-YYYY").toDate();
    } else {
      poDate = values.purchaseDate
    }

    return (
      <section id="welcome" className="tm-section">
        <ReactTooltip />
        <div className="row" id="YearSelected">
          <div className="col-md-6">
            <div className="form-block">
              <label>
                <img src="images/red-car.png" alt="" /> What year is your vehicle?
              </label>
              <Select
                value={values.selectedYear}
                onChange = { (value) => handleCustomSelect('selectedYear', value, "1")}
                options={years}
                placeholder="Start Typing"
                name="selectedYear"
                isSearchable
              />
            </div>
          </div>
        </div>
        {
          values.selectedYear ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
              <ReactTooltip />
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/red-car.png" alt="" /> What is the make of your vehicle?
                    </label>
                    <Select
                      defaultMenuIsOpen={true}
                      autoFocus={true}
                      value={values.selectedMake}
                      onChange = { (value) => handleCustomSelect('selectedMake', value, "2")}
                      options={makes}
                      placeholder="Start Typing"
                      isSearchable
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.selectedMake ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row" id="vehicle-model">
              <ReactTooltip />
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/red-car.png" alt="" /> What is the model of your vehicle?
                    </label>
                    <Select
                      autoFocus={true}
                      defaultMenuIsOpen={true}
                      value={values.selectedModel}
                      onChange = { (value) => handleCustomSelect('selectedModel', value, "3")}
                      options={models}
                      placeholder="Start Typing"
                      isSearchable
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.selectedModel ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
              <ReactTooltip />
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/condition.png" alt="" /> Purchase condition
                    </label>
                    <div className="btn-group-toggle" data-toggle="buttons">
                      <label className={'btn btn-radio mr-1' + (values.purchaseCondition === 'New' ? ' active': '')}>
                        <input
                          type="radio"
                          value="New"
                          id="4"
                          name="purchaseCondition"
                          defaultChecked={values.purchaseCondition === "New"}
                          onChange={handleChange}
                          autoComplete="off" /> New
                      </label>
                      <label className={'btn btn-radio mr-1' + (values.purchaseCondition === 'Used' ? ' active': '')}>
                        <input
                          type="radio"
                          value="Used"
                          id="4"
                          name="purchaseCondition"
                          defaultChecked={values.purchaseCondition === "Used"}
                          onChange={handleChange}
                          autoComplete="off" /> Used
                      </label>
                      <label className={'btn btn-radio mr-1' + (values.purchaseCondition === 'Demo' ? ' active': '')}>
                        <input
                          type="radio"
                          value="Demo"
                          id="4"
                          name="purchaseCondition"
                          defaultChecked={values.purchaseCondition === "Demo"}
                          onChange={handleChange}
                          autoComplete="off" /> Demo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.purchaseCondition ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <div className="col-md-6">
                <div className={`form-block mt-3`}>
                  <label>
                    <img src="images/calendar.png" alt="" /> Purchase date
                  </label><br/>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false }
                    }}
                    id="5"
                    autoComplete="off"
                    showYearDropdown
                    maxDate={moment().add(2, 'years').toDate()}
                    scrollableYearDropdown
                    scrollableMonthDropdown
                    showMonthDropdown
                    name="purchaseDate"
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={poDate}
                    onChange={(e) => this.props.handleDateChange(e, 'purchaseDate')}
                  />
                  <div className='invalid-feedback'>{values.purchaseDateError}</div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
          : null
        }
        {
          (values.purchaseDate && !values.purchaseDateError) ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
              <ReactTooltip html={true} />
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/usage.png" alt="" /> What do you use the vehicle for? (Select all that apply)
                    </label>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-group colors" data-toggle="buttons">
                          {
                            values.vehicleUsage && values.vehicleUsage.map((usage) => {
                              return (
                                <Checkbox
                                  key={usage.id}
                                  questionId="6"
                                  handleCheckChildElement={handleCheckChildElement}
                                  {...usage}
                                />
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          this.checkForTrue() ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <ReactTooltip />
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/seat_belt.png" alt="" /> Distance driven one way (In KMs)
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      id="7"
                      label="Distance driven 1 way"
                      name="distanceDrivenOneWay"
                      handleChange={handleChange}
                      value={values.distanceDrivenOneWay}
                      radioValues={this.distanceDrivenOneWay()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.distanceDrivenOneWay === '75+'
          ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/red-car.png" alt="" /> Please enter the distance you drive 1-way.
                    </label>
                    <input
                      type="number"
                      name="userEnteredDistanceOneWay"
                      className="form-control"
                      placeholder='Distance driven one way'
                      value={values.userEnteredDistanceOneWay}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.distanceDrivenOneWay || values.userEnteredDistanceOneWay || this.checkIfPleasure() ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <ReactTooltip />
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/seat_belt.png" alt="" /> Distance driven annually (In KMs)
                    </label>
                    <div className="btn-group-toggle" data-toggle="buttons">
                      <Radio
                        id="8"
                        label="Distance driven annually"
                        name="distanceDrivenAnnually"
                        handleChange={handleChange}
                        value={values.distanceDrivenAnnually}
                        radioValues={this.distanceDrivenAnnually()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.distanceDrivenAnnually === '50,000+'
          ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/red-car.png" alt="" /> Please enter the distance you drive annually.
                    </label>
                    <input
                      type="number"
                      name="userEnteredDistanceAnnually"
                      className="form-control"
                      placeholder='Distance driven annually'
                      onChange={handleChange}
                      value={values.userEnteredDistanceAnnually}
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          this.isBusinessCommercial() ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/red-car.png" alt="" /> What percentage of the time do you use your vehicle for business/commercial?
                    </label>
                    <input
                      type="number"
                      name="commercialBusinessPercent"
                      className="form-control percentage"
                      placeholder='Percentage'
                      onChange={handleChange}
                      value={values.commercialBusinessPercent}
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        { (this.isBusinessCommercial() && values.commercialBusinessPercent) ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/usage.png" alt="" /> What type of business/commercial use? (Select all that apply)
                    </label>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-group colors" data-toggle="buttons">
                          {
                            values.commercialBusinessUsage && values.commercialBusinessUsage.map((usage) => {
                              return (
                                <Checkbox
                                  key={usage.id}
                                  handleCheckChildElement={handleCheckChildElement}
                                  {...usage}
                                />
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          this.isOtherChecked() ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/other.png" alt="" /> Please describe what you use the vehicle for.
                    </label>
                    <input
                      name="commercialBusinessOther"
                      className="form-control"
                      placeholder='Other'
                      onChange={handleChange}
                      defaultValue={values.commercialBusinessOther}
                      value={values.commercialBusinessOther}
                    />
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.distanceDrivenAnnually || this.isBusinessCommercial() ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
              <ReactTooltip html={true} />
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/ownership.png" alt="" /> Ownership status &nbsp;
                      <Tooltip placement="right" trigger="click" id='ownership-tooltip' tooltip="If vehicle is financed or leased your lender or leasing company will require that you purchase collision and comprehensive coverage.">
                        <img src="images/help.png" alt="/"/>
                      </Tooltip>
                    </label>
                    <div className="btn-group-toggle" data-toggle="buttons">
                      <Radio
                        id="9"
                        label="Ownership status"
                        name="ownershipStatus"
                        handleChange={handleChange}
                        value={values.ownershipStatus}
                        radioValues={this.ownershipStatus()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
          : null
        }
        {
          values.ownershipStatus ?
          <ScrollIntoViewIfNeeded options={{
            scrollMode: 'always',
          }}>
            <div className="row">
              <ReactTooltip />
              <div className="col-md-12">
                <div className="form-block mt-3">
                  <label>
                    <img src="images/tire.png" alt="" /> Winter tires
                  </label>
                  <div className="btn-group-toggle" data-toggle="buttons">
                    <Radio
                      name="winterTires"
                      id="10"
                      handleChange={handleChange}
                      value={values.winterTires}
                      radioValues={['Yes', 'No']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollIntoViewIfNeeded>
          : null
        }

        {
          useSameAddress && values.winterTires && !values.address
          ?
            <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                      <img src="images/question.png" alt="" /> Is this vehicle also mostly parked at the same location as the first vehicle?
                    </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          name="isParkingSame"
                          id="10"
                          handleChange={handleChange}
                          value={values.isParkingSame}
                          radioValues={['Yes', 'No']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
            : null
          }

          <React.Fragment>
          {
            showHideParking ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always'
              }}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-block mt-3">
                      <label>
                        <img src="images/address.png" alt="" /> Street address
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-block mt-1">
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <input
                          type="text"
                          name="unitNumber"
                          className="form-control"
                          placeholder="Unit or Apt #"
                          value={values.unitNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-block mt-1">
                      <label>
                        <img src="images/address.png" alt="" /> City
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-block mt-1">
                      <label>
                        <img src="images/address.png" alt="" /> Province
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <input
                          type="text"
                          name="province"
                          className="form-control"
                          value="Ontario"
                          readOnly="readonly"
                        />
                      </div>
                    </div>
                    <div className='help-text'>
                      <img src="images/info.png" alt="Info" />&nbsp; Note: For Ontario residents only
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-block mt-1">
                      <label>
                        <img src="images/address.png" alt="" /> Postal code
                      </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <input
                          type="text"
                          name="postalCode"
                          className="form-control"
                          placeholder="Postal Code"
                          value={values.postalCode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='invalid-feedback'>{values.postalCodeError}</div>
                  </div>
                </div>
            </ScrollIntoViewIfNeeded>
            : null
          }
          </React.Fragment>
          {
            values.postalCode ?
              <ScrollIntoViewIfNeeded options={{
                scrollMode: 'always',
              }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-block mt-3">
                      <label>
                      <img src="images/address.png" alt="" /> Do you own or rent this location? (discounts could apply)
                    </label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        <Radio
                          name="ownRent"
                          id="10"
                          handleChange={handleChange}
                          value={values.ownRent}
                          radioValues = {
                            ['Own', 'Rent', 'Live with Parents', 'Other']
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollIntoViewIfNeeded>
              : null
          }

          {
            (values.ownRent === 'Rent' || values.ownRent === 'Own') ?
            <ScrollIntoViewIfNeeded options={{
              scrollMode: 'always',
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-block mt-3">
                    <label>
                      <img src="images/money.png" alt="" /> Would you like to save up to 15% on your auto insurance by applying the home and auto discount? (Subject to eligibility) &nbsp;
                      <Tooltip placement="right" trigger="click" id='discount-tooltip' tooltip="In order to qualify for this discount your property insurance must be with the same insurance company as your auto insurance. Additional premium applies for property insurance policy.">
                        <img src="images/help.png" alt="/"/>
                      </Tooltip>
                    </label>
                    <div className="btn-group-toggle" data-toggle="buttons">
                      <Radio
                        name="applyDiscount"
                        id="10"
                        handleChange={handleChange}
                        value={values.applyDiscount}
                        radioValues={['Yes', 'No']}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ScrollIntoViewIfNeeded>
            : null
          }

          {
            values.ownRent === 'Other' ?
              <div className="row mt-3">
                <div className="col-md-8">
                  <div className="form-block">
                    <label>
                      <img src="images/building.png" alt="" /> Other
                    </label>
                    <input
                      id="13"
                      type="text"
                      name="ownRentOther"
                      className="form-control"
                      placeholder='Other'
                      onChange={handleChange}
                      defaultValue={values.ownRentOther}
                    />
                  </div>
                </div>
              </div>
            : null
          }

        <ScrollIntoViewIfNeeded options={{
          scrollMode: 'always',
        }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-actions btn-list mt-3">
                <button
                  disabled={isDisabled}
                  onClick={this.saveAndContinue}
                  className="btn btn-green"
                  id="Vehicle-2"
                  type="submit">
                    Next Step &rarr;
                </button>
                <div className='invalid-feedback'>
                  {values.purchaseDateError ? "Purchase Date has errors." : null}
                </div>
                <div className='invalid-feedback'>
                {
                  values.parking && _.isUndefined(values.postalCode) ? "Invalid address. Start typing your address and make a selection from the list " : null
                }
                </div>
                <br /><br />
                <button
                  disabled={disableAddAnother}
                  className="btn btn-primary btn-sm"
                  onClick={this.addAnother}
                  type="submit">Add another vehicle</button>

                  <div className='invalid-feedback'>
                    {
                      (vehicleLimitExceeded && (values.parking || values.isParkingSame) ) &&
                      <p>Please note, our online quote can only support up to 3 vehicles. You may continue with the 3 vehicles only, or call us with your quote reference number {values.quoteReferenceNumber} and one of our brokers will be happy to assist.</p>
                    }
                  </div>
                </div>
            </div>
          </div>
        </ScrollIntoViewIfNeeded>
        </section>

    )
  }
}

export default Vehicle;
