import _ from 'lodash';
import React from 'react';
import Vehicle from './Vehicle';
import Driver from './Driver';
import DriversList from './DriversList';
import axios from 'axios';
import config from '../config';
import moment from 'moment';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Convictions from './Convictions';
import Assignment from './Assignment';
import VehicleList from './VehicleList';
import PersonalInformation from './PersonalInformation';
import Quotes from './Quotes';
import Success from './Success';
import Complete from './Complete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ProgressBar from './lib/progress-bar/ProgressBar';
import EffectiveDate from './EffectiveDate';
import BookCall from './lib/BookCall';
import airbrake from '../config/airbrake';

let psid;

const initialVehicleState = {
  ownRent: '',
  ownRentOther: '',
  purchaseCondition: '',
  purchaseDate: '',
  distanceDrivenOneWay: '',
  userEnteredDistanceOneWay: '',
  userEnteredDistanceAnnually: '',
  distanceDrivenAnnually: '',
  ownershipStatus: '',
  winterTires: '',
  selectedYear: '',
  selectedMake: '',
  selectedModel: '',
  questionNumber: 0,
  commercialBusinessPercent: '',
  commercialBusinessOther: '',
  parking: '',
  address: '',
  isParkingSame: '',
  vehicleUsage: [{
        id: "Work/School",
        value: "Getting to work or School",
        isChecked: false
      },
      {
        id: "Pleasure",
        value: "Pleasure Use",
        isChecked: false
      },
      {
        id: "Business/Commercial",
        value: "Business or Commercial",
        isChecked: false
      },
    ],
    commercialBusinessUsage: [{
        id: "Visiting clients or suppliers",
        value: "Visiting clients or suppliers",
        isChecked: false
      },
      {
        id: "Carrying tools and equipment for work",
        value: "Carrying tools and equipment for work",
        isChecked: false
      },
      {
        id: "Other",
        value: "Other",
        isChecked: false
      },
    ],
}

const validations = {
  driverFirstnameError: '',
  driverLastnameError: '',
  licenseClassError: '',
  purchaseDateError: '',

  licenseClassDateError1: '',
  licenseClassDateError2: '',

  g2DateError2: '',
  g2DateError3: '',
  g2DateError4: '',

  g1DateError2: '',
  g1DateError3: '',
  g1DateError4: '',

  endDateError1: '',

  birthdateAgeError: '',
  cancelStartDateError: '',
  phoneNumberError: '',
  formHasErrors: false,
  phoneHasErrors: false,
  emailHasErrors: false,
  driverFirstnameHasError: false,
  driverLastnameHasError: false

}

const convictions = {
  speedingTicketsCount: 0,
  impairedCount: 0,
  carelessCount: 0,
  noInsuranceCount: 0,
  noLicenseCount: 0,
  otherMinorTicketsCount: 0,
  otherMajorTicketsCount: 0,
  cancellationCount: 0,
  licenseSuspensionCount: 0,
  atFaultAccidentCount: 0,
  nonAtFaultAccidentCount: 0,
  windshieldRepairCount: 0,
  fireCount: 0,
  theftVandalismCount: 0,
  otherClaimCount: 0,
  kmsOverLimit: null,
  speedingAmount: [],
  impaired: [],
  careless: [],
  insurance: [],
  suspended: [],
  otherMinor: [],
  otherMajor: [],
  insuranceGaps: [],
  suspensions: [],
  atFaultAccidents: [],
  nonAtFaultAccidents: [],
  windshieldRepairs: [],
  fires: [],
  theftVandalisms: [],
  otherClaimAccidents: [],
  convictionDate: '',
  startDate: '',
  endDate: '',
  convictionType: '',
  hasCancellations: '',
  hasTickets: '',
  hasClaims: '',
  hasSuspensions: '',
}

class QuoteBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      vehicleAssignment: '',
      additionalInformation: '',
      preferredTime: '',
      yearsSuspended: '',
      completedDriversTraining: '',
      driversTrainingDate: '',
      gDateFiveOrLess: '',
      quoteId: '',
      convictionId: '',
      vehicleId: [],
      drivers: [],
      userSelectedVehicles: [],
      driverId: '',
      primaryUse: '',
      policyExpireDate: '',
      parking: '',
      isParkingSame: '',
      selectedAddress: '',
      driverFirstname: '',
      driverLirstname: '',
      maritalStatus: '',
      employmentStatus: '',
      occupation: '',
      goodGrades: '',
      licenseClassDate: '',
      isAfterOrOn: false,
      fb: false,
      hasG2: '',
      OutOfCountryDriver: '',
      g2Date: '',
      gender: '',
      licenseClass: '',
      birthdate: '',
      currentlyInsured: '',
      previouslyInsuredDuration: '',
      hasHadInsurance: '',
      effectiveDate: '',
      intent: '',
      yearsContinuouslyInsured: '',
      questionNumber: 0,
      yearsCurrentCompany: '',
      dateLastInsured: '',
      userFirstName: '',
      userLastName: '',
      phoneNumber: '',
      emailAddress: '',
      otherClaim: '',
      otherCancel: '',
      vehicleAssigned: '',
      primaryDriver: [],
      quoteReferenceNumber: '',
      vehiclesAvailable: [],
      driversAvailable: [],
      selectedLiabilityLimit: '',
      selectedComprehensiveDeductible: '',
      selectedCollisionDeductible: '',
      accidentForgiveness: '',
      rentalVehicleReplacement: '',
      rentalVehicleInsurance: '',
      purchasePriceProtection: '',
      customQuoteIsValid: true,
      commercialBusinessOther: '',
      percentage: 30,
      hasTickets: '',
      hasSuspensions: '',
      hasClaims: '',
      hasCancellations: '',
      applyDiscount: '',
      hasFacility: null,
      ...initialVehicleState,
      ...validations,
      ...convictions
    }

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCustomSelect = this.handleCustomSelect.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.save = this.save.bind(this);
    this.incrementCount = this.incrementCount.bind(this);
    this.decrementCount = this.decrementCount.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.setHasFacility = this.setHasFacility.bind(this);
    this.getHasFacility = this.getHasFacility.bind(this);
    this.go = this.go.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
    this.addAnotherVehicle = this.addAnotherVehicle.bind(this);
    this.handleConviction = this.handleConviction.bind(this);
    this.handleMultiAssignment = this.handleMultiAssignment.bind(this);
    this.applyNonPayFilter = this.applyNonPayFilter.bind(this);
    this.selectPackage = this.selectPackage.bind(this);
    this.editVehicle = this.editVehicle.bind(this);
    this.deleteVehicle = this.deleteVehicle.bind(this);
    this.editDriver = this.editDriver.bind(this);
    this.restartQuote = this.restartQuote.bind(this);
    this.setStep = this.setStep.bind(this);
    this.onUnload = this.onUnload.bind(this);
  }

  onUnload(event) { // the method that will be used for both add and remove event
    event.returnValue = "back"
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)
    if (window.location.pathname === '/fb') {
      window.extAsyncInit = () => {
        console.log("the Messenger Extensions JS SDK is done loading");
        window.MessengerExtensions.getSupportedFeatures(function success(result) {
          let features = result.supported_features;
          if (features.includes("context")) {
            window.MessengerExtensions.getContext(process.env.QUOTER_REACT_APP_FB_APP_ID, function success(thread_context) {
              // success
              psid = thread_context.psid;
            }, function error(err) {
              // error
              console.log(err);
            });
          }
        }, function error(err) {
          // error retrieving supported features
          console.log(err);
        });
      }
      this.setState({
        fb: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vehicleId !== this.state.vehicleId || prevState.driverId !== this.state.driverId) {
      // fetch vehicles for quoteid
      axios.get(`${config.apiUrl}/quotes/${this.state.quoteId}`)
      .then(response => {

        this.setState({
          userSelectedVehicles: response.data.quote.vehicles,
          drivers: response.data.drivers,
          quoteReferenceNumber: response.data.quote.quote_number
        })
      }).catch(error => {
        console.log(error);
        airbrake.notify({
          error: error,
          params: {
            endpoint: `QuoterBuilder 1 Page- ${error} - API End point: ${config.apiUrl}/quotes/${this.state.quoteId}`,
          }
        });
      });
    }
  }

  restartQuote() {
    // typically you'd clear the state but there is a lot of state
    // but there is also a lot of things that happen when the components
    // mount so, resources are cheap so a simple reload will work just fine.
    window.location.reload();
  }

  selectPackage(pkg, carrier, premium, otherPackages) {
    let carrierFullName;
    switch(carrier) {
      case "ALGM":
        carrierFullName = "Algoma Mutual"
        break;
      case "GA":
        carrierFullName = "Aviva"
        break;
      case "GA2":
        carrierFullName = "Aviva Journey"
        break;
      case "COA":
        carrierFullName = "Coachman"
        break;
      case "ECGL":
        carrierFullName = "Echelon"
        break;
      case "ECON":
        carrierFullName = "Economical"
        break;
      case "FAC":
        carrierFullName = "Facility"
        break;
      case "GORE":
        carrierFullName = "Gore Mutual"
        break;
      case "HAL":
        carrierFullName = "Intact Insurance"
        break;
      case "HAL3":
        carrierFullName = "Intact My Drive"
        break;
      case "JEV":
        carrierFullName = "Jevco Insurance Company"
        break;
      case "PAF":
        carrierFullName = "Pafco"
        break;
      case "PEM":
        carrierFullName = "Pembridge"
        break;
      case "SGI":
        carrierFullName = "SGI"
        break;
      // case "WEST":
      //   carrierFullName = "Western Assurance"
      // break;
      default:
        carrierFullName = carrier
        break;
    }
    const pkgData = {
      package: pkg,
      carrier: carrierFullName,
      premium: premium,
      quote_number: this.state.quoteReferenceNumber,
      otherPackages: otherPackages
    }

    axios.put(`${config.apiUrl}/update_quote_price/${this.state.quoteId}`, pkgData)
      .then((response) => {
        this.setHasFacility()
        axios.put(`${config.apiUrl}/create_infusion_lead/${this.state.quoteId}`, pkgData)
          .then((response) => {
            {this.getHasFacility() ? this.nextStep() : this.setStep('BookCall') }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getNonPayOccurrences(lapses) {
    if (_.isArray(lapses)) {
      return lapses.filter((v) => (v.lapse_type === 'NonPay'));
    }
  }

  applyNonPayFilter(lapses) {
    let nonPay;
    if (!_.isUndefined(lapses.lapses)) {
      nonPay = this.getNonPayOccurrences(lapses.lapses);
    } else {
      nonPay = this.getNonPayOccurrences(lapses);
    }

    let cancelCount = 0;
    if (nonPay && nonPay.length >= 1) {
      // pull out the start date from object
      const lapseStart = nonPay.map(lapse => lapse.start_date);
      if (lapseStart.length) {
        const startDate = moment();
        startDate.subtract(3, 'years');
        lapseStart.forEach((d) => {
          const lapseDate = moment(d, "YYYY-MM-DD");
          if (moment(lapseDate).isSameOrAfter(startDate)) {
            cancelCount++;
          }
        })
      }
    }
    return cancelCount;
  }

  setStep(step) {
    this.setState({
      step: step,
    })
  }

  nextStep(driverAssignment) {
    const { step } = this.state

    // window.history.pushState({
    //   step: step + 1
    // }, null, null);


    if (driverAssignment) {
      this.setState({
        step: 'driverAssignment'
      })
    } else {
      this.setState({
        step : step + 1,
      })
    }
  }

  editVehicle(e, vehicleId) {
    e.preventDefault();
    axios.get(`${config.apiUrl}/vehicles/${vehicleId}`)
      .then(response => {
        const { vehicle } = response.data;
        console.log("veoce ", vehicle);
        this.goToVehicles(vehicle);
      }).catch(error => console.log(error));
  }

  deleteVehicle(e, vehicleId) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm delete</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" onClick={onClose}>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this vehicle?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-green"
                    onClick={() => {
                      this.handleClickDeleteVehicle(vehicleId);
                      onClose();
                    }}
                    >Yes</button>&nbsp;
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                    >No</button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }

  editDriver(e, driverId) {
    e.preventDefault();
    axios.get(`${config.apiUrl}/drivers/${driverId}`)
      .then(response => {
        const {
          driver
        } = response.data;
        this.goToDrivers(driver);
      }).catch(error => console.log(error));
  }

  handleClickDeleteVehicle(vehicleId) {
    axios.delete(`${config.apiUrl}/vehicles/${vehicleId}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            userSelectedVehicles: response.data.vehicles,
          });
        }
        if (response.status === 204) {
          this.setState({
            step: 1,
            editVehicle: false,
            userSelectedVehicles: [],
            ...initialVehicleState
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  go(step) {
    this.setState({
      step: step,
      quoteId: this.state.quoteId
    });
  }

  goToVehicles(vehicle) {

    // const vehicleDateParts = vehicle.purchase_date.split('-');
    // let formattedDate = `${vehicleDateParts[1]}/${vehicleDateParts[2]}/${vehicleDateParts[0]}`;

    // let vehicleUseString = vehicle.vehicle_use.replace(/\["/g, '').replace(/"]/g, '');
    const vehicleUseJsonArray = JSON.parse(vehicle.vehicle_use)

    let vehicleUsage = [{
          id: "Work/School",
          value: "Getting to work or School",
          isChecked: false
        },
        {
          id: "Pleasure",
          value: "Pleasure Use",
          isChecked: false
        },
        {
          id: "Business/Commercial",
          value: "Business or Commercial",
          isChecked: false
        },
      ];


      vehicleUseJsonArray.map((item, index) => {
        let vehicleIndex = vehicleUsage.findIndex((obj => obj.value === item));

        if (vehicleIndex > -1) {
          vehicleUsage[vehicleIndex].isChecked = true;
        }
      });

    let commercialBusinessUsage = [{
          id: "Visiting clients or suppliers",
          value: "Visiting clients or suppliers",
          isChecked: false
        },
        {
          id: "Carrying tools and equipment for work",
          value: "Carrying tools and equipment for work",
          isChecked: false
        },
        {
          id: "Other",
          value: "Other",
          isChecked: false
        },
      ];
    if (vehicle.commercial_business_use) {

      const commercialBusinessUseJsonArray = JSON.parse(vehicle.commercial_business_use)

      commercialBusinessUseJsonArray.map((item, index) => {
        const commercialBusinessUseIndex = commercialBusinessUsage.findIndex((obj => obj.value === item));
        if (commercialBusinessUseIndex > -1) {
          commercialBusinessUsage[commercialBusinessUseIndex].isChecked = true;
        }
      });

    }

    this.setState({
      step: 1,
      editVehicle: true,
      vehicleId: vehicle.id,
      quoteId: this.state.quoteId,
      selectedYear: {
        value: vehicle.year,
        label: vehicle.year,
      },
      selectedMake: {
        value: vehicle.make,
        label: vehicle.make,
      },
      selectedModel: {
        value: vehicle.model,
        label: vehicle.model,
      },
      purchaseCondition: vehicle.purchase_condition,
      purchaseDate: vehicle.purchase_date === null ? '' :  this._formatDate(vehicle.purchase_date),
      vehicleUsage: vehicleUsage,
      commercialBusinessUsage: commercialBusinessUsage,
      distanceDrivenOneWay: vehicle.distance_oneway,
      distanceDrivenAnnually: vehicle.distance_annually,
      ownershipStatus: vehicle.ownership_status,
      winterTires: vehicle.winter_tires === true ? "Yes" : "No",
      commercialBusinessPercent: vehicle.commercial_business_percent,
      commercialBusinessOther: vehicle.commercial_business_use_other,
      locationIndex: vehicle.location_index,
      parking: vehicle.parking_address,
      city: vehicle.location_city,
      postalCode: vehicle.postal_code,
      viccCode: vehicle.vicc_code,
      extViccCode: vehicle.ext_vicc,
      price: vehicle.price,
    })
  }

  _formatDate(dateStr) {
    const dateParts = dateStr.split('-');
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }

  goToDrivers(driver) {
    this.setState({
      step: 2,
      editDriver: true,
      driverId: driver.id,
      quoteId: this.state.quoteId,
      driverFirstname: driver.firstname,
      driverLastname: driver.lastname,
      gender: driver.gender,
      birthdate: driver.dob === null ? '' : this._formatDate(driver.dob),
      maritalStatus: driver.marital_status,
      employmentStatus: driver.employment_status,
      licenseClass: driver.license_class,
      licenseClassDate: driver.license_class_date === null ? '' : this._formatDate(driver.license_class_date),
      currentlyInsured: driver.currently_insured === true ? "Yes" : "No",
      yearsContinuouslyInsured: driver.years_continuously_insured,
      yearsCurrentCompany: driver.years_current_company,
      dateLastInsured: driver.date_last_insured === null ? '' : this._formatDate(driver.date_last_insured),
      hasHadInsurance: driver.has_had_insurance === true ? "Yes" : "No",
      previouslyInsuredDuration: driver.previously_insured_duration,
      completedDriversTraining: driver.completed_drivers_training === true ? "Yes" : "No",
      driversTrainingDate: driver.drivers_training_date === null ? '' : this._formatDate(driver.drivers_training_date),
      hasG2: driver.has_g2 === true ? "Yes" : "No",
      OutOfCountryDriver: driver.out_of_country_driver === true ? "Yes" : "No",
      g2Date: driver.g2_date === null ? '' : this._formatDate(driver.g2_date),
      hasG1: driver.has_g1 === true ? "Yes" : "No",
      g1Date: driver.g1_date === null ? '' : this._formatDate(driver.g1_date),
      occupation: driver.occupation,
      goodGrades: driver.good_grades === true ? "Yes" : "No",
      otherClaim: driver.other_claim,
      otherCancel: driver.other_cancel
    })
  }

  goToStep(step) {
    this.setState({
      step: step,
      driverFirstname: '',
      driverLastname: '',
      birthdate: '',
      gender: '',
      quoteId: this.state.quoteId || '',
      maritalStatus: '',
      employmentStatus: '',
      goodGrades: '',
      occupation: '',
      licenseClass: '',
      licenseClassDate: '',
      currentlyInsured: '',
      policyExpireDate: '',
      yearsContinuouslyInsured: '',
      yearsCurrentCompany: '',
      hasG2: '',
      OutOfCountryDriver: '',
      g2Date: '',
      hasG1: '',
      g1Date: '',
      //effectiveDate: '',
      ...initialVehicleState,
      ...convictions
    })
  }

  addAnotherVehicle() {
    this.setState({
      ...initialVehicleState,
      ...convictions,
      vehicleUsage: [{
          id: "Work/School",
          value: "Getting to work or School",
          isChecked: false
        },
        {
          id: "Pleasure",
          value: "Pleasure Use",
          isChecked: false
        },
        {
          id: "Business/Commercial",
          value: "Business or Commercial",
          isChecked: false
        },
      ],
      commercialBusinessUsage: [{
          id: "Visiting clients or suppliers",
          value: "Visiting clients or suppliers",
          isChecked: false
        },
        {
          id: "Carrying tools and equipment for work",
          value: "Carrying tools and equipment for work",
          isChecked: false
        },
        {
          id: "Other",
          value: "Other",
          isChecked: false
        },
      ],
      step: this.state.step
    });
  }

  prevStep(usedBackLink) {
    const { step } = this.state

    this.setState({
      step: step - 1,
      usedBackLink: usedBackLink
    })
  }

  validateDateFormat = (dateInput) => {
    return moment(dateInput, 'MM/DD/YYYY', true).isValid();
  }

  validatePurchaseDate = () => {
    const {
      selectedYear,
      purchaseDate
    } = this.state;

    // format date
    const purchaseDateFormatted = moment(purchaseDate).format('MM/DD/YYYY');
    const purchaseYear = parseInt(purchaseDateFormatted.split('/')[2]) || 0;
    const oneYearFromNow = moment().add(1, 'years');
    if ( purchaseDate === null) {
      this.setState({
        purchaseDateError: 'Purchasing date is required.',
        formHasErrors: true,
      });
    } else if (purchaseYear > 0) {
      if (selectedYear.value - purchaseYear > 2) {
        this.setState({
          purchaseDateError: 'Purchase date cannot be older than 2 years from year of vehicle',
          formHasErrors: true,
        });
      } else if (moment(purchaseDateFormatted, 'MM-DD-YYYY').isAfter(moment(this.state.effectiveDate, 'MM-DD-YYYY'))) {
        this.setState({
          purchaseDateError: `Purchase date cannot be past the effective date. You selected ${moment(this.state.effectiveDate).format('LL')} as your effective date.`,
          formHasErrors: true,
        });
      } else if (purchaseYear > moment(oneYearFromNow, 'MM-DD-YYYY').year()) {
        this.setState({
          purchaseDateError: 'Purchasing vehicle that far in future?',
          formHasErrors: true,
        });
      } else {
        this.setState({
          purchaseDateError: null,
          formHasErrors: false,
        });
      }
    }
  }

  validDrivingAge(field) {
    const licenseDate = new Date(moment(this.state[field], 'MM-DD-YYYY').toDate());
    const birthDate = new Date(moment(this.state.birthdate, 'MM-DD-YYYY').toDate());

    let age = licenseDate.getFullYear() - birthDate.getFullYear();
    const m = licenseDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && licenseDate.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
  }

  fiveYearsOrLess() {
    const today = moment();
    const classDate = new Date(moment(this.state.licenseClassDate, 'MM-DD-YYYY').toDate());
    const diff = today.diff(classDate, 'years');

    return diff;
  }

  isAfterOrOnAprilFourth() {
    // On April 1, 1994, the government passed regulations that introduced a
    // graduated licensing system for new drivers in Ontario.
    const compareTo = new Date(moment("04-01-1994", 'MM-DD-YYYY').toDate());
    const classDate = new Date(moment(this.state.licenseClassDate, 'MM-DD-YYYY').toDate());
    const isAfterOrOn = moment(classDate).isSameOrAfter(compareTo);

    return isAfterOrOn;
  }

  dateIsBeforeBirthDate(licenseDate) {
    const birthDate = new Date(moment(this.state.birthdate, 'MM-DD-YYYY').toDate());
    const classDate = new Date(moment(licenseDate, 'MM-DD-YYYY').toDate());

    const dateIsBeforeBirthDate = moment(classDate).isSameOrBefore(birthDate);

    return dateIsBeforeBirthDate;
  }

  dateIsAfterLicenseDate(date) {
    return moment(date, 'MM-DD-YYYY').isAfter(moment(this.state.licenseClassDate, 'MM-DD-YYYY'))
  }

  dateIsAfterG2Date(date) {
    return moment(date, 'MM-DD-YYYY').isAfter(moment(this.state.g2Date, 'MM-DD-YYYY'))
  }

  setUpDriversVehicles() {
    axios.get(`${config.apiUrl}/quotes/${this.state.quoteId}`)
    //axios.get(`${config.apiUrl}/quotes/199`)
      .then(response => {
        let mappedVehicles = [];
        let mappedDrivers = [];

        const { drivers } = response.data;
        const { vehicles } = response.data.quote;

        if (drivers && vehicles) {
          _.map(vehicles, vehicle => {
            mappedVehicles.push({
              id: vehicle.id,
              name: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
              driverId: null,
            });
            this.setState({
              vehiclesAvailable: mappedVehicles,
              quoteId: response.data.quote.id
            })
          })

          _.map(drivers, driver => {
            mappedDrivers.push({
              id: driver.id,
              name: `${driver.firstname} ${driver.lastname}`,
            });

            this.setState({
              driversAvailable: mappedDrivers,
              quoteId: response.data.quote.id
            })
          })
        }
      }).catch(error => {
        console.log(error);
        airbrake.notify({
          error: error,
          params: {
            endpoint: `QuoterBuilder 2 Page - ${error} - API End point: ${config.apiUrl}/quotes/${this.state.quoteId}`,
          }
        });
      });
  }

  dateCannotBeInFuture(dateToValidate) {
    return moment(dateToValidate, 'MM-DD-YYYY').isAfter(moment());
  }

  validateBirthDate() {
    const dateFormatted = moment(this.state.birthdate).format('MM-DD-YYYY');
    const age = moment().diff(moment(dateFormatted, 'MM-DD-YYYY'), 'years');

    const isLegal = (age >= 16);
    const isTooOld = (age >= 90);

    if (!isTooOld) {
      this.setState({
        formHasErrors: false,
        birthDateTooOldError: null
      })
    } else {
      this.setState({
        birthDateTooOldError: 'Please contact us 1-855-777-4376',
        formHasErrors: true
      })
    }

    if (isLegal) {
      this.setState({
        formHasErrors: false,
        birthDateAgeError: null
      })
    } else {
      this.setState({
        birthDateAgeError: 'You must be at least 16 years old.',
        formHasErrors: true
      })
    }
  }

  get_number = () => {
    return (window.location.hash === '#auto_hr' || window.location.hash === '#auto_youngdriver') ? '1.(866).467.4376' : '1.(855).777.4376';
  }

  setHasFacility () {
    axios.get(`${config.apiUrl}/quotes/${this.state.quoteId}`).then(response => {
      this.setState({
        hasFacility: response.data.quote.has_facility
      })
    }).catch(error => {
      console.log(error);
      airbrake.notify({
        error: error,
        params: {
          endpoint: `QuoterBuilder 3 Page - ${error} - API End point: ${config.apiUrl}/quotes/${this.state.quoteId}`,
        }
      });
    });
  }

  getHasFacility () {
    return this.state.hasFacility;
  }

  getYear() {
    return new Date().getFullYear();
  }

  handleDateChange = (date, field) => {
    this.setState({
      [field]: date,
    }, () => {
      if (field === 'purchaseDate' && this.state.purchaseCondition === 'New') {
        this.validatePurchaseDate();
      }
      if (field === 'birthdate') {
        this.validateBirthDate();
      }
      if (field === 'licenseClassDate') {
        // license class date cannot be blank or before birthdate, for obvious reasons
        if (this.state.licenseClassDate === null){
          this.setState({
            licenseClassDateError1: "Date is required",
            formHasErrors: true
          });
        } else if (this.dateIsBeforeBirthDate(this.state.licenseClassDate) === true) {
          this.setState({
            licenseClassDateError1:"Date cannot be on or before birthdate.",
            formHasErrors: true
          });
        } else {
          this.setState({
            licenseClassDateError1: null,
            formHasErrors: false,
          });
        }

        // this.setState({
        //   licenseClassDateError1: this.dateIsBeforeBirthDate(this.state.licenseClassDate) === true ? "Date cannot be on or before birthdate." :  null,
        //   formHasErrors: this.dateIsBeforeBirthDate(this.state.licenseClassDate) === true ? true : false
        // })

        // Must be at least 16 years for a license.
        if (!isNaN(this.validDrivingAge('licenseClassDate')) && this.validDrivingAge('licenseClassDate') > 0) {
          if (this.validDrivingAge('licenseClassDate') >= 16) {
            this.setState({
              licenseClassDateError2: null,
              formHasErrors: false,
            })
          } else {
            this.setState({
              licenseClassDateError2: 'You must be at least 16 years old.',
              formHasErrors: true
            });
          }
        }

        // If G date is 5 years or less from today's date, ask
        // "Have you completed a driver's training course"
        if (!isNaN(this.fiveYearsOrLess()) && this.fiveYearsOrLess() > 0) {
          this.setState({
            gDateFiveOrLess: (this.fiveYearsOrLess() === 5 || this.fiveYearsOrLess() < 5) ?
              true :
              false
          });
        } else{
          this.setState({
            gDateFiveOrLess: false
          });
        }

        // On April 1, 1994, the government passed regulations that introduced a
        // graduated licensing system for new drivers in Ontario.
        this.setState({
          isAfterOrOn: this.isAfterOrOnAprilFourth() === true ? true : false
        })
      }

      if (field === 'g2Date') {

        // G2 date cannot be blank or before brithdate
        if (this.state.g2Date === null){
          this.setState({
            g2DateError2: "Date is required",
            formHasErrors: true
          });
        } else if (this.dateIsBeforeBirthDate(this.state.g2Date) === true) {
          this.setState({
            g2DateError2:"Date cannot be on or before birthdate.",
            formHasErrors: true
          });
        } else {
          this.setState({
            g2DateError2: null,
            formHasErrors: false,
          });
        }

        // // G2 date cannot be before birthdate
        // this.setState({
        //   g2DateError2: this.dateIsBeforeBirthDate(this.state.g2Date) === true ? 'Date cannot be before birthdate.' : null,
        //   formHasErrors: this.dateIsBeforeBirthDate(this.state.g2Date) === true ? true : false
        // })

        // G2 date cannot be after G date
        this.setState({
          g2DateError3: this.dateIsAfterLicenseDate(this.state.g2Date) === true ? 'G2 Date cannot be after G Date.' : null,
          formHasErrors: this.dateIsAfterLicenseDate(this.state.g2Date) === true ? true : false
        })

        // Must be at least 16 years for a license.
        if (!isNaN(this.validDrivingAge('g2Date')) && this.validDrivingAge('g2Date') > 0) {
          if (this.validDrivingAge('g2Date') < 16) {
            this.setState({
              g2DateError4: 'You must be at least 16 years old.',
              formHasErrors: false
            })
          } else {
            this.setState({
              g2DateError4: null,
              formHasErrors: false
            });
          }
        }
      }


      if (field === 'g1Date') {

        // G1 date cannot be blank or before brithdate
        if (this.state.g1Date === null){
          this.setState({
            g1DateError2: "Date is required",
            formHasErrors: true
          });
        } else if (this.dateIsBeforeBirthDate(this.state.g1Date) === true) {
          this.setState({
            g1DateError2:"Date cannot be on or before birthdate.",
            formHasErrors: true
          });
        } else {
          this.setState({
            g1DateError2: null,
            formHasErrors: false,
          });
        }

        // this.setState({
        //   g1DateError2: this.dateIsBeforeBirthDate(this.state.g1Date) === true ? 'Date cannot be before birthdate.' : null,
        //   formHasErrors: this.dateIsBeforeBirthDate(this.state.g1Date) === true ? true : false
        // })

        // G1 date cannot be after g2 date
        this.setState({
          g1DateError3: this.dateIsAfterG2Date(this.state.g1Date) === true ? 'G1 Date cannot be after G2 Date.': null,
          formHasErrors: this.dateIsAfterG2Date(this.state.g1Date) === true ? true : false
        })

        // Must be at least 16 years old
        if (!isNaN(this.validDrivingAge('g1Date')) && this.validDrivingAge('g1Date') > 0) {
          if (this.validDrivingAge('g1Date') < 16) {
            this.setState({
              g1DateError4: 'You must be at least 16 years old.',
              formHasErrors: false
            })
          } else {
            this.setState({
              g1DateError4: null,
              formHasErrors: false
            });
          }
        }
      }
    });
  }

  // eslint-disable-next-line
  handlePhoneChange(value) {
    this.setState({
      phoneNumber: value
    });
  }

  // eslint-disable-next-line
  handleChange(e) {
    const {
      name,
      value,
      id,
      dataset
    } = e.target;
    if (name === 'postalCode') {
      let postalCode = value.toUpperCase();
      postalCode = postalCode.toString().trim();

      var ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/ig);

      if (ca.test(postalCode.toUpperCase())
          && (postalCode.startsWith('p'.toUpperCase())
          || postalCode.startsWith('m'.toUpperCase())
          || postalCode.startsWith('l'.toUpperCase())
          || postalCode.startsWith('k'.toUpperCase())
          || postalCode.startsWith('n'.toUpperCase()))) {
            fetch(`${config.apiUrl}/location/${postalCode.slice(0, 3)}`)
              .then(response => response.json())
              .then(index => {
                if (_.isEmpty(index)) {
                    this.setState({
                      postalCodeError: 'Please type in a valid postal code. Note: For Ontario residents only currently',
                      formHasErrors: true
                    })
                }
                else {
                  this.setState({
                    postalCode: postalCode,
                    postalCodeError: null,
                    formHasErrors: false
                  })
                }
              });
   
      } else {
        this.setState({
          postalCodeError: 'Please type in a valid postal code. Note: For Ontario residents only currently',
          formHasErrors: true
        })
      }
    }

    if (name === 'hasG1' && value === 'No'){
      this.setState({
        g1Date: null,
        g1DateError2: null,
        g1DateError3: null,
        g1DateError4: null,
        formHasErrors: false
      })
    }

    if (name === 'hasG2' && value === 'No'){
      this.setState({
        g2Date: null,
        g2DateError2: null,
        g2DateError3: null,
        g2DateError4: null,
        formHasErrors: false
      })
    }

    if (name === 'driverFirstname') {
      this.setState({
        driverFirstnameError: _.isEmpty(this.state.driverFirstname) ? 'First name is required.' : null,
        driverFirstnameHasError: _.isEmpty(this.state.driverFirstname) ? true : false
      })
    }

    if (name === 'driverLastname') {
      this.setState({
        driverLastnameError: _.isEmpty(this.state.driverLastname) ? 'Last name is required.' : null,
        driverLastnameHasError: _.isEmpty(this.state.driverLastname) ? true : false
      })
    }

    if (name === 'userFirstName') {
      this.setState({
        useFirstnameError: _.isEmpty(this.state.userFirstName) ? 'First name is required.' : null,
        userFirstNameHasError: _.isEmpty(this.state.userFirstName) ? true : false
      })
    }

    if (name === 'userLastName') {
      this.setState({
        userLastnameError: _.isEmpty(this.state.userLastName) ? 'Last name is required.' : null,
        userLastNameHasError: _.isEmpty(this.state.userLastName) ? true : false
      })
    }

    if ( (name === 'currentlyInsured' && value === 'No') || (name === 'hasHadInsurance' && value === 'No'))  {
      this.setState({
        yearsContinuouslyInsured: '',
        yearsCurrentCompany: '',
        hasCancellations: '',
        dateLastInsured: '',
        [name]: value,
        questionNumber: id || this.state.questionNumber
      })
    } else
    if (name === 'primaryDriver') {
      this.setState(prevState => ({
        vehiclesAvailable: prevState.vehiclesAvailable.map(
          obj => (obj.id === parseInt(dataset.vehicle)
            ? Object.assign(obj, { driverId: parseInt(value) })
            : obj
          )
        )
      }));
    } else {
      this.setState({
        [name]: value,
        questionNumber: id || this.state.questionNumber
      }, () => {
        /**
         * If more than 1 validation is required, don't put the validation
         * in a function due to async issue. If there is only one validation
         * required such as the validatePurchaseDate(), extracting to
         * a function is fine.
         */

        /**
         * Single validations, functions allowed here
         */

        if (name === 'purchaseDate' && this.state.purchaseCondition === 'New') {
          this.validatePurchaseDate();
        }

        if (name === 'phoneNumber') {
          /* eslint-disable-next-line */
          const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
          this.setState({
            phoneNumberError: !phoneRegex.test(this.state.phoneNumber) === true ? "Phone number is invalid." : null,
            phoneHasErrors: !phoneRegex.test(this.state.phoneNumber) === true ? true : false
          })
        }

        if (name === 'emailAddress') {
          var emailExp = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/i);
          this.setState({
            // emailAddressError: (_.isEmpty(this.state.emailAddress) ? 'Email address is required' : (!emailExp.test(this.state.emailAddress) ? 'Email address is invalid.' : null)),
            // emailHasErrors: (_.isEmpty(this.state.emailAddress) ? true : (!emailExp.test(this.state.emailAddress)) ? true : false)

            emailAddressError: (!emailExp.test(this.state.emailAddress) ? 'Email address is invalid.' : null),
            emailHasErrors: (!emailExp.test(this.state.emailAddress)) ? true : false
          })
        }

        if (name === 'hasCancellations' && value === 'No'){
          this.setState({
            cancellationCount: 0,
            insuranceGaps: []
          })
        }
      });
    }
  }

  handleMultiAssignment = (idx) => (evt) => {
    const newAssignment = this.state.driversAvailable.map((driver, sidx) => {
      if (idx !== sidx) return driver;
      return {
        ...driver,
      };
    });
    this.setState({
      driversAvailable: newAssignment
    });
  }

  handleSpeedChange = (idx, error) => (evt) => {
    const newSpeed = this.state.speedingAmount.map((speed, sidx) => {
      if (idx !== sidx) return speed;
      return {
        ...speed,
        kmsOverLimit: evt.target.value,
      };
    });

    // add error if inputs are blank
    newSpeed.forEach((speed, i) => {
      newSpeed[i].fieldHasErrors = _.isEmpty(newSpeed[i].kmsOverLimit);
      newSpeed[i].errorMessage = _.isEmpty(newSpeed[i].kmsOverLimit) ? "Missing information. Please verify that all fields are filled out." : null
    });

    this.setState({
      speedingAmount: newSpeed
    });
  }

  handleConviction = (idx, field, mappedObject) => (evt) => {

    const conviction = this.state[field].map((f, sidx) => {
      if (idx !== sidx) return f;
      return {
        ...f,
        [mappedObject]: {
          label: evt.label,
          value: evt.value
        }
      };
    });

    if (field === 'impaired') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].impairedType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].impairedType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'careless') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].carelessType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].carelessType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'insurance') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].insuranceType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].insuranceType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'suspended') {

      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].suspendedType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].suspendedType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'otherMinor') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].otherMinorType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].otherMinorType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'otherMajor') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].otherMajorType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].otherMajorType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'atFaultAccidents') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].incidentDate);
        conviction[i].errorMessage = _.isEmpty(conviction[i].incidentDate) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'nonAtFaultAccidents') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].incidentDate);
        conviction[i].errorMessage = _.isEmpty(conviction[i].incidentDate) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'windshieldRepairs') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].incidentDate);
        conviction[i].errorMessage = _.isEmpty(conviction[i].incidentDate) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'theftVandalisms') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].incidentDate);
        conviction[i].errorMessage = _.isEmpty(conviction[i].incidentDate) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }
    if (field === 'otherClaimAccidents') {
      conviction.forEach((conv, i) => {
        conviction[i].fieldHasErrors = _.isUndefined(conviction[i].otherClaimAccidentsType.value);
        conviction[i].errorMessage = _.isEmpty(conviction[i].otherClaimAccidentsType.value) ? "Missing information. Please verify that all fields are filled out." : null
      });
    }

    this.setState({
      [field]: conviction
    });
  }

  handleConvictionDate = (idx, field, dateField) => (evt) => {
    const newDate = this.state[field].map((date, sidx) => {
      if (idx !== sidx) {
        return date;
      }
      // claims do not need to be considered after 10 years
      if (dateField) {
        if (dateField === 'incidentDate') {
          const claimYears = moment().diff(moment(evt, 'MM-DD-YYYY'), 'years');
          const isWithinRange = (claimYears >= 10);
          return {
            ...date,
            [dateField]: evt,
            incidentDateError: isWithinRange ? 'Claims over 10 years don\'t matter' : null,
            fieldHasErrors: isWithinRange ? true : false
          };
        }
        // this may need to change to not look up field but for now it's lookking at gaps
        // and the start date because it cannot be in the past
        if (field === 'insuranceGaps' || field === 'suspensions')  {
          if (dateField === 'startDate') {
            return {
              ...date,
              [dateField]: evt
            };
          } else {
            // end dates should be after the start date
            const compareTo = moment(date.startDate, 'MM-DD-YYYY');
            const endDate = moment(evt, 'MM-DD-YYYY');
            const endDateIsAfterStart = (moment(endDate, 'MM-DD-YYYY').isAfter(compareTo) === true);

            return {
              ...date,
              [dateField]: evt,
              endDateError1: !endDateIsAfterStart ? 'Must be after your incident date.' : null,
              fieldHasErrors: !endDateIsAfterStart ? true : false
            };
          }
        } else {

          return {
            ...date,
            [dateField]: evt.target.value,
          };
        }
      } else {
        return {
          ...date,
          convictionDate: evt.target.value
        };
      }
    });

    this.setState({
      [field]: newDate
    });
  }

// eslint-disable-next-line
  handleCustomSelect(field, value, id) {
    // if id is undefined, use state otherwise use the values passed in.
    this.setState({
      [field] : value,
      questionNumber: _.isUndefined(id) ? this.state.questionNumber : id,
    });
    if (field === 'selectedYear') {
      // Reset selectedMake and selectedModel when year changes
      this.setState({
        selectedYear: value,
        selectedMake: null, // Reset the make
        selectedModel: null // Reset the model
      });
    } else if (field === 'selectedMake') {
      this.setState({
      selectedMake: value,
      selectedModel: null // Reset the model
    });
    }
  }

  handleCheckChildElement(event) {
    const {
      vehicleUsage,
      commercialBusinessUsage
    } = this.state;

    const {
      value,
      checked,
      id
    } = event.target;

    vehicleUsage.forEach(usage => {
      if (usage.value === value) {
        usage.isChecked = checked
      }
    });

    commercialBusinessUsage.forEach(usage => {
      if (usage.value === value) {
        usage.isChecked = checked;
      }
    });
    this.setState({
      vehicleUsage: vehicleUsage,
      commercialBusinessUsage: commercialBusinessUsage,
      questionNumber: id,
    })
  }

  toggleChange(e) {
    const {
      name,
      id
    } = e.target;

    this.setState({
      [name]: !this.state[name],
      questionNumber: id === "" ? this.state.questionNumber : id
    });
  }

  save(data, endpoint, extras) {
    if (endpoint === 'effectiveDate' && data === null) {
      this.setState({
        effectiveDate: this.state.effectiveDate,
        intent: this.state.intent
      });
    } else if (endpoint === 'lapses') {
      axios.post(`${config.apiUrl}/${endpoint}`, data)
        .then((response) => {
          // set some rel id's up for saving
          if (!_.isUndefined(response.data.vehicle)) {
            this.setState({
              vehicleId: [...this.state.vehicleId, response.data.vehicle.id],
              quoteId: response.data.vehicle.quote_id,
            });
          }
          if (!_.isUndefined(response.data.driver)) {
            this.setState({
              driverId: response.data.driver.id
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (endpoint === 'drivers') {
      // intercept the lapses endpoint to apply filters
      // if 1 cancellation for non-payment in the last 3 years
      // only use carriers: JEV, ECON, ECH, PAF, COACH, FAC
      // if more than 1 cancellation for non-payment only use FAC
      if (!_.isEmpty(data.lapses)) {
        const hasCancellations = this.applyNonPayFilter(data.lapses);
        if (hasCancellations) {
          data.cancel_count = hasCancellations;
        }
      }

      const today = new Date(moment());
      const birthDate = new Date(moment(this.state.birthdate, 'MM-DD-YYYY').toDate());
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      if (age < 25 && this.state.hasHadInsurance === "No") {
        data.is_young_driver = true;
      }
      if (!_.isEmpty(data.lapses)) {
        const cancelData = {
          cancel_count: this.applyNonPayFilter(data.lapses)
        }
        axios.put(`${config.apiUrl}/update_cancel_count/${this.state.quoteId}`, cancelData)
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
      axios.post(`${config.apiUrl}/${endpoint}`, data)
        .then((response) => {
          // set some rel id's up for saving
          if (!_.isUndefined(response.data.vehicle)) {
            this.setState({
              vehicleId: [...this.state.vehicleId, response.data.vehicle.id],
              quoteId: response.data.vehicle.quote_id,
            });
          }
          if (!_.isUndefined(response.data.driver)) {
            this.setState({
              driverId: response.data.driver.id
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {

      axios.post(`${config.apiUrl}/${endpoint}`, data)
        .then((response) => {
          // set some rel id's up for saving
          if (!_.isUndefined(response.data.vehicle)) {
            let vehicleId = [];
            if (!_.isArray(this.state.vehicleId)) {
              vehicleId.push(this.state.vehicleId);
            } else {
              vehicleId = this.state.vehicleId;
            }
            this.setState({
              vehicleId: [...vehicleId, response.data.vehicle.id],
              quoteId: response.data.vehicle.quote_id,
            });
          }
          if (!_.isUndefined(response.data.driver)) {
            this.setState({
              driverId: response.data.driver.id
            });
          }
          if (!_.isUndefined(response.data.conviction)) {
            this.setState({
              convictionId: response.data.conviction.id
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
  }

  incrementCount(field, stateValue, fieldsToAdd) {
    this.setState({
      [field]: this.state[field] + 1,
      [stateValue]: this.state[stateValue].concat(fieldsToAdd)
    });
  }

  decrementCount(field, stateValue) {
    if (this.state[field] === 0) {
      this.setState({
        [field]: 0,
      })
    } else {
      // spread the state value field to a new array so we can pop it before setting it.
      const stateArr = [...this.state[stateValue]];
      // for each time they press the minus we pop from end of array.
      stateArr.pop();

      this.setState({
        [field]: this.state[field] - 1,
        [stateValue]: stateArr
      });
    }
  }

  render() {
    const { step } = this.state;

    const percentage = Math.round((parseInt(this.state.questionNumber) / 26) * 100);
    let linePercentage;
    if (this.state.step === 1) {
      linePercentage = Math.round((parseInt(0) / 3) * 100);
    } else {
      let stepState = step - 1;
      linePercentage = Math.round((parseInt(stepState) / 3) * 100);
    }

    const {
      quoteId,
      vehicleId,
      driverId,
      purchaseDate,
      primaryUse,
      purchaseCondition,
      ownershipStatus,
      distanceDrivenOneWay,
      userEnteredDistanceOneWay,
      distanceDrivenAnnually,
      userEnteredDistanceAnnually,
      parking,
      isParkingSame,
      selectedAddress,
      winterTires,
      driverFirstname,
      driverLastname,
      maritalStatus,
      employmentStatus,
      occupation,
      goodGrades,
      gender,
      licenseClass,
      licenseClassDate,
      isAfterOrOn,
      birthdate,
      otherClaim,
      otherCancel,
      currentlyInsured,
      previouslyInsuredDuration,
      hasHadInsurance,
      effectiveDate,
      intent,
      yearsContinuouslyInsured,
      policyExpireDate,
      dateLastInsured,
      selectedYear,
      selectedMake,
      selectedModel,
      questionNumber,
      vehicleUsage,
      commercialBusinessUsage,
      yearsCurrentCompany,
      userFirstName,
      userLastName,
      emailAddress,
      phoneNumber,
      vehicleAssignment,
      additionalInformation,
      PreferredTime,
      yearsSuspended,
      gDateFiveOrLess,
      completedDriversTraining,
      driversTrainingDate,
      hasG2,
      OutOfCountryDriver,
      g2Date,
      userSelectedVehicles,
      // convictions
      speedingAmount,
      kmsOverLimit,
      impaired,
      careless,
      insurance,
      suspended,
      otherMinor,
      otherMajor,
      insuranceGaps,
      suspensions,
      atFaultAccidents,
      nonAtFaultAccidents,
      windshieldRepairs,
      fires,
      theftVandalisms,
      otherClaimAccidents,
      // conviction counts
      speedingTicketsCount,
      convictionDate,
      startDate,
      endDate,
      convictionType,
      impairedCount,
      carelessCount,
      noInsuranceCount,
      noLicenseCount,
      otherMinorTicketsCount,
      otherMajorTicketsCount,
      cancellationCount,
      licenseSuspensionCount,
      atFaultAccidentCount,
      nonAtFaultAccidentCount,
      windshieldRepairCount,
      fireCount,
      theftVandalismCount,
      otherClaimCount,
      vehicleAssigned,
      primaryDriver,
      quoteReferenceNumber,
      drivers,
      selectedLiabilityLimit,
      selectedComprehensiveDeductible,
      selectedCollisionDeductible,
      accidentForgiveness,
      rentalVehicleReplacement,
      rentalVehicleInsurance,
      purchasePriceProtection,
      customQuoteIsValid,
      commercialBusinessPercent,
      commercialBusinessOther,
      hasTickets,
      hasSuspensions,
      fb,
      hasClaims,
      convictionId,
      hasCancellations,
      applyDiscount,
      ...validations
    } = this.state;

    const values = {
      quoteId,
      vehicleId,
      driverId,
      purchaseDate,
      primaryUse,
      purchaseCondition,
      ownershipStatus,
      distanceDrivenOneWay,
      userEnteredDistanceOneWay,
      distanceDrivenAnnually,
      userEnteredDistanceAnnually,
      parking,
      isParkingSame,
      selectedAddress,
      winterTires,
      driverFirstname,
      driverLastname,
      maritalStatus,
      employmentStatus,
      occupation,
      goodGrades,
      policyExpireDate,
      gender,
      licenseClass,
      licenseClassDate,
      isAfterOrOn,
      birthdate,
      otherClaim,
      otherCancel,
      currentlyInsured,
      previouslyInsuredDuration,
      hasHadInsurance,
      effectiveDate,
      intent,
      fb,
      yearsContinuouslyInsured,
      dateLastInsured,
      selectedYear,
      selectedMake,
      selectedModel,
      questionNumber,
      vehicleUsage,
      commercialBusinessUsage,
      commercialBusinessOther,
      yearsCurrentCompany,
      userFirstName,
      userLastName,
      emailAddress,
      phoneNumber,
      vehicleAssignment,
      additionalInformation,
      PreferredTime,
      yearsSuspended,
      gDateFiveOrLess,
      completedDriversTraining,
      driversTrainingDate,
      hasG2,
      OutOfCountryDriver,
      g2Date,
      userSelectedVehicles,
      drivers,

      // convictions
      speedingAmount,
      kmsOverLimit,
      impaired,
      careless,
      insurance,
      suspended,
      otherMinor,
      otherMajor,
      insuranceGaps,
      suspensions,
      atFaultAccidents,
      nonAtFaultAccidents,
      windshieldRepairs,
      fires,
      theftVandalisms,
      otherClaimAccidents,
      // conviction counts
      speedingTicketsCount,
      convictionDate,
      startDate,
      endDate,
      convictionType,
      impairedCount,
      carelessCount,
      noInsuranceCount,
      noLicenseCount,
      otherMinorTicketsCount,
      otherMajorTicketsCount,
      cancellationCount,
      licenseSuspensionCount,
      atFaultAccidentCount,
      nonAtFaultAccidentCount,
      windshieldRepairCount,
      fireCount,
      theftVandalismCount,
      otherClaimCount,
      vehicleAssigned,
      primaryDriver,
      quoteReferenceNumber,
      selectedLiabilityLimit,
      selectedComprehensiveDeductible,
      selectedCollisionDeductible,
      accidentForgiveness,
      rentalVehicleReplacement,
      rentalVehicleInsurance,
      purchasePriceProtection,
      customQuoteIsValid,
      commercialBusinessPercent,
      hasTickets,
      hasSuspensions,
      hasClaims,
      convictionId,
      hasCancellations,
      applyDiscount,
      ...validations
    };

    let hrefLink = '#';

    return (
      <React.Fragment>
        <ProgressBar percentage={linePercentage} isMobile={true} />
        <nav className="navbar navbar-light fixed-top bg-light">
          <a className="navbar-brand" href={hrefLink}>
            <img src="images/mobile-logo.png" alt="Logo" />
          </a>
          <a href="tel:18557774376">
            <img src="images/phone.png" alt="Need help" className="phone" />
          </a>
        </nav>

      <div className="row">
        <div className="tm-left-column">
          <div className="tm-logo-div text-xs-center">
            <img src="images/logo.png" alt="Logo" />
          </div>
          <div className="text-xs-center mb-2 mt-3 progress-bar" style={{ width: '134px' }}>
            <CircularProgressbar
              percentage={percentage}
              text={`${percentage}%`}
              background
              backgroundPadding={5}
              styles={{
                background: {
                  fill: '#fff',
                },
                text: {
                  fill: '#2C2F30',
                  fontWeight: 'bold'
                },
                path: {
                  stroke: '#96C61A',
                },
                trail: {
                  stroke: '#E9E9E9',
                  strokeWidth: 5
                },
              }}
            />
          </div>

          <nav className="tm-main-nav">
            <ul className="tm-main-nav-ul">
              <li className="tm-nav-item">
                <img src="images/vehicles.png" alt="Vehicles" />
                <a href={hrefLink} onClick={
                  values.userSelectedVehicles.length > 0
                  ? () => this.setStep('vehicleList')
                  : (e) => e.preventDefault()
                } className={`tm-nav-item-link ${step === 1 ? ' active' : null}`}>Vehicles</a>
                {
                  values.userSelectedVehicles.length > 0 ?
                  <ul className="vehicle-list">
                    {
                      values.userSelectedVehicles.map((vehicle, idx) => (
                        <a href={hrefLink} onClick={(e) => this.editVehicle(e, vehicle.id)} key={idx}>
                          <li className="xs" key={vehicle.id}>{vehicle.year} {vehicle.make} {vehicle.model}</li>
                        </a>
                      ))
                    }
                  </ul>
                  : null
                }
              </li>
              <li className="tm-nav-item">
                <img src="images/driver.png" alt="DriversList" />
                <a href={hrefLink} onClick={
                  values.drivers.length > 0
                  ? () => this.setStep(4)
                  : (e) => e.preventDefault()
                } className={`tm-nav-item-link ${step === 2 || step === 3 || step === 4 || step === 5 ? ' active' : null}`}>Drivers</a>
                {
                  values.drivers && values.drivers.length > 0 ?
                  <ul className="vehicle-list">
                    {
                      values.drivers.map((driver, idx) => (
                        <a href={hrefLink} onClick={(e) => this.editDriver(e, driver.id)} key={idx}>
                          <li className="xs">{driver.firstname} {driver.lastname}</li>
                        </a>
                      ))
                    }
                  </ul>
                  : null
                }
              </li>
              <li className="tm-nav-item">
                <img src="images/calculator.png" alt="Quotes" />
                <a href={hrefLink} className={`tm-nav-item-link ${step === 6 ? ' active' : null}`}>Quotes</a>
                {
                  values.quoteReferenceNumber ?
                  <ul className="vehicle-list">
                    {
                      <li className="xs">Quote Reference # {values.quoteReferenceNumber}</li>
                    }
                  </ul>
                  : null
                }
              </li>
            </ul>
          </nav>
          <div className="text-xs-center restart-quote">
            <button type="submit" className="btn btn-restart" onClick={this.restartQuote}>Restart Quote</button>
          </div>
        </div>
        <div className="tm-right-column">
          <div className="text-xs-right pr-3 mt-1 call-us">
            <img src="images/phone.png" alt="Need help" />
            <small>Need Help? Call us</small> <br />
            {
              this.get_number()
            }
            <br/>
            {
              values.quoteReferenceNumber && <span className="tiny-text">Quote Reference # {values.quoteReferenceNumber}</span>
            }
          </div>
          <ul className="mobile-nav-ul">
            <li>
              <img src="images/vehicles-sm.png" alt="Vehicles" />
              <a href={hrefLink} onClick={
                values.userSelectedVehicles.length > 0
                ? () => this.setStep('vehicleList')
                : (e) => e.preventDefault()
              } className={`tm-nav-item-link ${step === 1 ? ' active' : null}`}>Vehicles</a>
            </li>
            <li>
              <img src="images/driver-sm.png" alt="DriversList" />
              <a href={hrefLink} onClick={
                values.drivers.length > 0
                ? () => this.setStep(4)
                : (e) => e.preventDefault()
              } className={`tm-nav-item-link ${step === 2 || step === 3 || step === 4 || step === 5 ? ' active' : null}`}>Drivers</a>
            </li>
            <li>
              <img src="images/calculator-sm.png" alt="Quotes" />
              <a href={hrefLink} className={`tm-nav-item-link ${step === 6 ? ' active' : null}`}>Quotes</a>
            </li>
          </ul>
          <div id="mobile-ref">
          {
            values.quoteReferenceNumber &&
            <center>
              <span className="tiny-text grey">Quote Reference # {values.quoteReferenceNumber}</span>
            </center>
          }
          </div>
          <div className="tm-content-div">
            {step === 0 && (
              <EffectiveDate
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                save={this.save}
                values={values}
              />
            )}
            {step === 1 && (
              <Vehicle
                nextStep={this.nextStep}
                go={this.go}
                handleChange={this.handleChange}
                handleCustomSelect={this.handleCustomSelect}
                handleCheckChildElement={this.handleCheckChildElement}
                toggleChange={this.toggleChange}
                handleDateChange={this.handleDateChange}
                addAnotherVehicle={this.addAnotherVehicle}
                save={this.save}
                values={values}
              />
            )}
            {step === 2 && (
              <Driver
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                setStep={this.setStep}
                handleChange={this.handleChange}
                handleConviction={this.handleConviction}
                handleDateChange={this.handleDateChange}
                handleConvictionDate={this.handleConvictionDate}
                incrementCount={this.incrementCount}
                decrementCount={this.decrementCount}
                save={this.save}
                values={values}
              />
            )}
            {step === 3 && (
              <Convictions
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                handleSpeedChange={this.handleSpeedChange}
                handleConviction={this.handleConviction}
                incrementCount={this.incrementCount}
                setStep={this.setStep}
                decrementCount={this.decrementCount}
                handleConvictionDate={this.handleConvictionDate}
                applyNonPayFilter={this.applyNonPayFilter}
                goToStep={this.goToStep}
                save={this.save}
                values={values}
              />
            )}
            {step === 4 && (
              <DriversList
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                handleSpeedChange={this.handleSpeedChange}
                goToStep={this.goToStep}
                editDriver={this.editDriver}
                save={this.save}
                values={values}
              />
            )}
            {step === 'driverAssignment' && (
              <Assignment
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                handleMultiAssignment={this.handleMultiAssignment}
                setUpDriversVehicles={this.setUpDriversVehicles.bind(this)}
                goToStep={this.goToStep}
                save={this.save}
                values={values}
              />
            )}
            {step === 5 && (
              <PersonalInformation
                handleChange={this.handleChange}
                handlePhoneChange={this.handlePhoneChange}
                handleCustomSelect={this.handleCustomSelect}
                toggleChange={this.toggleChange}
                save={this.save}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
              />
            )}
            {step === 6 && (
              <Quotes
                handleChange={this.handleChange}
                handleCustomSelect={this.handleCustomSelect}
                toggleChange={this.toggleChange}
                selectPackage={this.selectPackage}
                save={this.save}
                setStep={this.setStep}
                prevStep={this.prevStep}
                values={values}
              />
            )}
            {step === 7 && (
              <Success
                handleChange={this.handleChange}
                handlePhoneChange={this.handlePhoneChange}
                handleCustomSelect={this.handleCustomSelect}
                save={this.save}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                psid={psid}
              />
            )}
            {step === 8 && (
              <Complete
                values={values}
                getHasFacility={this.getHasFacility}
                setStep={this.setStep}
              />
            )}
            {step === 'vehicleList' && (
              <VehicleList
                values={values}
                editVehicle={this.editVehicle}
                deleteVehicle={this.deleteVehicle}
                goToStep={this.goToStep}
              />
            )}
            {step === 'BookCall' && (
              <BookCall
                values={values}
                setStep={this.setStep}
              />
            )}
          </div>
          <footer>
            <p>&copy; {this.getYear()} Insurance Hero. InsuranceHero.ca o/b 9373551 Canada Inc. – Registered Insurance Brokers of Ontario License # 3856. <a href="https://insurancehero.ca/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>|<a href="https://insurancehero.ca/our-commitment-to-transparency/" target="_blank" rel="noopener noreferrer">Code of Conduct & Disclosures</a></p>
          </footer>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default QuoteBuilder;
