import React from 'react';
import CallButton from './CallButton';

class BookCall extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { values } = this.props;
    let hrefLink = '#';

    return (
      <section className="tm-section contact-us-section">
        <h3 className="widget-list text-xs-center bold-font mt-3" id="ThankYou">
          Great Choice, { values.driverFirstname }!
        </h3>
        <h5 className="text-xs-center text-xs-center sub-title-text">Here's how you can get insured</h5>
        <div class='main-row'>
          <div class="col-md-6 text-xs-center">
           <h5 className='bold-font'>Call Us Now</h5>
            <div>1-855-777-4376</div>
            <div>Monday to Friday</div>
            <div class='content-text'>9am to 5pm</div>
            <CallButton />
          </div>
          <div class="col-md-6 text-xs-center">
           <h5 className='bold-font'>We'll Call You</h5>
           <div class='content-text'>Pick your preferred time to talk to us</div>
           <p><a 
           className="btn btn-green"
           href={hrefLink}
           onClick={() => this.props.setStep(7)}>Book a Call</a></p>
          </div>
        </div>
        <div class="contact-page-logo">
          <img src="images/logo.png" alt="Logo" />
        </div>
        <div class='main-row mt-3 text-xs-center'>
          <h5 class='bold-font'>Talking to one of our HEROES can save you money !</h5>
          <ul class="insurance-info">
            <li>We may be able to find additional discounts</li>
            <li>We'll make sure you're properly insured</li>
            <li>We're happy to answer your questions</li>
          </ul>
        </div>
      </section>
    )
  }
}

export default BookCall;
