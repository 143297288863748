import _ from 'lodash';
import React from 'react';

class VehicleList extends React.Component {

  constructor(props) {
    super(props);

    this.addAnotherVehicle = this.addAnotherVehicle.bind(this);
    this.continue = this.continue.bind(this);
  }

  addAnotherVehicle(e) {
    e.preventDefault();
    const { values } = this.props;
    this.props.goToStep(1, values.quoteId);
  }

  continue(e) {
    const { goToStep, values } = this.props;

    if (_.isEmpty(values.drivers)) {
      return goToStep(2);
    }
    return goToStep(3)
  }

  render() {
    const { values } = this.props;

    let hrefLink = '#';
    
    return (
      <section id="welcome" className="tm-section">
        <h5>Vehicles</h5>
        {
          _.map(values.userSelectedVehicles, vehicle => (
            <div className="card" key={vehicle.id}>
              <div className="card-body p-2">
                <ul>
                  <li>
                    <h4>
                      <a href={hrefLink} onClick={(e) => this.props.editVehicle(e, vehicle.id)}>{ vehicle.year } { vehicle.make } { vehicle.model }</a>
                    </h4>
                    {/* <small><a href={hrefLink} onClick={(e) => this.props.deleteVehicle(e, vehicle.id)}>delete</a></small> &nbsp;|&nbsp;  */}
                    {/* <small><a href={hrefLink} onClick={(e) => this.props.editVehicle(e, vehicle.id)}>edit</a></small> */}
                    <small className="pull-right">
                      <hr />
                      <a href={hrefLink} onClick={(e) => this.props.editVehicle(e, vehicle.id)}>
                        <img src="images/edit.png" alt="Edit" /> Edit {vehicle.make}
                      </a>
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          ))
        }
        <button onClick={this.addAnotherVehicle}
          className="btn btn-primary"
          type="submit">
          Add another vehicle
        </button>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-3">
              <button onClick={this.continue} className="btn btn-green" type="submit">
                {
                  _.isEmpty(values.drivers) ? "Continue to driver" : "Continue"
                } 
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VehicleList;