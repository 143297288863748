import React from 'react'

const Checkbox = props => {
  
  return (
    <label className={'btn btn-radio checkboxes' + (props.isChecked ? ' active': '')}>
      <input 
        type="checkbox" 
        value={props.value} 
        autoComplete="off" 
        checked={props.isChecked}
        key={props.id}
        id={props.questionId}
        onChange={props.handleCheckChildElement} 
      /> {props.value}
    </label>
  )
}

export default Checkbox