import React from 'react';

class Complete extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { values } = this.props;

    return (
      <section className="tm-section">
        <h5 className="widget-list text-xs-center bold-font mt-3" id="ThankYou">
          Thank you. Your quote is complete and a HERO will be touch shortly! Your quote reference number is {values.quoteReferenceNumber}.
        </h5>
        <div className="text-xs-center pt-3">
          <img src="images/logo.png" alt="Logo" />
        </div>
      </section>
    )
  }
}

export default Complete;