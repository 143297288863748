import _ from 'lodash';
import React from 'react';
import config from '../config';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {isMobile} from 'react-device-detect';
import ProgressBar from './lib/progress-bar/ProgressBar';
import ReactTooltip from 'react-tooltip'
import Tooltip from './lib/Tooltip';
import airbrake from '../config/airbrake';

import {
  liabilityLimits,
  deductibles
} from "./lib/constants";

// turn this on if quotes don't add up, this will show the debugging quote data.
const DEBUG = false;
class Quotes extends React.Component {
  constructor(props) {
    super(props)
    this.liabilities = [];
    this.comprehensives = [];
    this.collisions = [];
    this.accidents = [];
    this.replacements = [];
    this.insurances = [];
    this.protections = [];

    this.state = {
      plus: [],
      standard: [],
      basic: [],
      custom: [],
      response: [],
      liability: [],
      vehicles: [],
      numberOfVehicles: 0,
      loading: true,
      loadingMessage: null,
      extraLoadingMessage: null,
      open: false,
      error500: false,
      errorMessage: '',
      progress: 0,
      hasLapses: false,
      done: false,
      count: 0,
    }
    this.saveCustomQuote = this.saveCustomQuote.bind(this);
  }

  applyCarrierFilters(carriers) {
    const {
      vehicles
    } = this.state;

    // if (vehicles.length) {
    //   _.map(vehicles, vehicle => {
    //     if (vehicle.location_city === 'BRAMPTON') {
    //       if (_.has(carriers, 'WEST')) {
    //         if (!_.isUndefined(carriers.WEST.CodeNames)) {
    //           const { VehLink } = carriers.WEST.CodeNames.Auto.QuoteInfo;
    //           if (_.isArray(VehLink)) {
    //             _.map(VehLink, link => {
    //               delete carriers.WEST
    //             })
    //           } else {
    //             if (!_.isUndefined(VehLink)) {
    //               delete carriers.WEST;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   });
    // }

    if (vehicles.length) {
      _.map(vehicles, vehicle => {
        if (vehicle.location_city === 'BRAMPTON'|| vehicle.location_city === 'Richmond Hill' || vehicle.location_city === 'Scarborough' || vehicle.location_city === 'Vaughan') {
          if (_.has(carriers, 'GORE')) {
            if (!_.isUndefined(carriers.GORE.CodeNames)) {
              const { VehLink } = carriers.GORE.CodeNames.Auto.QuoteInfo;
              if (_.isArray(VehLink)) {
                _.map(VehLink, link => {
                  delete carriers.GORE
                })
              } else {
                if (!_.isUndefined(VehLink)) {
                  delete carriers.GORE;
                }
              }
            }
          }
        }
      });
    }

    // if (_.has(carriers, 'COA')) {
    //   if (!_.isUndefined(carriers.COA.CodeNames)) {
    //     const {
    //       VehLink,
    //       Vehicle
    //     } = carriers.COA.CodeNames.Auto.QuoteInfo;

        // VehLink block
        // if (_.isArray(VehLink)) {
        //   _.map(VehLink, link => {
        //     if (!_.isUndefined(link.Message)) {
        //       if (_.isArray(link.Message)) {
        //         _.map(link.Message, message => {
        //           if (message.MessageText !== 'Preferred Customer') {
        //             delete carriers.COA;
        //           }
        //         })
        //       } else {
        //         if (link.Message.MessageText !== 'Preferred Customer') {
        //           delete carriers.COA;
        //         }
        //       }
        //     }
        //   })
        // } else {
        //   if (!_.isUndefined(VehLink) && !_.isUndefined(VehLink.Message)) {
        //     if (_.isArray(VehLink.Message)) {
        //       _.map(VehLink.Message, message => {
        //         if (message.MessageText !== 'Preferred Customer') {
        //           delete carriers.COA;
        //         }
        //       })
        //     } else {
        //       if (VehLink.Message.MessageText !== 'Preferred Customer') {
        //         delete carriers.COA
        //       }
        //     }
        //   }
        // }

    //     // Vehicle block
    //     if (_.isArray(Vehicle)) {
    //       _.map(Vehicle, veh => {
    //         if (!_.isUndefined(veh.Message)) {
    //           if (_.isArray(veh.Message)) {
    //             _.map(veh.Message, message => {
    //               if (message.MessageText !== 'Preferred Customer') {
    //                 delete carriers.COA;
    //               }
    //             })
    //           } else {
    //             if (veh.Message.MessageText !== 'Preferred Customer') {
    //               delete carriers.COA;
    //             }
    //           }
    //         }
    //       })
    //     } else {
    //       if (!_.isUndefined(Vehicle) && !_.isUndefined(Vehicle.Message)) {
    //         if (_.isArray(Vehicle.Message)) {
    //           _.map(Vehicle.Message, message => {
    //             if (message.MessageText !== 'Preferred Customer') {
    //               delete carriers.COA;
    //             }
    //           })
    //         } else {
    //           if (Vehicle.Message.MessageText !== 'Preferred Customer') {
    //             delete carriers.COA
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // if (_.has(carriers, 'GORE')) {
    //   if (!_.isUndefined(carriers.GORE.CodeNames)) {
    //     const { VehLink } = carriers.GORE.CodeNames.Auto.QuoteInfo;
    //     if (_.isArray(VehLink)) {
    //       _.map(VehLink, link => {
    //         if (!_.isUndefined(link.Message)) {
    //           if (_.isArray(link.Message)) {
    //             _.map(link.Message, message => {
    //               if (message.MessageText === 'Refer prior to binding due to territory.') {
    //                 delete carriers.GORE;
    //               }
    //             })
    //           } else {
    //             if (link.Message.MessageText === 'Refer prior to binding due to territory.') {
    //               delete carriers.GORE;
    //             }
    //           }
    //         }
    //       })
    //     } else {
    //       if (!_.isUndefined(VehLink.Message)) {

    //         if (_.isArray(VehLink.Message)) {
    //           _.map(VehLink.Message, message => {
    //             if (message.MessageText === 'Refer prior to binding due to territory.') {
    //               delete carriers.GORE
    //             }
    //           })
    //         } else {
    //           if (VehLink.Message.MessageText === 'Refer prior to binding due to territory.') {
    //             delete carriers.GORE
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    _.map(carriers, carrier => {
      if (!_.isUndefined(carrier.CodeNames) && carrier) {
        const { Message } = carrier.CodeNames.Auto.QuoteInfo;
        if (_.isArray(Message)) {
          _.map(Message, message => {
            const {
              CompanyCode
            } = carrier.CodeNames.Auto.QuoteInfo;
            if (message.MessageText) {
              // remove carrier if do not bind appears or paper app or reject
              if (message.MessageText.match(/\bdo not bind/gi)
                || message.MessageText.match(/\bpaper application/gi)
                || message.MessageText.match(/\brefer due to convictions/gi)
                || message.MessageText.match(/\bpolicy not acceptable/gi)
                // || message.MessageText.match(/\bunderwriting for review/gi)
                || message.MessageText.match(/\breject -/gi)
                || message.MessageText.match(/\brisk not written/gi)
                || message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
                || message.MessageText.match(/\bdoes not meet eligibility/gi)) {
                delete carriers[CompanyCode];
              }
            }
          })

        } else {
          const {
            CompanyCode
          } = carrier.CodeNames.Auto.QuoteInfo;
          if (!_.isUndefined(Message) && !_.isUndefined(Message.MessageText)) {
            // remove carrier if do not bind appears or paper app or reject
            if (Message.MessageText.match(/\bdo not bind/gi)
              || Message.MessageText.match(/\bpaper application/gi)
              || Message.MessageText.match(/\brefer due to convictions/gi)
              || Message.MessageText.match(/\bpolicy not acceptable/gi)
              // || Message.MessageText.match(/\bunderwriting for review/gi)
              || Message.MessageText.match(/\breject -/gi)
              || Message.MessageText.match(/\brisk not written/gi)
              || Message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
              || Message.MessageText.match(/\bdoes not meet eligibility/gi)) {
              delete carriers[CompanyCode];
            }
          }
        }

        const { VehLink } = carrier.CodeNames.Auto.QuoteInfo;

        if (_.isArray(VehLink)) {
          _.map(VehLink, link => {
            if (!_.isUndefined(link.Message)) {
              if (_.isArray(link.Message)) {
                _.map(link.Message, message => {

                  const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                  if (message.MessageText) {
                    // remove carrier if do not bind appears or reject
                    if (message.MessageText.match(/\bdo not bind/gi)
                      || message.MessageText.match(/\bpaper application/gi)
                      || message.MessageText.match(/\brefer due to convictions/gi)
                      // || message.MessageText.match(/\bunderwriting for review/gi)
                      || message.MessageText.match(/\breject -/gi)
                      || message.MessageText.match(/\brisk not written/gi)
                      || message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
                      || message.MessageText.match(/\bdoes not meet eligibility/gi)) {
                      delete carriers[CompanyCode];
                    }
                  }

                  if (message.MessageID === '1'
                      || message.MessageID === '999'
                      || message.MessageText === 'Risk exceeds binding authority'
                      || message.MessageText === 'Maximum vehicle age exceeded.'
                      || message.MessageText === 'Maximum vehicle value exceeded.'
                      || message.MessageText === 'Maximum vehicle weight exceeded.') {
                        // remove carrier object if filters match
                        delete carriers[CompanyCode];
                  }
                })
              } else {
                const {
                  MessageText,
                  MessageID
                } = link.Message;

                const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                if (MessageText) {
                  // remove carrier if do not bind appears or reject
                  if (MessageText.match(/\bdo not bind/gi)
                    || MessageText.match(/\bpaper application/gi)
                    || MessageText.match(/\brefer due to convictions/gi)
                    // || MessageText.match(/\bunderwriting for review/gi)
                    || MessageText.match(/\breject -/gi)
                    || MessageText.match(/\brisk not written/gi)
                    || MessageText.match(/\bvehicle\(s\) not eligible/gi)
                    || MessageText.match(/\bdoes not meet eligibility/gi)) {
                    // also remove any instance of "do not bind"
                    delete carriers[CompanyCode];
                  }
                }

                if (MessageID === '1'
                    || MessageID === '999'
                    || MessageText === 'Risk exceeds binding authority'
                    || MessageText === 'Maximum vehicle age exceeded.'
                    || MessageText === 'Maximum vehicle value exceeded.'
                    || MessageText === 'Maximum vehicle weight exceeded.') {
                      // remove carrier from object is filter matches
                      delete carriers[CompanyCode];
                }
              }
            }
          });

        } else {
          if (!_.isUndefined(VehLink) && !_.isUndefined(VehLink.Message)) {
            if (_.isArray(VehLink.Message)) {
              _.map(VehLink.Message, message => {
                const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                if (message.MessageText) {
                  // remove carrier if do not bind appears or reject
                  if (message.MessageText.match(/\bdo not bind/gi)
                    || message.MessageText.match(/\bpaper application/gi)
                    || message.MessageText.match(/\brefer due to convictions/gi)
                    // || message.MessageText.match(/\bunderwriting for review/gi)
                    || message.MessageText.match(/\breject -/gi)
                    || message.MessageText.match(/\brisk not written/gi)
                    || message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
                    || message.MessageText.match(/\bdoes not meet eligibility/gi)) {
                    delete carriers[CompanyCode];
                  }
                }

                if (message.MessageID === '1'
                    || message.MessageID === '999'
                    || message.MessageText === 'Maximum vehicle age exceeded.'
                    || message.MessageText === 'Maximum vehicle value exceeded.'
                    || message.MessageText === 'Risk exceeds binding authority'
                    || message.MessageText === 'Maximum vehicle weight exceeded.') {
                      // delete carrier from object if filter matches
                      delete carriers[CompanyCode];
                }
              })
            } else {
              const { MessageID, MessageText } = VehLink.Message;
              const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;

              if (MessageText) {
                // remove carrier if do not bind appears or reject
                if (MessageText.match(/\bdo not bind/gi)
                    || MessageText.match(/\brefer due to convictions/gi)
                    || MessageText.match(/\bpaper application/gi)
                    // || MessageText.match(/\bunderwriting for review/gi)
                    || MessageText.match(/\breject -/gi)
                    || MessageText.match(/\brisk not written/gi)
                    || MessageText.match(/\bvehicle\(s\) not eligible/gi)
                    || MessageText.match(/\bdoes not meet eligibility/gi)) {
                  // also remove any instance of "do not bind"
                  delete carriers[CompanyCode];
                }
              }

              if (MessageID === '1'
                  || MessageID === '999'
                  || MessageText === 'Maximum vehicle age exceeded.'
                  || MessageText === 'Risk exceeds binding authority'
                  || MessageText === 'Maximum vehicle value exceeded.'
                  || MessageText === 'Maximum vehicle weight exceeded.') {
                    // remove carrier from object if matches text
                    delete carriers[CompanyCode];
              }
            }
          }
        }

        const { Vehicle } = carrier.CodeNames.Auto.QuoteInfo;
        if (_.isArray(Vehicle)) {
          _.map(Vehicle, veh => {
            if (!_.isUndefined(veh.Message)) {
              if (_.isArray(veh.Message)) {
                _.map(veh.Message, message => {
                  const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                  if (message.MessageText) {
                    // remove carrier if do not bind appears or reject
                    if (message.MessageText.match(/\bdo not bind/gi)
                      || message.MessageText.match(/\bpaper application/gi)
                      || message.MessageText.match(/\brefer due to convictions/gi)
                      // || message.MessageText.match(/\bunderwriting for review/gi)
                      || message.MessageText.match(/\breject -/gi)
                      || message.MessageText.match(/\brisk not written/gi)
                      || message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
                      || message.MessageText.match(/\bdoes not meet eligibility/gi)) {
                      delete carriers[CompanyCode];
                    }
                  }
                })
              } else {
                const {
                  MessageText
                } = veh.Message;
                const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                if (MessageText) {
                  // remove carrier if do not bind appears or reject
                  if (MessageText.match(/\bdo not bind/gi)
                    || MessageText.match(/\bpaper application/gi)
                    || MessageText.match(/\brefer due to convictions/gi)
                    // || MessageText.match(/\bunderwriting for review/gi)
                    || MessageText.match(/\breject -/gi)
                    || MessageText.match(/\brisk not written/gi)
                    || MessageText.match(/\bvehicle\(s\) not eligible/gi)
                    || MessageText.match(/\bdoes not meet eligibility/gi)) {
                    delete carriers[CompanyCode];
                  }
                }
              }
            }
          });

        } else {
          if (!_.isUndefined(Vehicle) && !_.isUndefined(Vehicle.Message)) {
            if (_.isArray(Vehicle.Message)) {
              _.map(Vehicle.Message, message => {
                const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
                if (message.MessageText) {
                  // remove carrier if do not bind appears or reject
                  if (message.MessageText.match(/\bdo not bind/gi)
                    || message.MessageText.match(/\bpaper application/gi)
                    || message.MessageText.match(/\brefer due to convictions/gi)
                    // || message.MessageText.match(/\bunderwriting for review/gi)
                    || message.MessageText.match(/\breject -/gi)
                    || message.MessageText.match(/\brisk not written/gi)
                    || message.MessageText.match(/\bvehicle\(s\) not eligible/gi)
                    || message.MessageText.match(/\bdoes not meet eligibility/gi)) {
                    delete carriers[CompanyCode];
                  }
                }

              })
            } else {
              const {MessageText } = Vehicle.Message;
              const { CompanyCode } = carrier.CodeNames.Auto.QuoteInfo;
              if (MessageText) {
                // remove carrier if do not bind appears or reject
                if (MessageText.match(/\bdo not bind/gi)
                  || MessageText.match(/\brefer due to convictions/gi)
                  || MessageText.match(/\bpaper application/gi)
                  // || MessageText.match(/\bunderwriting for review/gi)
                  || MessageText.match(/\breject -/gi)
                  || MessageText.match(/\brisk not written/gi)
                  || MessageText.match(/\bvehicle\(s\) not eligible/gi)
                  || MessageText.match(/\bdoes not meet eligibility/gi)) {
                  delete carriers[CompanyCode];
                }
              }

            }
          }
        }
      }
    })

    return carriers;
  }

  // Removing those carrier who has no cmp and col val
  // we will use below carrier for standard and plus package only
  applyAdvanceCarrierFilters(carriersData){
    _.map(carriersData, carrierData => {
      if (!_.isUndefined(carrierData.CodeNames) && carrierData) {
        const { VehLink } = carrierData.CodeNames.Auto.QuoteInfo;
        const { CompanyCode } = carrierData.CodeNames.Auto.QuoteInfo;

        if (_.isArray(VehLink)) {
          _.map(VehLink, link => {
            if (!_.isUndefined(link.CoveragePremium)) {
              let isColPresent = false;
              let isCmpPresent = false;

              _.map(link.CoveragePremium, covPrem => {
                if (covPrem.CarrierCovCode === 'CMP') {
                  isCmpPresent = true;
                } else if(covPrem.CarrierCovCode === 'COL'){
                  isColPresent = true;
                }
              })

              if (!(isColPresent && isCmpPresent )){
                delete carriersData[CompanyCode];
              }
            }
          });
        } else {
          if (!_.isUndefined(VehLink) && !_.isUndefined(VehLink.CoveragePremium)) {
            let isColPresent = false;
            let isCmpPresent = false;

            _.map(VehLink.CoveragePremium, covPrem => {
                if (covPrem.CarrierCovCode === 'CMP') {
                  isCmpPresent = true;
                } else if(covPrem.CarrierCovCode === 'COL'){
                  isColPresent = true;
                }
              });

            if (!(isColPresent && isCmpPresent )){
              delete carriersData[CompanyCode];
            }
          }
        }
      }
    });

    return carriersData;
  }

  // Removing those carrier who has no cmp and col val
  // we will use below carrier for custom package only
  applyAdvanceCustomCarrierFilters(customCarriersData, customQuoteData){
    if (parseInt(customQuoteData.collision_deductible) > 0){
      _.map(customCarriersData, carrierData => {
        if (!_.isUndefined(carrierData.CodeNames) && carrierData) {
          const { VehLink } = carrierData.CodeNames.Auto.QuoteInfo;
          const { CompanyCode } = carrierData.CodeNames.Auto.QuoteInfo;

          if (_.isArray(VehLink)) {
            _.map(VehLink, link => {
              if (!_.isUndefined(link.CoveragePremium)) {
                let isColPresent = false;

                _.map(link.CoveragePremium, covPrem => {
                  if(covPrem.CarrierCovCode === 'COL'){
                    isColPresent = true;
                  }
                })

                if (isColPresent === false) {
                  delete customCarriersData[CompanyCode];
                }
              }
            });
          } else {
            if (!_.isUndefined(VehLink) && !_.isUndefined(VehLink.CoveragePremium)) {
              let isColPresent = false;

              _.map(VehLink.CoveragePremium, covPrem => {
                    if(covPrem.CarrierCovCode === 'COL'){
                    isColPresent = true;
                  }
                });

              if (isColPresent === false) {
                delete customCarriersData[CompanyCode];
              }
            }
          }
        }
      });
    }

    if (parseInt(customQuoteData.comprehensive_deductible) > 0){
      _.map(customCarriersData, carrierData => {
        if (!_.isUndefined(carrierData.CodeNames) && carrierData) {
          const { VehLink } = carrierData.CodeNames.Auto.QuoteInfo;
          const { CompanyCode } = carrierData.CodeNames.Auto.QuoteInfo;

          if (_.isArray(VehLink)) {
            _.map(VehLink, link => {
              if (!_.isUndefined(link.CoveragePremium)) {
                let isCmpPresent = false;

                _.map(link.CoveragePremium, covPrem => {
                  if(covPrem.CarrierCovCode === 'CMP'){
                    isCmpPresent = true;
                  }
                })

                if (isCmpPresent === false) {
                  delete customCarriersData[CompanyCode];
                }
              }
            });
          } else {
            if (!_.isUndefined(VehLink) && !_.isUndefined(VehLink.CoveragePremium)) {
              let isCmpPresent = false;

              _.map(VehLink.CoveragePremium, covPrem => {
                    if(covPrem.CarrierCovCode === 'CMP'){
                      isCmpPresent = true;
                  }
                });

              if (isCmpPresent === false) {
                delete customCarriersData[CompanyCode];
              }
            }
          }
        }
      });
    }

    return customCarriersData;
  }

  lowestBasicPremium(lowestBasic, index){
    let col = 0,cmp = 0;
    if (!_.isUndefined(lowestBasic.vehicle)){
      _.forEach(lowestBasic.vehicle[index]["CoveragePremium"], cov_prem => {
        if(cov_prem.CarrierCovCode === 'COL'){
            col = cov_prem.FinalPremium
        }
        else if(cov_prem.CarrierCovCode === 'CMP'){
          cmp = cov_prem.FinalPremium
        }
      });
      return (<NumberFormat value={Math.trunc((lowestBasic.vehicles[index].VehicleTotal.FinalPremium - col - cmp)/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />);
    }
    else{
      return (<NumberFormat value='0' displayType={'text'} thousandSeparator={true} prefix={'$'} />);
    }
  }
  lowestStandardPremium(lowestStandard, index){
    let col = 0;
    if (!_.isUndefined(lowestStandard.vehData)) {
      _.forEach(lowestStandard.vehData[index]["CoveragePremium"], cov_prem => {
        if (cov_prem.CarrierCovCode === 'COL'){
            col = cov_prem.FinalPremium
        }
      });
      return (<NumberFormat value={Math.trunc((lowestStandard.vehicle[index].VehicleTotal.FinalPremium - col)/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />);
    }
    else{
      return (<NumberFormat value='0' displayType={'text'} thousandSeparator={true} prefix={'$'} />);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.progress !== this.state.progress && !prevState.loading) {
      clearInterval(this.interval);
      this.setState({
        progress: 0,
        done: true,
        count: this.state.count + 1
      })
    }
  }

  componentDidMount() {
    const { values } = this.props;
    window.scrollTo(0, 0)

    this.interval = setInterval(() => {
      this.setState({
        progress: this.state.progress + 3
      });
    }, 1000);

    axios.get(`${config.apiUrl}/get_lapse/${values.quoteId}`).then(response => {
      this.setState({
        hasLapses: response.data['has_laspes']
      })
    });

    axios.get(`${config.apiUrl}/quotes/${values.quoteId}`,{timeout: 35000}).then(response => {
      this.setState({
        vehicles: response.data.quote.vehicles,
        numberOfVehicles: response.data.quote.vehicles.length,
        loading: true,
        loadingMessage: 'Putting on our cape!'
      });

      // Start quote calls!
      axios.get(`${config.apiUrl}/get_quote/plus/${values.quoteId}`,{timeout: 35000})
      .then(soap => {
        this.setState({
          loadingMessage: 'Fetching quotes from 15 insurance companies.'
        })
        const { StatusCode } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
        if (StatusCode === 'SUCCESS') {
          const { ReceiverResponse } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
          const { Carriers } = ReceiverResponse.FortusTransaction;

          let carriers;
          if (_.isArray(Carriers)) {
            carriers = Carriers[0];
          } else {
            carriers = Carriers;
          }

          const filteredRatingCarriers = this.applyCarrierFilters(carriers);

          axios.get(`${config.apiUrl}/get_ratebridge_quote/plus/${values.quoteId}`,{timeout: 35000})
            .then(soap => {
              const {
                FortusOutputXML
              } = soap.data.Envelope.Body.RateResponse.RateResult;

              let rateBridgeCarriers
              if (!_.isUndefined(FortusOutputXML)) {
                const { Carriers } = FortusOutputXML.FortusTransaction;
                rateBridgeCarriers = Carriers;
              }

              let carriers;
              if (_.isArray(rateBridgeCarriers))
                carriers = rateBridgeCarriers[0] || {};
              else
                carriers = rateBridgeCarriers || {};

              const filteredRateBridgeCarriers = this.applyCarrierFilters(carriers);

              const mergedPlus = _.extend({}, filteredRatingCarriers, filteredRateBridgeCarriers);
              const advanceCheckedCarriers = this.applyAdvanceCarrierFilters(_.cloneDeep(mergedPlus));
              // clone coverages to build one for basic. Basic is Basic+ - CMP
              const standardCoverages = _.cloneDeep(advanceCheckedCarriers);
              _.forEach(standardCoverages, (cov) => {
                _.forEach(_.get(cov, 'CodeNames.Auto', []), (attribute) => {
                  // when theres more than 1 vehicle, VehLink is an array
                  // and needs to be treated differently.
                  if (!_.isUndefined(attribute.VehLink) && _.isArray(attribute.VehLink)) {
                    _.forEach(attribute.VehLink, vehicle => {
                      _.forEach(vehicle.CoveragePremium, premCode => {
                        if (premCode.CarrierCovCode === 'COL') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                      })
                    })
                  } else {
                    // single vehicle
                    if (!_.isUndefined(attribute.VehLink)) {

                      _.forEach(attribute.VehLink.CoveragePremium, premCode => {
                        if (premCode.CarrierCovCode === 'COL') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                      })
                    }
                  }
                });
              })

              // clone coverages to build one for basic. Basic is Basic+ - CMP
              const basicCoverages = _.cloneDeep(mergedPlus);
              _.forEach(basicCoverages, (cov) => {
                _.forEach(_.get(cov, 'CodeNames.Auto', []), (attribute) => {
                  // when theres more than 1 vehicle, VehLink is an array
                  // and needs to be treated differently.
                  if (!_.isUndefined(attribute.VehLink) && _.isArray(attribute.VehLink)) {
                    _.forEach(attribute.VehLink, vehicle => {
                      _.forEach(vehicle.CoveragePremium, premCode => {
                        if (premCode.CarrierCovCode === 'CMP') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                        if (premCode.CarrierCovCode === 'COL') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                      })
                    })
                  } else {
                    // single vehicle
                    if (!_.isUndefined(attribute.VehLink)) {
                      _.forEach(attribute.VehLink.CoveragePremium, premCode => {
                        if (premCode.CarrierCovCode === 'CMP') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                        if (premCode.CarrierCovCode === 'COL') {
                          // get the final premium total from basic+
                          let finalPrem = _.get(attribute, 'PolicyTotal.FinalPremium');
                          // mutate the cloned object and subtract the CMP from final premium
                          // to create the Basic package final premium.
                          _.set(attribute, 'PolicyTotal.FinalPremium',
                            (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                          );
                        }
                      })
                    }
                  }
                });
              })
              this.setState({
                standard: standardCoverages,
                basic: basicCoverages,
                plus: advanceCheckedCarriers,
                loading: false,
              });
            })
            .catch(error => {
              const errorData = {
                has_error: true,
                error_message: error
              };
              axios.put(`${config.apiUrl}/record_error/${values.quoteId}`, errorData)
                .then((response) => {
                  this.setState({
                    error500: true,
                    loading: false,
                    errorMessage: error
                  })
                })
                .catch((error) => {
                  console.log(error);
                });
            });
        }
      }).catch(error => {
        const errorData = {
          has_error: true,
          error_message: error
        };
        axios.put(`${config.apiUrl}/record_error/${values.quoteId}`, errorData)
          .then((response) => {
            this.setState({
              error500: true,
              loading: false,
              errorMessage: error
            })
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }).catch(error => {
      airbrake.notify({
        error: error,
        params: {
          endpoint: `Quotes 1 Page - ${error} - API End point: ${config.apiUrl}/quotes/${values.quoteId}`,
        }
      });
      const errorData = {
        has_error: true,
        error_message: error
      };
      axios.put(`${config.apiUrl}/record_error/${values.quoteId}`, errorData)
        .then((response) => {
          this.setState({
            error500: true,
            loading: false,
            errorMessage: error
          })
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  saveCustomQuote(e) {
    e.preventDefault();

    const {
      values,
      save
    } = this.props;

    this.interval = setInterval(() => {
      this.setState({
        progress: this.state.progress + 6
      });
    }, 1000);

    const { vehicles } = this.state;

    if (this.state.numberOfVehicles > 1) {
      // eslint-disable-next-line
      vehicles.map((vehicle, i) => {
        const customQuoteData = {
          quote_id: values.quoteId,
          vehicle_id: vehicle.id,
          // liability_limit: this.liabilities[i].state.value.value,
          // comprehensive_deductible: this.comprehensives[i].state.value.value,
          // collision_deductible: this.collisions[i].state.value.value,
          // accident_forgiveness: this.accidents[i].checked || false,
          // rental_vehicle_replacement: this.replacements[i].checked || false,
          // rental_vehicle_insurance: this.insurances[i].checked || false,
          // purchase_price_protection: this.protections[i].checked || false
        };

        if (isMobile){
          customQuoteData.liability_limit = values.selectedLiabilityLimit.value
          customQuoteData.accident_forgiveness =  values.accidentForgiveness ? true : false
          customQuoteData.rental_vehicle_replacement = values.rentalVehicleReplacement ? true : false
          customQuoteData.rental_vehicle_insurance = values.rentalVehicleInsurance ? true : false
          customQuoteData.purchase_price_protection = values.purchasePriceProtection ? true : false

          if (_.isUndefined (values.selectedComprehensiveDeductible.value) || values.selectedComprehensiveDeductible.value === 'No Coverage')
            customQuoteData.comprehensive_deductible = 0;
          else
            customQuoteData.comprehensive_deductible = values.selectedComprehensiveDeductible.value;

          if (_.isUndefined(values.selectedCollisionDeductible.value) || values.selectedCollisionDeductible.value === 'No Coverage')
            customQuoteData.collision_deductible = 0;
          else
            customQuoteData.collision_deductible = values.selectedCollisionDeductible.value;

        } else{
          customQuoteData.liability_limit = this.liabilities[i].state.value.value
          customQuoteData.accident_forgiveness =  this.accidents[i].checked || false
          customQuoteData.rental_vehicle_replacement = this.replacements[i].checked || false
          customQuoteData.rental_vehicle_insurance = this.insurances[i].checked || false
          customQuoteData.purchase_price_protection = this.protections[i].checked || false

          if (_.isUndefined (values[`selectedComprehensiveDeductible[${i}]`]) || values[`selectedComprehensiveDeductible[${i}]`].value === 'No Coverage')
            customQuoteData.comprehensive_deductible = 0;
          else
            customQuoteData.comprehensive_deductible = values[`selectedComprehensiveDeductible[${i}]`].value;

          if (_.isUndefined (values[`selectedCollisionDeductible[${i}]`]) || values[`selectedCollisionDeductible[${i}]`].value === 'No Coverage')
            customQuoteData.collision_deductible = 0;
          else
            customQuoteData.collision_deductible = values[`selectedCollisionDeductible[${i}]`].value
        }

        // save quotes for quote engine
        save(customQuoteData, 'custom_quotes');
        axios.get(`${config.apiUrl}/quotes/${values.quoteId}`).then(response => {
          this.setState({
            vehicles: response.data.quote.vehicles,
            loading: true,
          });
          axios.get(`${config.apiUrl}/get_quote/custom/${values.quoteId}`)
            .then(soap => {
              this.setState({
                progress: 30,
                loadingMessage: "Calculating custom..."
              })
              const { StatusCode } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
              if (StatusCode === 'SUCCESS') {
                const { ReceiverResponse } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
                const { Carriers } = ReceiverResponse.FortusTransaction;

                let carriers;
                if (_.isArray(Carriers)) {
                  carriers = Carriers[0];
                } else {
                  carriers = Carriers;
                }
                const filteredCarriers = this.applyCarrierFilters(carriers);
                const advanceCheckedCustomCarriers = this.applyAdvanceCustomCarrierFilters(_.cloneDeep(filteredCarriers), customQuoteData);
                this.setState({
                  custom: advanceCheckedCustomCarriers,
                  loading: false,
                })
              }
            })
          }).catch(error => {
            airbrake.notify({
              error: error,
              params: {
                endpoint: `Quotes 2 Page - ${error} - API End point: ${config.apiUrl}/quotes/${values.quoteId}`,
              }
            });
            const errorData = {
              has_error: true,
              error_message: error
            };
            axios.put(`${config.apiUrl}/record_error/${values.quoteId}`, errorData)
              .then((response) => {
                this.setState({
                  error500: true,
                  loading: false,
                  errorMessage: error
                })
              })
              .catch((error) => {
                console.log(error);
              });
          });
      })
    } else {
      const vehicleId = this.state.vehicles.map((d) => {
        return d.id;
      });

      const customQuoteData = {
        // quote_id: values.quoteId,
        quote_id: values.quoteId,
        vehicle_id: vehicleId[0],
        liability_limit: values.selectedLiabilityLimit.value,
        accident_forgiveness: values.accidentForgiveness ? true : false,
        rental_vehicle_replacement: values.rentalVehicleReplacement ? true : false,
        rental_vehicle_insurance: values.rentalVehicleInsurance ? true : false,
        purchase_price_protection: values.purchasePriceProtection ? true : false,
      };

      if (_.isUndefined (values.selectedComprehensiveDeductible.value) || values.selectedComprehensiveDeductible.value === 'No Coverage')
        customQuoteData.comprehensive_deductible = 0;
      else
        customQuoteData.comprehensive_deductible = values.selectedComprehensiveDeductible.value;

      if (_.isUndefined(values.selectedCollisionDeductible.value) || values.selectedCollisionDeductible.value === 'No Coverage')
        customQuoteData.collision_deductible = 0;
      else
        customQuoteData.collision_deductible = values.selectedCollisionDeductible.value;

      save(customQuoteData, 'custom_quotes');

      axios.get(`${config.apiUrl}/quotes/${values.quoteId}`).then(response => {
        this.setState({
          vehicles: response.data.quote.vehicles,
          loading: true,
        });
        axios.get(`${config.apiUrl}/get_quote/custom/${values.quoteId}`)
          .then(soap => {
            const { StatusCode } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
            if (StatusCode === 'SUCCESS') {
              const { ReceiverResponse } = soap.data.Envelope.Body.QuoteResponse.QuoteResult.QuoteResponse;
              const { Carriers } = ReceiverResponse.FortusTransaction;

              let carriers;
              if (_.isArray(Carriers)) {
                carriers = Carriers[0];
              } else {
                carriers = Carriers;
              }
              const filteredRatingCarriers = this.applyCarrierFilters(carriers);

              axios.get(`${config.apiUrl}/get_ratebridge_quote/custom/${values.quoteId}`)
                .then(soap => {
                  const {
                    FortusOutputXML
                  } = soap.data.Envelope.Body.RateResponse.RateResult;

                  let rateBridgeCarriers;
                  if (!_.isUndefined(FortusOutputXML)) {
                    const { Carriers } = FortusOutputXML.FortusTransaction;
                    rateBridgeCarriers = Carriers;
                  }

                  let carriers;
                  if (_.isArray(rateBridgeCarriers))
                    carriers = rateBridgeCarriers[0] || {};
                  else
                    carriers = rateBridgeCarriers || {};

                  const filteredRateBridgeCarriers = this.applyCarrierFilters(carriers);
                  const mergedCustom = _.extend({}, filteredRatingCarriers, filteredRateBridgeCarriers);
                  const advanceCheckedCustomCarriers = this.applyAdvanceCustomCarrierFilters(_.cloneDeep(mergedCustom), customQuoteData);

                  this.setState({
                    loading: false,
                    custom: advanceCheckedCustomCarriers,
                  })
                })
            }
          })
        }).catch(error => {
          airbrake.notify({
            error: error,
            params: {
              endpoint: `Quotes 3 Page - ${error} - API End point: ${config.apiUrl}/quotes/${values.quoteId}`,
            }
          });
          const errorData = {
            has_error: true,
            error_message: error
          };
          axios.put(`${config.apiUrl}/record_error/${values.quoteId}`, errorData)
            .then((response) => {
              this.setState({
                error500: true,
                loading: false,
                errorMessage: error
              })
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  }
  multiMobileVehicle(vehicle, index, lowestBasic, lowestStandard, lowestPlus, lowestCustom, packageData) {
    const {
      values,
      toggleChange,
      handleCustomSelect
    } = this.props;


    return (
      <React.Fragment key={index}>
        {this.tableHeaderOtherVehicle(vehicle.ownership_status, 'mob')}
        <h3 className="card-header font-weight-normal">Basic</h3>
        <dl className="pricing-details">
          <dt>
            {
              this.state.hasLapses
              ? <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              : <NumberFormat value={Math.trunc(lowestBasic.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </dt>
          <dd>
            {
              this.state.hasLapses
              ? "per year"
              : "per month"
            }
          </dd>
          {
            this.state.hasLapses
            ?
            <dd>
              <small className="bg-yellow"><b>(Payment in full required)</b></small>
            </dd>
            :
            <dd>
              <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
              <small className="extra-small">(Monthly payments subject to eligibility)</small>
            </dd>
          }
        </dl>

        <table className="table">
          <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
            <img src="images/car-sm.png" alt="car" />
            {vehicle.year} {vehicle.make} {vehicle.model}
          </th>
          <tr>
            <td className="mobile-cell">Liability: $1,000,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Comprehensive Deductible &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Collision Deductible &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Accident Forgiveness &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Purchase Price Protection &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="text-xs-center" colSpan="2">
              <button
                disabled={this.state.loading}
                className="btn btn-primary btn-custom get_package"
                onClick={(e) => this.props.selectPackage('Basic', lowestBasic.company, lowestBasic.premium, packageData)}
              >Get Basic</button>
            </td>
          </tr>
        </table>

        <h3 className="card-header font-weight-normal">Basic+</h3>

        <dl className="pricing-details">
          <dt>
            {
              this.state.hasLapses
              ? <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              : <NumberFormat value={Math.trunc(lowestStandard.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </dt>
          <dd>
            {
              this.state.hasLapses
              ? "per year"
              : "per month"
            }
          </dd>
          {
            this.state.hasLapses
            ?
            <dd>
              <small className="bg-yellow"><b>(Payment in full required)</b></small>
            </dd>
            :
            <dd>
              <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
              <small className="extra-small">(Monthly payments subject to eligibility)</small>
            </dd>
          }
        </dl>

        <table className="table">
          <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
            <img src="images/car-sm.png" alt="car" />
            {vehicle.year} {vehicle.make} {vehicle.model}
          </th>
          <tr>
            <td className="mobile-cell">Liability: $1,000,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Comprehensive Deductible: $1,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Collision Deductible &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Accident Forgiveness &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Purchase Price Protection &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="text-xs-center" colSpan="2">
              <button
                disabled={this.state.loading}
                className="btn btn-primary btn-custom get_package"
                onClick={(e) => this.props.selectPackage('Basic +', lowestStandard.company, lowestStandard.premium, packageData)}
              >Get Basic+</button>
            </td>
          </tr>
        </table>

        <h3 className="card-header font-weight-normal">Enhanced</h3>

        <dl className="pricing-details">
          <dt>
            {
              this.state.hasLapses
              ? <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              : <NumberFormat value={Math.trunc(lowestPlus.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </dt>
          <dd>
            {
              this.state.hasLapses
              ? "per year"
              : "per month"
            }
          </dd>
          {
            this.state.hasLapses
            ?
            <dd>
              <small className="bg-yellow"><b>(Payment in full required)</b></small>
            </dd>
            :
            <dd>
              <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
              <small className="extra-small">(Monthly payments subject to eligibility)</small>
            </dd>
          }
        </dl>

        <table className="table">
          <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
            <img src="images/car-sm.png" alt="car" />
            {vehicle.year} {vehicle.make} {vehicle.model}
          </th>
          <tr>
            <td className="mobile-cell">Liability: $1,000,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Comprehensive Deductible: $1,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Collision Deductible: $1,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Accident Forgiveness &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="mobile-cell">Purchase Price Protection &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          </tr>
          <tr>
            <td className="text-xs-center" colSpan="2">
              <button
                disabled={this.state.loading}
                className="btn btn-primary btn-custom get_package"
                onClick={(e) => this.props.selectPackage('Enhanced', lowestPlus.company, lowestPlus.premium, packageData)}
              >Get Enhanced</button>
            </td>
          </tr>
        </table>

        <h3 className="card-header font-weight-normal">Custom</h3>
        {
          lowestCustom ?
          <dl className="pricing-details">
            <dt>
              {
                this.state.hasLapses
                ? <NumberFormat value={Math.trunc(lowestCustom.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                : <NumberFormat value={Math.trunc(lowestCustom.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              }
            </dt>
            <dd>
              {
                this.state.hasLapses
                ? "per year"
                : "per month"
              }
            </dd>
            {
              this.state.hasLapses
              ?
              <dd>
                <small className="bg-yellow"><b>(Payment in full required)</b></small>
              </dd>
              :
              <dd>
                <NumberFormat value={Math.trunc(lowestCustom.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
                <small className="extra-small">(Monthly payments subject to eligibility)</small>
              </dd>
            }
          </dl>
          : <img src="images/custom.png" alt="custom" className="custom-icon text-xs-center" />
        }
        <table className="table">
          <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
            <img src="images/car-sm.png" alt="car" />
            {
              this.state.vehicles.length === 1
              ? (
                  <span>
                    {this.state.vehicles[0].year} {this.state.vehicles[0].make} {this.state.vehicles[0].model}
                  </span>
                )
              : null
            }
          </th>
          <tr>
            <td className="mobile-cell">Liability: $1,000,000 &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <Select
                value={values.selectedLiabilityLimit}
                onChange = { (value) => handleCustomSelect('selectedLiabilityLimit', value)}
                options={liabilityLimits}
                isSearchable
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Comprehensive Deductible &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
              </td>
            <td className="text-xs-center">
              <Select
                value={values.selectedComprehensiveDeductible}
                onChange = { (value) => handleCustomSelect('selectedComprehensiveDeductible', value)}
                options={deductibles}
                isSearchable
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Collision Deductible &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <Select
                value={values.selectedCollisionDeductible}
                onChange = { (value) => handleCustomSelect('selectedCollisionDeductible', value)}
                options={deductibles}
                isSearchable
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Accident Forgiveness &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <input
                type="checkbox"
                autoComplete="off"
                defaultChecked={values.accidentForgiveness}
                name="accidentForgiveness"
                onChange={toggleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <input
                type="checkbox"
                autoComplete="off"
                defaultChecked={values.rentalVehicleReplacement}
                name="rentalVehicleReplacement"
                onChange={toggleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <input
                type="checkbox"
                autoComplete="off"
                defaultChecked={values.rentalVehicleInsurance}
                name="rentalVehicleInsurance"
                onChange={toggleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="mobile-cell">Purchase Price Protection &nbsp;
              <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
                <img src="images/help.png" alt="/"/>
              </Tooltip>
            </td>
            <td className="text-xs-center">
              <input
                type="checkbox"
                autoComplete="off"
                defaultChecked={values.purchasePriceProtection}
                name="purchasePriceProtection"
                onChange={toggleChange}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="text-xs-center">
              <button
                disabled={this.checkLiabilityLimitForMobile() || this.state.loading}
                className="btn btn-default btn-pkg"
                onClick={this.saveCustomQuote}>
                Calculate!
              </button>
              <button
                className="btn btn-primary btn-custom quote-button-less-margin get_package"
                onClick={(e) => this.props.selectPackage('custom', lowestCustom.company, lowestCustom.premium, packageData)}
                disabled={_.isUndefined(lowestCustom)  || lowestCustom === 0 || this.state.loading}>
                Get Custom!
              </button>
            </td>
          </tr>
        </table>
      </React.Fragment>
    )

  }

  singleMobileVehicle(lowestBasic, lowestStandard, lowestPlus, lowestCustom, packageData) {
    const {
      values,
      toggleChange,
      handleCustomSelect
    } = this.props;

    return (
      <div>
      {this.tableHeaderOtherVehicle(values.ownershipStatus, 'mob')}
      <h3 className="card-header font-weight-normal">Basic</h3>
      <dl className="pricing-details">
        <dt>
          {
            this.state.hasLapses
            ? <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            : <NumberFormat value={Math.trunc(lowestBasic.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          }
        </dt>
        <dd>
          {
            this.state.hasLapses
            ? "per year"
            : "per month"
          }
        </dd>
        {
          this.state.hasLapses
          ?
          <dd>
            <small className="bg-yellow"><b>(Payment in full required)</b></small>
          </dd>
          :
          <dd>
            <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
            <small className="extra-small">(Monthly payments subject to eligibility)</small>
          </dd>
        }
      </dl>
      <table className="table">
        <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
          <img src="images/car-sm.png" alt="car" />
          {
            this.state.vehicles.length === 1
            ? (

                <span>
                  {this.state.vehicles[0].year} {this.state.vehicles[0].make} {this.state.vehicles[0].model}
                </span>

              )
            : null
          }
        </th>
        <tr>
          <td className="mobile-cell">Liability: $1,000,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Comprehensive Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Collision Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Accident Forgiveness  &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Purchase Price Protection &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="text-xs-center" colSpan="2">
            <button
              disabled={this.state.loading}
              className="btn btn-primary btn-custom get_package"
              onClick={(e) => this.props.selectPackage('Basic', lowestBasic.company, lowestBasic.premium, packageData)}
            >Get Basic</button>
          </td>
        </tr>
      </table>
      <hr />
      <h3 className="card-header font-weight-normal">Basic+</h3>

      <dl className="pricing-details">
        <dt>
          {
            this.state.hasLapses
            ? <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            : <NumberFormat value={Math.trunc(lowestStandard.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          }
        </dt>
        <dd>
          {
            this.state.hasLapses
            ? "per year"
            : "per month"
          }
        </dd>
        {
          this.state.hasLapses
          ?
          <dd>
            <small className="bg-yellow"><b>(Payment in full required)</b></small>
          </dd>
          :
          <dd>
            <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
            <small className="extra-small">(Monthly payments subject to eligibility)</small>
          </dd>
        }
      </dl>

      <table className="table">
        <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
          <img src="images/car-sm.png" alt="car" />
          {
            this.state.vehicles.length === 1
            ? (
                <div>
                  <span>
                    {this.state.vehicles[0].year} {this.state.vehicles[0].make} {this.state.vehicles[0].model}
                  </span>
                </div>
              )
            : null
          }
        </th>
        <tr>
          <td className="mobile-cell">Liability: $1,000,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Comprehensive Deductible: $1,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Collision Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Accident Forgiveness &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip></td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Purchase Price Protection &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="text-xs-center" colSpan="2">
            <button
              disabled={this.state.loading}
              className="btn btn-primary btn-custom get_package"
              onClick={(e) => this.props.selectPackage('Basic +', lowestStandard.company, lowestStandard.premium, packageData)}
            >Get Basic+</button>
          </td>
        </tr>
      </table>
      <hr />
      <h3 className="card-header font-weight-normal">Enhanced</h3>

      <dl className="pricing-details">
        <dt>
          {
            this.state.hasLapses
            ? <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            : <NumberFormat value={Math.trunc(lowestPlus.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          }
        </dt>
        <dd>
          {
            this.state.hasLapses
            ? "per year"
            : "per month"
          }
        </dd>
        {
          this.state.hasLapses
          ?
          <dd>
            <small className="bg-yellow"><b>(Payment in full required)</b></small>
          </dd>
          :
          <dd>
            <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
            <small className="extra-small">(Monthly payments subject to eligibility)</small>
          </dd>
        }
      </dl>

      <table className="table">
        <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
          <img src="images/car-sm.png" alt="car" />
          {
            this.state.vehicles.length === 1
            ? (
                <div>
                  <span>
                    {this.state.vehicles[0].year} {this.state.vehicles[0].make} {this.state.vehicles[0].model}
                  </span>
                </div>
              )
            : null
          }
        </th>
        <tr>
          <td className="mobile-cell">Liability: $1,000,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Comprehensive Deductible: $1,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Collision Deductible: $1,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/check.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Accident Forgiveness &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="mobile-cell">Purchase Price Protection &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        </tr>
        <tr>
          <td className="text-xs-center" colSpan="2">
            <button
              disabled={this.state.loading}
              className="btn btn-primary btn-custom get_package"
              onClick={(e) => this.props.selectPackage('Enhanced', lowestPlus.company, lowestPlus.premium, packageData)}
            >Get Enhanced</button>
          </td>
        </tr>
      </table>
      <hr />
      <h3 className="card-header font-weight-normal">Custom</h3>
      {
        lowestCustom ?

        <dl className="pricing-details">
          <dt>
            {
              this.state.hasLapses
              ? <NumberFormat value={Math.trunc(lowestCustom.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              : <NumberFormat value={Math.trunc(lowestCustom.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </dt>
          <dd>
            {
              this.state.hasLapses
              ? "per year"
              : "per month"
            }
          </dd>
          {
            this.state.hasLapses
            ?
            <dd>
              <small className="bg-yellow"><b>(Payment in full required)</b></small>
            </dd>
            :
            <dd>
              <NumberFormat value={Math.trunc(lowestCustom.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
              <small className="extra-small">(Monthly payments subject to eligibility)</small>
            </dd>
          }
        </dl>
        : <img src="images/custom.png" alt="custom" className="custom-icon text-xs-center" />
      }
      <table className="table">
        <th className="vehicle-name" style={{width: '26.66%'}} colSpan="4">
          <img src="images/car-sm.png" alt="car" />
          {
            this.state.vehicles.length === 1
            ? (
                <div>
                  <span>
                    {this.state.vehicles[0].year} {this.state.vehicles[0].make} {this.state.vehicles[0].model}
                  </span>
                </div>
              )
            : null
          }
        </th>
        <tr>
          <td className="mobile-cell">Liability: $1,000,000 &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <Select
              value={values.selectedLiabilityLimit}
              onChange = { (value) => handleCustomSelect('selectedLiabilityLimit', value)}
              options={liabilityLimits}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Comprehensive Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <Select
              value={values.selectedComprehensiveDeductible}
              onChange = { (value) => handleCustomSelect('selectedComprehensiveDeductible', value)}
              options={deductibles}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Collision Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <Select
              value={values.selectedCollisionDeductible}
              onChange = { (value) => handleCustomSelect('selectedCollisionDeductible', value)}
              options={deductibles}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Accident Forgiveness &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.accidentForgiveness}
              name="accidentForgiveness"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Replacement &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.rentalVehicleReplacement}
              name="rentalVehicleReplacement"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Rental Vehicle Insurance &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.rentalVehicleInsurance}
              name="rentalVehicleInsurance"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <td className="mobile-cell">Purchase Price Protection &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.purchasePriceProtection}
              name="purchasePriceProtection"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2" className="text-xs-center">
            <button
              disabled={this.checkLiabilityLimitForMobile() || this.state.loading}
              className="btn btn-default btn-pkg"
              onClick={this.saveCustomQuote}>
                Calculate!
            </button>
              <button
                className="btn btn-primary btn-custom quote-button-less-margin get_package"
                onClick={(e) => this.props.selectPackage('custom', lowestCustom.company, lowestCustom.premium, packageData)}
                disabled={_.isUndefined(lowestCustom) || lowestCustom === 0 || this.state.loading}>
                Get Custom!
              </button>
              {/*
              <button
                className="btn btn-primary btn-pkg"
                onClick={this.saveCustomQuote}
                disabled={!values.customQuoteIsValid || this.state.loading}>
                  Get Quote!
              </button>
              */}
          </td>
        </tr>
      </table>
    </div>)
  }

  tableHeaderMultiVehicle(vehicle){
    return (
    (vehicle.ownership_status === 'Financed' || vehicle.ownership_status === 'Leased') ?
    <th className="borderless ownershipStatusborderless-left vehicle-name" style={{width: '26.66%'}} colSpan="5">
      <img src="images/car-sm.png" alt="car" /> {vehicle.year} {vehicle.make} {vehicle.model}
      <br/>
      <span className='invalid-feedback'>Collision and Comprehensive Coverages are mandatory as vehicle is financed or leased. Enhanced package is required for this vehicle.</span>
    </th>
    :
    <th className="borderless borderless-left vehicle-name" style={{width: '26.66%'}} colSpan="4">
      <img src="images/car-sm.png" alt="car" /> {vehicle.year} {vehicle.make} {vehicle.model}
    </th>
    )
  }

  tableHeaderOtherVehicle(ownershipStatus, deviceType){
    return(
      (ownershipStatus === 'Financed' || ownershipStatus === 'Leased') ?
      <th className="borderless borderless-left vehicle-name" style={{width: '26.66%'}}  {...(deviceType === 'web') ? { colSpan: "5" } : {} } >
        <span className='invalid-feedback'>Collision and Comprehensive Coverages are mandatory as vehicle is financed or leased. Enhanced package is required for this vehicle.</span>
        { deviceType === 'mob' ? <hr /> : '' }
      </th>
      :
      ''
    )
  }



  multiVehicle(vehicle, index, lowestBasic, lowestStandard, lowestPlus, lowestCustom) {
    const {
      values,
      handleCustomSelect,
      toggleChange
    } = this.props;

    // This is only done for basic package. The reason this is done is because Basic package
    // is calculated and we can't include occasional drivers in the driver total
    if (lowestBasic) {
      if (lowestBasic.vehicle[index].Link === 'Occ') {
        lowestBasic.vehicle.splice(index, 1);
      }
    }

    return (
      <React.Fragment key={index}>
        <ReactTooltip className="wtf" />
      <tr>
        { this.tableHeaderMultiVehicle(vehicle) }
      </tr>
      <tr>
        <th className="borderless borderless-left" style={{width: '26.66%'}}>
          Liability &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center borderless">$1,000,000</td>
        <td className="text-xs-center borderless">$1,000,000</td>
        <td className="text-xs-center borderless">$1,000,000</td>
        <td className="borderless">
          <Select
            ref={(ref) => this.liabilities[index] = ref}
            value={values.selectedLiabilityLimit[index]}
            name="selectedLiabilityLimit"
            onChange={ (value) => handleCustomSelect(`selectedLiabilityLimit[${index}]`, value)}
            options={liabilityLimits}
            isSearchable
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Comprehensive Deductible &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
        <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
        <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
        <td>
          <Select
            ref={(ref) => this.comprehensives[index] = ref}
            value={values.selectedComprehensiveDeductible[index]}
            name="selectedComprehensiveDeductible"
            onChange={ (value) => handleCustomSelect(`selectedComprehensiveDeductible[${index}]`, value)}
            options={deductibles}
            isSearchable
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Collision Deductible &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
        <td>
          <Select
            ref={(ref) => this.collisions[index] = ref}
            value={values.selectedCollisionDeductible[index]}
            name="selectedCollisionDeductible"
            onChange={ (value) => handleCustomSelect(`selectedCollisionDeductible[${index}]`, value)}
            options={deductibles}
            isSearchable
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Accident Forgiveness &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/check.png" alt="check" /></td>
        <td className="text-xs-center">
          <input
            ref={(ref) => this.accidents[index] = ref}
            type="checkbox"
            autoComplete="off"
            defaultChecked={values.accidentForgiveness[index]}
            name="accidentForgiveness"
            onChange={toggleChange}
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Rental Vehicle Replacement &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center">
          <input
            ref={(ref) => this.replacements[index] = ref}
            type="checkbox"
            autoComplete="off"
            defaultChecked={values.rentalVehicleReplacement[index]}
            name="rentalVehicleReplacement"
            onChange={toggleChange}
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Rental Vehicle Insurance &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center">
          <input
            ref={(ref) => this.insurances[index] = ref}
            type="checkbox"
            autoComplete="off"
            defaultChecked={values.rentalVehicleInsurance[index]}
            name="rentalVehicleInsurance"
            onChange={toggleChange}
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Purchase Price Protection &nbsp;
          <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
            <img src="images/help.png" alt="/"/>
          </Tooltip>
        </th>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
        <td className="text-xs-center">
          <input
            ref={(ref) => this.protections[index] = ref}
            type="checkbox"
            autoComplete="off"
            defaultChecked={values.purchasePriceProtection[index]}
            name="purchasePriceProtection"
            onChange={toggleChange}
          />
        </td>
      </tr>
      <tr>
        <th className="borderless-left" style={{width: '26.66%'}}>
          Monthly Premium
        </th>
        <td className="text-xs-center">
        { lowestBasic && this.lowestBasicPremium(lowestBasic, index)
        }
        </td>

        <td className="text-xs-center">
        { lowestStandard && this.lowestStandardPremium(lowestStandard, index)
        }
        </td>
        <td className="text-xs-center">
        { lowestPlus &&
          <NumberFormat value={Math.trunc(lowestPlus.vehicle[index].VehicleTotal.FinalPremium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        }
        </td>
        <td className="text-xs-center">
          {
            lowestCustom &&
              <NumberFormat value={Math.trunc(lowestCustom.vehicle[index].VehicleTotal.FinalPremium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          }
        </td>
      </tr>
    </React.Fragment>)
  }

  debugInfo() {
      let quotes = [];

      if (this.state.plus) {
        // iterate over each carrier data and populate a quote object to work with
        _.each(this.state.plus, plus => {
          if (plus.CodeNames) {
            // const {
            //   FinalPremium
            // } = plus.CodeNames.Auto.QuoteInfo.PolicyTotal;
            quotes.push({
              carrier: plus.CodeNames.Auto.QuoteInfo.CompanyCode,
              quoteInfo: plus.CodeNames.Auto.QuoteInfo
            })
          }
        });
      }

      return (
        <React.Fragment>
        {
          this.state.loading
          ?
          <div className="absolute-center">
            <h6>Please wait...</h6>
          </div>
          :
          quotes.map((quote) => (
            <div className="widget-holder widget-sm col-md-4 widget-full-height" key={quote.carrier}>
              <div className="widget-bg">
                <div className="widget-body">
                  <div className="counter-w-info media">
                    <div className="media-body">
                      <hr />
                      <h6>{quote.carrier} - Plus Package</h6>
                      <span className="counter-title">
                        <span className="counter">
                        </span>
                      </span>
                      <h4>${quote.quoteInfo.PolicyTotal.FinalPremium}</h4>
                      <hr />
                      <b>Vehicle Info</b>
                      {
                        !_.isUndefined(quote.quoteInfo.Vehicle)
                        ?
                          <React.Fragment>
                            <ul>
                              <li>{quote.quoteInfo.Vehicle.Year}</li>
                              <li>{quote.quoteInfo.Vehicle.Manufacturer}</li>
                              <li>{quote.quoteInfo.Vehicle.MakeAndModel}</li>
                            </ul>

                            <b>VICC Info</b>
                            <ul>
                              <li>{quote.quoteInfo.Vehicle.VICCCode}</li>
                              <li>{quote.quoteInfo.Vehicle.VICCCodeExt} <small>(Extended)</small></li>
                            </ul>
                          </React.Fragment>
                        : null
                      }
                      <hr />
                      <b>Coverages</b>
                      <ul>
                        {
                          !_.isUndefined(quote.quoteInfo.Vehicle)
                          ?
                            quote.quoteInfo.VehLink.CoveragePremium.map(prem => (
                              <li key={prem.FinalPremium}>{prem.CarrierCovCode}: ${prem.FinalPremium}</li>
                            ))
                          :
                          null
                        }
                      </ul>

                      <b>Discounts</b>
                      {
                        !_.isUndefined(quote.quoteInfo.Vehicle)
                        ? _.isArray(quote.quoteInfo.VehLink.Discount)
                          ? <ul>
                            {
                              quote.quoteInfo.VehLink.Discount.map(discount => (
                                <React.Fragment>
                                  <li style={{listStyle: "none"}}><hr /></li>
                                  <li>Discount code: {discount.DiscountCode}</li>
                                  <li>Percentage: {discount.Percentage}%</li>
                                  <li>Description: {discount.Descript}</li>
                                  <li>Appled? {discount.Applied}</li>
                                </React.Fragment>
                              ))
                            }
                          </ul>
                          :
                            <ul>

                            </ul>
                        : null
                      }
                      <b>Messages</b>
                      {
                        /*_.isArray(quote.quoteInfo.VehLink.Message)
                        ? <ul>
                          {quote.quoteInfo.VehLink.Message.map(msg => (
                            <React.Fragment>
                              <li style={{listStyle: "none"}}><hr /></li>
                              <li>{msg.MessageText}</li>
                              <li>Severity: {msg.Severity}</li>
                            </React.Fragment>
                          ))}
                          </ul>
                        : <ul>
                            <li>{quote.quoteInfo.VehLink.Message.MessageText}</li>
                            <li>Severity: {quote.quoteInfo.VehLink.Message.Severity}</li>
                          </ul>
                          */}
                      <b>Ratebook Information</b>
                      {
                        !_.isUndefined(quote.quoteInfo.Vehicle) ?
                          <ul>
                            <li>{quote.quoteInfo.Vehicle.RateBookEffDate}</li>
                            <li>{quote.quoteInfo.Vehicle.Ratebook}</li>
                          </ul>
                        : null
                      }
                    <div className="progress" style={{width: '100%', position: 'relative', top: '25px'}}>
                      <div className="progress-bar bg-success" style={{width: '100%'}} role="progressbar">
                        <span className="sr-only">100% Complete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>))
        }
        </React.Fragment>
      )
    }

  singleVehicle() {
    const {
      values,
      handleCustomSelect,
      toggleChange
    } = this.props;

    return (
      <React.Fragment>
        <ReactTooltip className="wtf" />
        <tr>
        {this.tableHeaderOtherVehicle(values.ownershipStatus, 'web')}
        </tr>
        <tr>
          <th className="borderless borderless-left" style={{width: '26.66%'}}>
            Liability &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Liability: It provides protection if someone else is killed or injured or if you damage someone else’s property while operating your vehicle. This coverage will also pay for your legal costs if you are sued as a result of the injury/death or damage. In Ontario, when Liability coverage is purchased other mandatory coverages are also included on your policy, they are: Accident Benefits, Direct Compensation, Uninsured Automobile.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center borderless">$1,000,000</td>
          <td className="text-xs-center borderless">$1,000,000</td>
          <td className="text-xs-center borderless">$1,000,000</td>
          <td className="borderless">
            <Select
              value={values.selectedLiabilityLimit}
              onChange = { (value) => handleCustomSelect('selectedLiabilityLimit', value)}
              options={liabilityLimits}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Comprehensive Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Comprehensive: This coverage provides protection for damage to your vehicle from causes other than collision (except if with a bird or animal). For example, your policy would pay if the damage to your vehicle was caused by hail, flood, fire, theft, glass breakage, failing objects, windstorms, vandalism or mischief, riot…">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="line" /></td>
          <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
          <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
          <td className="borderless">
            <Select
              value={values.selectedComprehensiveDeductible}
              onChange = { (value) => handleCustomSelect('selectedComprehensiveDeductible', value)}
              options={deductibles}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Collision Deductible &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Collision: This coverage provides protection for damage to your vehicle if it’s involved in a collision with an object or an unidentified vehicle (hit and run).">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/check.png" alt="check" /> $1,000</td>
          <td className="borderless">
            <Select
              value={values.selectedCollisionDeductible}
              onChange = { (value) => handleCustomSelect('selectedCollisionDeductible', value)}
              options={deductibles}
              isSearchable
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Accident Forgiveness &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Accident Forgiveness: This coverage protects your driving record which helps you avoid a premium increase after your first accident if you are found responsible/at-fault, even if you are only partially responsible.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/check.png" alt="check" /></td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.accidentForgiveness}
              name="accidentForgiveness"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Rental Vehicle Replacement&nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Replacement: Also known as the Loss of Use coverage (OPCF20), this coverage provides you with a replacement vehicle or reimbursement for transportation (taxis or public transit) while your vehicle is being repaired or replaced after being damaged in a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.rentalVehicleReplacement}
              name="rentalVehicleReplacement"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Rental Vehicle Insurance &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Rental Vehicle Insurance: Another name for this coverage is Legal Liability for Damage to Non-Owned Automobiles (OPCF27). The name is more complicated that it seems, it provides protection for damage to a vehicle you rent or borrow. If you have this coverage, there is no need to purchase the additional insurance coverage offered by the rental company.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.rentalVehicleInsurance}
              name="rentalVehicleInsurance"
              onChange={toggleChange}
            />
          </td>
        </tr>
        <tr>
          <th className="borderless-left" style={{width: '26.66%'}}>
            Purchase Price Protection &nbsp;
            <Tooltip placement="right" trigger="click"tooltip="Purchase Price Protection: Most commonly referred to as the Limited Waiver of Depreciation, this one is true to its name. It prevents the depreciation from being applied to your settlement, allowing you to receive the purchase or lease price of your new vehicle (as shown on your bill of sale/lease agreement) if your vehicle is stolen or written off due to a covered loss.">
              <img src="images/help.png" alt="/"/>
            </Tooltip>
          </th>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center"><img src="images/line.png" alt="check" /></td>
          <td className="text-xs-center">
            <input
              type="checkbox"
              autoComplete="off"
              defaultChecked={values.purchasePriceProtection}
              name="purchasePriceProtection"
              onChange={toggleChange}
            />
          </td>
        </tr>
      </React.Fragment>)
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  checkLiabilityLimitForMobile(){
    const { values } = this.props;
    return values.selectedLiabilityLimit === ''
  }

  checkLiabilityLimit(numberOfVehicles){
    const { values } = this.props;
    if (numberOfVehicles > 1) {
      let selectedVehCount = 0;
      _.forEach(this.state.vehicles, (vehicle, i) => {
        if (!_.isUndefined(values[`selectedLiabilityLimit[${i}]`])) {
          selectedVehCount++;
        }
      });
      return numberOfVehicles !== selectedVehCount
    }
    else {
      return values.selectedLiabilityLimit === ''
    }
  }

  showCustomPricing(pkg) {
    if (this.state.loading) {
      return (
        <img src="images/loading.gif" alt="Loading" />
      )
    } else if (!pkg) {
      return (
        <img src="images/custom.png" alt="custom" className="custom-icon" />
      )
    } else {
      return (
        <dl className="pricing-details">
          <ReactTooltip className="wtf" />
          <dt>
            {
              this.state.hasLapses
              ? <NumberFormat value={Math.trunc(pkg.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              : <NumberFormat value={Math.trunc(pkg.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </dt>
          <dd>
            {
              this.state.hasLapses
              ? "per year"
              : "per month"
            }
          </dd>
          {
            this.state.hasLapses
            ?
            <dd>
              <small className="bg-yellow"><b>(Payment in full required)</b></small>
            </dd>
            :
            <dd>
              <NumberFormat value={Math.trunc(pkg.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
              <small className="extra-small">(Monthly payments subject to eligibility)</small>
            </dd>
          }
        </dl>
      )
    }
  }

  _mapCarrierName(carrier) {
    let carrierFullName;
    switch (carrier) {
      case "GA":
        carrierFullName = "Aviva"
        break;
      case "GA2":
        carrierFullName = "Aviva Journey"
        break;
      case "COA":
        carrierFullName = "Coachman"
        break;
      case "ECGL":
        carrierFullName = "Echelon"
        break;
      case "ECON":
        carrierFullName = "Economical"
        break;
      case "FAC":
        carrierFullName = "Facility"
        break;
      case "GORE":
        carrierFullName = "Gore"
        break;
      case "HAL":
        carrierFullName = "Intact"
        break;
      case "HAL3":
        carrierFullName = "Intact My Drive"
        break;
      case "JEV":
        carrierFullName = "Jevco Insurance Company"
        break;
      case "PAF":
        carrierFullName = "Pafco"
        break;
      case "PEM":
        carrierFullName = "Pembridge"
        break;
      case "SGI":
        carrierFullName = "SGI"
        break;
      // case "WEST":
      //   carrierFullName = "Western"
      //   break;
      default:
        carrierFullName = carrier
        break;
    }

    return carrierFullName;
  }

  renderPackages() {
    let quotes = {
      annualPlus: [],
      annualStandard: [],
      annualBasic: [],
      annualCustom: [],
    };

    let lowestBasic,
        lowestStandard,
        lowestPlus,
        lowestCustom;

    let packageData = {
      basic_messages: [],
      basic_plus_messages: [],
      enhanced_messages: [],
      custom_messages: [],
    };

    const basicPackage = _.cloneDeep(this.state.basic);

    _.forEach(basicPackage, (cov) => {
      _.forEach(_.get(cov, 'CodeNames.Auto', []), (attribute) => {
        // when theres more than 1 vehicle, VehLink is an array
        // and needs to be treated differently.
        if (!_.isUndefined(attribute.VehLink) && _.isArray(attribute.VehLink)) {
          _.forEach(attribute.VehLink, vehicle => {
            _.forEach(vehicle.CoveragePremium, premCode => {
              if (premCode.CarrierCovCode === 'CMP') {
                // get the final premium total from basic+
                let finalPrem = _.get(vehicle, 'DriverTotal.FinalPremium');
                // mutate the cloned object and subtract the CMP from final premium
                // to create the Basic package final premium.
                _.set(vehicle, 'DriverTotal.FinalPremium',
                  (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                );
              }
            })
          })
        } else {
          // single vehicle
          if (!_.isUndefined(attribute.VehLink)) {
            _.forEach(attribute.VehLink.CoveragePremium, premCode => {
              if (premCode.CarrierCovCode === 'CMP') {
                // get the final premium total from basic+
                let finalPrem = _.get(attribute, 'DriverTotal.FinalPremium');
                // mutate the cloned object and subtract the CMP from final premium
                // to create the Basic package final premium.
                _.set(attribute, 'DriverTotal.FinalPremium',
                  (parseInt(finalPrem) - parseInt(premCode.FinalPremium)).toString()
                );
              }
            })
          }
        }
      });
    })

    if (basicPackage) {
      // iterate over each carrier data and populate a quote object to work with
      _.each(basicPackage, carrier => {
        if (_.isArray(carrier)) {
          const { FinalPremium } = carrier[0].CodeNames.Auto.QuoteInfo.PolicyTotal;
          const { VehLink } = carrier[0].CodeNames.Auto.QuoteInfo;
          const { Vehicle } = carrier[0].CodeNames.Auto.QuoteInfo;
          if (parseInt(FinalPremium) > 0) {
            quotes.annualBasic.push({
              company: carrier[0].CodeNames.Auto.QuoteInfo.CompanyCode,
              premium: parseInt(FinalPremium),
              vehicle: VehLink,
              vehicles: Vehicle,
              message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
            })
          }
        } else {
          if (carrier.CodeNames) {
            const { FinalPremium } = carrier.CodeNames.Auto.QuoteInfo.PolicyTotal;
            const { VehLink } = carrier.CodeNames.Auto.QuoteInfo;
            const { Vehicle } = carrier.CodeNames.Auto.QuoteInfo;

            if (parseInt(FinalPremium) > 0) {
              quotes.annualBasic.push({
                company: carrier.CodeNames.Auto.QuoteInfo.CompanyCode,
                premium: parseInt(FinalPremium),
                vehicle: VehLink,
                vehicles: Vehicle,
                message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
              })
            }
          }
        }
      });

      // reduce to get smallest
      if (quotes.annualBasic.length > 0) {
        lowestBasic = quotes.annualBasic.reduce((prev, curr) => {
          return prev.premium < curr.premium ? prev : curr;
        });
      } else {
        lowestBasic = 0;
      }
    }

    if (this.state.standard) {
      // iterate over each carrier data and populate a quote object to work with
      _.each(this.state.standard, carrier => {
        if (_.isArray(carrier)) {
          const { FinalPremium } = carrier[0].CodeNames.Auto.QuoteInfo.PolicyTotal;
          const { Vehicle } = carrier[0].CodeNames.Auto.QuoteInfo;

          // Grab message from VehLink
          const { VehLink } = carrier[0].CodeNames.Auto.QuoteInfo;

          if (parseInt(FinalPremium) > 0) {
            quotes.annualStandard.push({
              company: carrier[0].CodeNames.Auto.QuoteInfo.CompanyCode,
              premium: parseInt(FinalPremium) || 0,
              vehicle: Vehicle,
              vehData: VehLink,
              message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
            })
          }
        } else {
          if (carrier.CodeNames) {
            const { FinalPremium } = carrier.CodeNames.Auto.QuoteInfo.PolicyTotal;
            const { Vehicle } = carrier.CodeNames.Auto.QuoteInfo;

            // Grab message from VehLink
            const { VehLink } = carrier.CodeNames.Auto.QuoteInfo;

            if (parseInt(FinalPremium) > 0) {
              quotes.annualStandard.push({
                company: carrier.CodeNames.Auto.QuoteInfo.CompanyCode,
                premium: parseInt(FinalPremium) || 0,
                vehicle: Vehicle,
                vehData: VehLink,
                message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
              })
            }
          }
        }
      });

      // reduce to get smallest
      if (quotes.annualStandard.length > 0) {
        lowestStandard = quotes.annualStandard.reduce((prev, curr) => {
          return prev.premium < curr.premium ? prev : curr;
        });
      } else {
        lowestStandard = 0;
      }
    }

    if (this.state.plus) {
      // iterate over each carrier data and populate a quote object to work with
      _.each(this.state.plus, carrier => {
        if (_.isArray(carrier)) {
          const { FinalPremium } = carrier[0].CodeNames.Auto.QuoteInfo.PolicyTotal;
          const { Vehicle } = carrier[0].CodeNames.Auto.QuoteInfo;

          // Grab message from VehLink
          const { VehLink } = carrier[0].CodeNames.Auto.QuoteInfo;

          if (parseInt(FinalPremium) > 0) {
            quotes.annualPlus.push({
              company: carrier[0].CodeNames.Auto.QuoteInfo.CompanyCode,
              premium: parseInt(FinalPremium) || 0,
              vehicle: Vehicle,
              message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
            })
          }
        } else {
          if (carrier.CodeNames) {
            const { FinalPremium } = carrier.CodeNames.Auto.QuoteInfo.PolicyTotal;
            const { Vehicle } = carrier.CodeNames.Auto.QuoteInfo;

            // Grab message from VehLink
            const { VehLink } = carrier.CodeNames.Auto.QuoteInfo;

            if (parseInt(FinalPremium) > 0) {
              quotes.annualPlus.push({
                company: carrier.CodeNames.Auto.QuoteInfo.CompanyCode,
                premium: parseInt(FinalPremium) || 0,
                vehicle: Vehicle,
                message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
              })
            }
          }
        }
      });

      // reduce to get smallest
      if (quotes.annualPlus.length > 0) {
        lowestPlus = quotes.annualPlus.reduce((prev, curr) => {
          return prev.premium < curr.premium ? prev : curr;
        });
      } else {
        lowestPlus = 0;
      }
    }

    if (this.state.custom) {
      // iterate over each carrier data and populate a quote object to work with
      _.each(this.state.custom, carrier => {
        if (_.isArray(carrier)) {
          const { FinalPremium } = carrier[0].CodeNames.Auto.QuoteInfo.PolicyTotal;
          const { Vehicle } = carrier[0].CodeNames.Auto.QuoteInfo;
          // Grab message from VehLink
          const { VehLink } = carrier[0].CodeNames.Auto.QuoteInfo;

          if (parseInt(FinalPremium) > 0) {
            quotes.annualCustom.push({
              company: carrier[0].CodeNames.Auto.QuoteInfo.CompanyCode,
              premium: parseInt(FinalPremium) || 0,
              vehicle: Vehicle,
              message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
            })
          }
        } else {
          if (carrier.CodeNames) {
            const { FinalPremium } = carrier.CodeNames.Auto.QuoteInfo.PolicyTotal;
            const { Vehicle } = carrier.CodeNames.Auto.QuoteInfo;
            // Grab message from VehLink
            const { VehLink } = carrier.CodeNames.Auto.QuoteInfo;

            if (parseInt(FinalPremium) > 0) {
              quotes.annualCustom.push({
                company: carrier.CodeNames.Auto.QuoteInfo.CompanyCode,
                premium: parseInt(FinalPremium) || 0,
                vehicle: Vehicle,
                message: !_.isUndefined(VehLink.Message) ? VehLink.Message : null
              })
            }
          }
        }
      });
      // reduce to get smallest
      if (quotes.annualCustom.length > 0) {
        lowestCustom = quotes.annualCustom.reduce((prev, curr) => {
          return prev.premium < curr.premium ? prev : curr;
        });
      } else {
        lowestCustom = 0;
      }
    }

    if (!this.state.loading) {
      const { values } = this.props;

      if (_.isArray(lowestBasic.message)) {
        lowestBasic.message.forEach(m => {
          packageData.basic_messages.push(`${m.MessageID} - ${m.MessageText}`);
        })
      } else {
        if (!_.isUndefined(lowestBasic.message) && !_.isNull(lowestBasic.message)) {
          packageData.basic_messages.push(`${lowestBasic.message.MessageID} - ${lowestBasic.message.MessageText}`);
        }
      }

      if (_.isArray(lowestStandard.message)) {
        lowestStandard.message.forEach(m => {
          packageData.basic_plus_messages.push(`${m.MessageText} - ${m.MessageText}`);
        })
      } else {
        if (!_.isUndefined(lowestStandard.message) && !_.isNull(lowestStandard.message)) {
          packageData.basic_plus_messages.push(`${lowestStandard.message.MessageID} - ${lowestStandard.message.MessageText}`);
        }
      }

      if (_.isArray(lowestPlus.message)) {
        lowestPlus.message.forEach(m => {
          packageData.enhanced_messages.push(`${m.MessageID} - ${m.MessageText}`);
        })
      } else {
        if (!_.isUndefined(lowestPlus.message) && !_.isNull(lowestPlus.message)) {
          packageData.enhanced_messages.push(`${lowestPlus.message.MessageID} - ${lowestPlus.message.MessageText}`);
        }
      }

      if (_.isArray(lowestCustom.message)) {

        lowestCustom.message.forEach(m => {
          packageData.custom_messages.push(`${m.MessageID} - ${m.MessageText}`);
        })
      } else {
        if (!_.isUndefined(lowestCustom.message) && !_.isNull(lowestCustom.message)) {
          packageData.custom_messages.push(`${lowestCustom.message.MessageID} - ${lowestCustom.message.MessageText}`);
        }
      }

      // if the state is complete and the count is 2 then we will record the lead.
      // If FAC is shown in any of the lowest company packages then store this boolean in the database
      // so that we can perform operations on when the quote is run.
      if (this.state.done && this.state.count === 2) {
        // record leads sleads web service and infusionsoft
        axios.post(`${config.apiUrl}/record_lead`, {
          quote_id: values.quoteId,
          lowest_carrier: this._mapCarrierName(lowestPlus.company),
          has_facility: (lowestBasic.company === 'FAC' || lowestStandard.company === 'FAC' || lowestPlus === 'FAC' || lowestCustom === 'FAC') ? true : false
        }).then(response => {})
        .catch(error => console.log("Record lead error: ", error));
      }

      packageData = {
        ...packageData,
        basic: this._mapCarrierName(lowestBasic.company) + ' ' + lowestBasic.premium,
        basic_plus: this._mapCarrierName(lowestStandard.company) + ' ' + lowestStandard.premium,
        enhanced: this._mapCarrierName(lowestPlus.company) + ' ' + lowestPlus.premium,
      }

      if (lowestCustom.premium > 0) {
        packageData.custom = this._mapCarrierName(lowestCustom.company) + ' ' + lowestCustom.premium
      }

      axios.put(`${config.apiUrl}/update_quote_packages/${values.quoteId}`, packageData)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }

    if (lowestBasic === 0 && lowestStandard === 0 && lowestPlus === 0 && lowestCustom === 0 && !this.state.loading) {
      return (
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-6 mx-auto">
              <div className="jumbotron text-xs-center">
                <h4 className="mb-3"><img src="images/accident.png" alt="" /> Sorry, we could not complete your request.</h4>
                <p>There was an issue getting a quote for you. Please contact us at <b>1 855-777-4376</b> to complete your request.</p>
                <p>Your quote reference number is {this.props.values.quoteReferenceNumber}</p>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (this.state.error500 === true && this.state.loading === false) {
      return (
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-6 mx-auto">
              <div className="jumbotron text-xs-center">
                <h4 className="mb-3"><img src="images/accident.png" alt="" /> Sorry, we could not complete your request.</h4>
                <p>There was an issue getting a quote for you. Please contact us at <b>1 855-777-4376</b> to complete your request.</p>
                <p>Your quote reference number is {this.props.values.quoteReferenceNumber}</p>
              </div>
            </div>
          </div>
        </div>
      )

    } else {

    return (
      <React.Fragment>
      <ReactTooltip className="wtf" />
        { isMobile || window.name === 'facebook_ref'
          ?
            this.state.vehicles && this.state.numberOfVehicles > 1 ?
              <div className="table-responsive card custom-card p-2">
                {
                  _.map(this.state.vehicles, (vehicle, i) => (
                    this.multiMobileVehicle(vehicle, i, lowestBasic, lowestStandard, lowestPlus, lowestCustom, packageData)
                  ))
                }
              </div>
              :
              <div className="table-responsive card custom-card p-2">
                { this.singleMobileVehicle(lowestBasic, lowestStandard, lowestPlus, lowestCustom, packageData) }
              </div>
          :
              <div className="table-responsive card custom-card p-2">

                <table className="table">
                  <tbody>
                    <tr>
                      <th className="borderless borderless-left"></th>
                      <td className="text-xs-center borderless" style={{width: '16.66%'}}>
                        <h3 className="card-header font-weight-normal">Basic</h3>
                          {
                            this.state.loading
                            ? <img src="images/loading.gif" alt="Loading" />
                            :
                            <dl className="pricing-details">
                              <ReactTooltip className="wtf" />
                              <dt>
                                {
                                  this.state.hasLapses
                                  ? <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  : <NumberFormat value={Math.trunc(lowestBasic.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                }
                              </dt>
                              <dd>
                                {
                                  this.state.hasLapses
                                  ? "per year"
                                  : "per month"
                                }
                              </dd>
                              {
                                this.state.hasLapses
                                ?
                                <dd>
                                  <small className="bg-yellow"><b>(Payment in full required)</b></small>
                                </dd>
                                :
                                <dd>
                                  <NumberFormat value={Math.trunc(lowestBasic.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
                                  <small className="extra-small">(Monthly payments subject to eligibility)</small>
                                </dd>
                              }
                            </dl>
                          }
                      </td>
                      <td className="text-xs-center borderless" style={{width: '16.66%'}}>
                        <h3 className="card-header font-weight-normal">Basic+</h3>
                        {
                          this.state.loading
                          ? <img src="images/loading.gif" alt="Loading" />
                          :
                            <dl className="pricing-details">
                              <ReactTooltip className="wtf" />
                              <dt>
                                {
                                  this.state.hasLapses
                                  ? <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  : <NumberFormat value={Math.trunc(lowestStandard.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                }
                              </dt>
                              <dd>
                                {
                                  this.state.hasLapses
                                  ? "per year"
                                  : "per month"
                                }
                              </dd>
                              {
                                this.state.hasLapses
                                ?
                                <dd>
                                  <small className="bg-yellow"><b>(Payment in full required)</b></small>
                                </dd>
                                :
                                <dd>
                                  <NumberFormat value={Math.trunc(lowestStandard.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
                                  <small className="extra-small">(Monthly payments subject to eligibility)</small>
                                </dd>
                              }
                            </dl>
                        }
                      </td>
                      <td className="text-xs-center borderless" style={{width: '16.66%'}}>
                        <h3 className="card-header font-weight-normal">Enhanced</h3>
                        {
                          this.state.loading
                          ? <img src="images/loading.gif" alt="Loading" />
                          :
                            <dl className="pricing-details">
                              <ReactTooltip className="wtf" />
                              <dt>
                                {
                                  this.state.hasLapses
                                  ? <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  : <NumberFormat value={Math.trunc(lowestPlus.premium/12)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                }
                              </dt>
                              <dd>
                                {
                                  this.state.hasLapses
                                  ? "per year"
                                  : "per month"
                                }
                              </dd>
                              {
                                this.state.hasLapses
                                ?
                                <dd>
                                  <small className="bg-yellow"><b>(Payment in full required)</b></small>
                                </dd>
                                :
                                <dd>
                                  <NumberFormat value={Math.trunc(lowestPlus.premium)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per year <br/>
                                  <small className="extra-small">(Monthly payments subject to eligibility)</small>
                                </dd>
                              }
                            </dl>
                        }
                      </td>
                      <td className="text-xs-center borderless" style={{width: '16.66%'}}>
                        <h3 className="card-header font-weight-normal">Custom</h3>
                        { this.showCustomPricing(lowestCustom) }
                      </td>
                    </tr>
                    {
                      this.state.vehicles && this.state.numberOfVehicles > 1 ?
                        _.map(this.state.vehicles, (vehicle, i) => (
                          this.multiVehicle(vehicle, i, lowestBasic, lowestStandard, lowestPlus, lowestCustom)
                      ))
                    :
                      this.singleVehicle()
                    }
                    <tr>
                      <th className="borderless-left" style={{width: '26.66%'}}></th>
                      <td className="text-xs-center">
                        <button
                          disabled={this.state.loading}
                          className="btn btn-primary btn-custom quote-buttons get_package"
                          onClick={(e) => this.props.selectPackage('Basic', lowestBasic.company, lowestBasic.premium, packageData)}
                        >Get Basic</button>
                      </td>
                      <td className="text-xs-center">
                        <button
                          disabled={this.state.loading}
                          className="btn btn-primary btn-custom quote-buttons get_package"
                          onClick={(e) => this.props.selectPackage('Basic +', lowestStandard.company, lowestStandard.premium, packageData)}
                        >Get Basic+</button></td>
                      <td className="text-xs-center">
                        <button
                          disabled={this.state.loading}
                          className="btn btn-primary btn-custom quote-buttons get_package"
                          onClick={(e) => this.props.selectPackage('Enhanced', lowestPlus.company, lowestPlus.premium, packageData)}
                        >Get Enhanced</button>
                      </td>
                      <td className="text-xs-center">
                        <button
                          disabled={this.checkLiabilityLimit(this.state.numberOfVehicles) || this.state.loading}
                          className="btn btn-default btn-pkg"
                          onClick={this.saveCustomQuote}>
                        Calculate!
                      </button>
                      <button
                        className="btn btn-primary btn-custom quote-button-less-margin get_package"
                        onClick={(e) => this.props.selectPackage('custom', lowestCustom.company, lowestCustom.premium, packageData)}
                        disabled={_.isUndefined(lowestCustom) || lowestCustom === 0|| this.state.loading}>
                        Get Custom!
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
        </React.Fragment>
      )
    }
  }

  render() {
    // 90 because 100 is slower, this is only a fake progress bar ;)
    const percentage = Math.round((parseInt(this.state.progress) / 102) * 100);
    if (percentage === 84) {
      clearInterval(this.interval);
    }

    return (
      <React.Fragment>
        <div className="row page-title clearfix">
          {
          this.state.loading
            ?
            <React.Fragment>
              <h3 className="text-xs-center mb-3 mt-2">
                {this.state.loadingMessage}
              </h3>
              <ProgressBar percentage={percentage} isMobile={false} />
            </React.Fragment>
            : <h3 className="text-xs-center mb-3 mt-2">
            {
              this.state.error500 ? "Uh oh! Something unexpected happened." : <div>Your quotes are ready!<img src="images/money-face.png" alt="" /></div>
            }
            </h3>
          }
        </div>
        <div className="widget-list row" id="QuoteSum">
          { DEBUG ? this.debugInfo() : this.renderPackages() }
        </div>
      </React.Fragment>
    )
  }
}

export default Quotes;