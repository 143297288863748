export const liabilityLimits = [
  {
    value: "1000000",
    label: "$1,000,000"
  },
  {
    value: "2000000",
    label: "$2,000,000"
  }
];

export const deductibles = [
  {
    value: "No Coverage",
    label: "No Coverage",
  },
  {
    value: "500",
    label: "$500"
  },
  {
    value: "750",
    label: "$750"
  },
  {
    value: "1000",
    label: "$1,000"
  },
  {
    value: "2000",
    label: "$2,000"
  },
  {
    value: "5000",
    label: "$5,000"
  }
];

export const preferredTime = [
  {
    value: "Morning (09:00 AM to 12:00 PM)",
    label: "Morning (09:00 AM to 12:00 PM)"
  },
  {
    value: "Afternoon (12:00 PM to 05:00 PM)",
    label: "Afternoon (12:00 PM to 05:00 PM)"
  }
];
