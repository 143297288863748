import React, { Component } from 'react';
import QuoteBuilder from './components/QuoteBuilder';
import ErrorBoundary from './config/ErrorBoundary';
import './css/bootstrap.min.css'
import './css/style.css'

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <div className="container fill">
          <QuoteBuilder />
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
