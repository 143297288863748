import _ from 'lodash';
import React from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import config from '../config';
import 'react-phone-number-input/style.css'
import airbrake from '../config/airbrake';
import Input, { isValidPhoneNumber } from 'react-phone-number-input';

class PersonalInformation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      driver: {
        firstName: '',
        lastName: ''
      }
    }

    this.saveAndContinue = this.saveAndContinue.bind(this);
  }

  componentDidMount() {
    const { values } = this.props;

    window.scrollTo(0, 0)

    axios.get(`${config.apiUrl}/quotes/${values.quoteId}`)
      .then(response => {
        if (response.data.quote.firstname && response.data.quote.lastname) {
          this.setState({
            driver: {
              firstName: response.data.quote.firstname,
              lastName: response.data.quote.lastname,
            }
          })
        } else {
          this.setState({
            driver: {
              firstName: response.data.drivers[0].firstname,
              lastName: response.data.drivers[0].lastname,
            }
          })
        }
      }).catch(error => {
        console.log(error);
        airbrake.notify({
          error: error,
          params: {
            endpoint: `PersonalInfo Page - ${error} - API End point: ${config.apiUrl}/quotes/${values.quoteId}`,
          }
        });
      });
  }

  saveAndContinue(e) {
    e.preventDefault()

    const { values } = this.props;
    const { driver } = this.state;

    const userData = {
      firstname: values.userFirstName ? values.userFirstName : driver.firstName,
      lastname: values.userLastName ? values.userLastName : driver.lastName,
      email: values.emailAddress,
      phone: values.phoneNumber
    }

    axios.put(`${config.apiUrl}/quotes/${values.quoteId}`, userData)
      .then((response) => {
        
      })
      .catch((error) => {
        console.log(error);
      });

    this.props.nextStep()
  }

  render() {
    let hrefLink = '#';
    const {
      handleChange,
      values,
    } = this.props;

    const { driver } = this.state;

    let disabled;
    if (values.phoneHasErrors === true || values.emailHasErrors || values.userFirstNameHasError || values.userLastNameHasError) {
      disabled = true;
    } else if (_.isEmpty(values.emailAddress)) {
      disabled = true;
    } else if (_.isEmpty(values.phoneNumber)) {
      disabled = true;
    } else {
      disabled = false;
    }

    return (
      <section id="PersonalInfo" className="tm-section">
        <h5 className="mb-2">Almost done! Please confirm these details to get your quote.</h5>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <div className="form-block">
              <label>
                <img src="images/driver.png" alt="" /> First name
              </label>
              <input
                id="23"
                name="userFirstName"
                className="form-control"
                defaultValue={driver && driver.firstName}
                placeholder='First name'
                onChange={handleChange}
                onBlur={handleChange}
              />
            <div className='invalid-feedback input-error'>{values.useFirstnameError}</div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-block">
              <label>
                <img src="images/driver.png" alt="" /> Last name
              </label>
              <input
                id="24"
                name="userLastName"
                className="form-control"
                placeholder='Last name'
                defaultValue={driver && driver.lastName}
                onChange={handleChange}
                onBlur={handleChange}
              />
            <div className='invalid-feedback input-error'>{values.userLastnameError}</div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-block">
              <label>
                <img src="images/lg-phone.png" alt="" /> Phone number
              </label>
              <Input
                country='CA'
                name="phoneNumber"
                inputClassName="form-control bacon"
                value={values.phoneNumber}
                error={values.phoneNumber ? (isValidPhoneNumber(values.phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number is required'}
                onChange={this.props.handlePhoneChange} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-block">
              <label>
                <img src="images/email2.png" alt="" /> Email address
              </label>
              <InputMask
                id="26"
                autoComplete="off"
                value={values.emailAddress}
                onChange={handleChange}
                placeholder="email@example.com"
                name="emailAddress"
                className={`form-control ${values.emailAddressError ? 'is-invalid' : ''}`}
              />
            </div>
            <div className='invalid-feedback input-error'>
              {
                _.isEmpty(values.emailAddress) ? 'Email address is required.' : values.emailAddressError
              }
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-1">
              <p className="consent"><small>By submitting this form, you acknowledge that you have reviewed our <a href="https://insurancehero.ca/our-commitment-to-transparency/" target="_blank" rel="noopener noreferrer">Code of Conduct & Commission Disclosures</a> and consent to the collection, use and disclosure of your information for the purposes of assessing and quoting the risk and determining your eligibility for coverage and discounts. All the information you provide will be treated as privileged and confidential. You may receive a phone call or email communication from <a href="https://insurancehero.ca/" target="_blank" rel="noopener noreferrer">InsuranceHero.ca</a> in regards to your quotation. For more information, please refer to our <a href="https://insurancehero.ca/privacy-policy/" target="_blank" rel="noopener noreferrer">privacy policy</a>.</small></p>
              <button onClick={this.saveAndContinue}
                disabled={disabled || !isValidPhoneNumber(values.phoneNumber)}
                className="btn btn-primary"
                id="PersonalInformation-6"
                type="submit">
                Get Quote! &rarr;
              </button>
              <div className='invalid-feedback'>
                {
                  values.phoneNumberError ? 'Phone number has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.emailAddressError ? 'Email address has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.useFirstnameError ? 'First name has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.userLastNameHasError ? 'Last name has errors.' : null
                }
              </div>
              <br/>
            </div>
          </div>
        </div>
        <div>
          <h5>You're seconds away from comparing rates from the top insurance companies including the ones below</h5>
          <br/>
          <div className="insuranceimg-block">
            <div>
              <div className="insurance-image">
                <img src="images/intact-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/aviva-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/economical-insurance.png" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/gore-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/pembridge-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/sgi-insurance.png" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/echelon-insurance.png" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/coachman-insurance.png" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/hagerty-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/pafco-insurance.jpg" alt='' />
              </div>
            </div>
            <div>
              <div className="insurance-image">
                <img src="images/jevco-insurance.jpg" alt='' />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-actions btn-list mt-3">
              <button onClick={this.saveAndContinue}
                disabled={disabled || !isValidPhoneNumber(values.phoneNumber)}
                className="btn btn-primary"
                id="PersonalInformation-6"
                type="submit">
                Get Quote! &rarr;
              </button>
              <div className='invalid-feedback'>
                {
                  values.phoneNumberError ? 'Phone number has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.emailAddressError ? 'Email address has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.useFirstnameError ? 'First name has errors.' : null
                }
              </div>
              <div className='invalid-feedback'>
                {
                  values.userLastNameHasError ? 'Last name has errors.' : null
                }
              </div>
              <br/>
              <p><a href={hrefLink} onClick={this.props.prevStep}><img src="images/back.png" alt="Back"/> Back</a></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PersonalInformation;