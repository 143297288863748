import React from 'react';

function CallButton() {
  // Function to initiate a phone call when the button is clicked
  const handleCallClick = () => {
    const phoneNumber = '18557774376';
    
    // Check if the device supports phone calls
    if ('contacts' in navigator && 'ContactsManager' in window) {
      // Use the Contacts API to initiate a call
      navigator.contacts.select(['phoneNumbers'], { multiple: false })
        .then(contacts => {
          if (contacts.length > 0) {
            const contact = contacts[0];
            const number = contact.phoneNumbers[0].value;
            window.location.href = 'tel:' + number;
          } else {
            // Fallback to directly calling the phone number
            window.location.href = 'tel:' + phoneNumber;
          }
        })
        .catch(error => {
          console.error('Error selecting contacts:', error);
          // Fallback to directly calling the phone number
          window.location.href = 'tel:' + phoneNumber;
        });
    } else {
      // Fallback to directly calling the phone number
      window.location.href = 'tel:' + phoneNumber;
    }
  };

  return (
    <button
    className="btn btn-green"
    onClick={handleCallClick}>Call Us</button>
  );
}

export default CallButton;
